import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_COMMUNICATION_REQUESTED: "GET_COMPANY_COMMUNICATION_REQUESTED",
    GET_COMPANY_COMMUNICATION_SUCCEEDED: "GET_COMPANY_COMMUNICATION_SUCCEEDED",
    GET_COMPANY_COMMUNICATION_FAILED: "GET_COMPANY_COMMUNICATION_FAILED",

    REPLY_COMPANY_COMMUNICATION_REQUESTED: "REPLY_COMPANY_COMMUNICATION_REQUESTED",
    REPLY_COMPANY_COMMUNICATION_SUCCEEDED: "REPLY_COMPANY_COMMUNICATION_SUCCEEDED",
    REPLY_COMPANY_COMMUNICATION_FAILED: "REPLY_COMPANY_COMMUNICATION_FAILED"
};

// Action Creators
export const actions = {
    all: (token, email, thread_id, callback) => {
        return {
            type: types.GET_COMPANY_COMMUNICATION_REQUESTED,
            token,
            email,
            thread_id,
            callback
        };
    },
    reply: (token, data, callback) => {
        return {
            type: types.REPLY_COMPANY_COMMUNICATION_REQUESTED,
            token,
            data,
            callback
        };
    }

};

// Default state
const defaultState = {
    data: [],
    new: 0,
    newLoading: true,
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_COMMUNICATION_REQUESTED:
        case types.REPLY_COMPANY_COMMUNICATION_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_COMMUNICATION_SUCCEEDED:
        case types.REPLY_COMPANY_COMMUNICATION_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_COMMUNICATION_FAILED:
        case types.REPLY_COMPANY_COMMUNICATION_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_COMMUNICATION_REQUESTED, allWorker);
    yield takeLatest(types.REPLY_COMPANY_COMMUNICATION_REQUESTED, replyWorker);
}

// Saga callback
function* allWorker({ token, email, thread_id, callback }) {

    try {
        const response = yield call(all, { token, email, thread_id });
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_COMMUNICATION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_COMMUNICATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// Saga callback
function* replyWorker({ token, data, callback }) {

    try {
        const response = yield call(reply, { token, data });
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REPLY_COMPANY_COMMUNICATION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.REPLY_COMPANY_COMMUNICATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function all({ token, email, thread_id }) {
    return api.get(`/api/company/${token}/communication/${email}/${thread_id}`);
}


function reply({ token, data }) {
    // console.log("reduce...",data);
    return api.post(`/api/company/${token}/communication`, data);
}

import React, { lazy } from "react";

import { Route } from "react-router-dom";

const CompanyComparisonPart1 = lazy(() => import("./companyBenchmarks/Page1"));
const CompanyComparisonPart2_2 = lazy(() => import("./companyBenchmarks/Page3"));
const CompanyComparisonPart2 = lazy(() => import("./companyBenchmarks/Page2"));
const CompanyComparisonPart3 = lazy(() => import("./circleBenchmarks/Page1"));
const CompanyComparisonPart3_2 = lazy(() => import("./circleBenchmarks/Page2"));
const CompanyComparisonPart3_3 = lazy(() => import("./circleBenchmarks/Page3"));
const CompanyProfile = lazy(() => import("./Profile"));
const CompanyHome = lazy(() => import("./Home"));

// Employer Pages
const CompanyEmployerPageAbout = lazy(() => import("./employerPage/About"));
const CompanyEmployerPageTeam = lazy(() => import("./employerPage/Team"));
const CompanyEmployerPageBenefits = lazy(() => import("./employerPage/Benefits"));
const CompanyEmployerPageEvp = lazy(() => import("./employerPage/Evp"));
const CompanyEmployerPageContact = lazy(() => import("./employerPage/Contact"));
const CompanyEmployerPagePreview = lazy(() => import("./employerPage/Preview"));
// Circle
// const CompanyTribeContentOverview = lazy(() => import("./tribe/ContentOverview"));
const CompanyTribeContentOverview = lazy(() => import("./tribe/Overview"));
const CompanyTribePreview = lazy(() => import("./tribe/CirclePreview"));
//Communication
const CompanyCommunicationMember = lazy(() => import("./communication/Member"));
const CompanyCommunicationChatbox = lazy(() => import("./communication/ChatBox"));

// const CompanyTribe = lazy(() => import("./tribe/New"));
const CompanyCulture = lazy(() => import("./Culture"));
// const CompanyEditTribe = lazy(() => import("./tribe/Edit"));
const CompanyEditTribe = lazy(() => import("./tribe/TribeCategory"));
const CompanyTribeCategory = lazy(() => import("./tribe/TribeCategory"));
const CompanyCopyTribeJob = lazy(() => import("./tribe/job/Copy"));
const CompanyEditTribeJobPage1 = lazy(() => import("./tribe/job/EditPage1"));
const CompanyEditTribeJobPage2 = lazy(() => import("./tribe/job/EditPage2"));
const CompanyCopyTribeGig = lazy(() => import("./tribe/gig/Copy"));
const CompanyEditTribeGigPage1 = lazy(() => import("./tribe/gig/EditPage1"));
const CompanyEditTribeGigPage2 = lazy(() => import("./tribe/gig/EditPage2"));
const CompanyCopyTribeContent = lazy(() => import("./tribe/content/Copy"));
const CompanyEditTribeContentPage1 = lazy(() => import("./tribe/content/EditPage1"));
const CompanyEditTribeContentPage2 = lazy(() => import("./tribe/content/EditPage2"));
const CompanyCopyTribeEvent = lazy(() => import("./tribe/event/Copy"));
const CompanyEditTribeEventPage1 = lazy(() => import("./tribe/event/EditPage1"));
const CompanyEditTribeEventPage2 = lazy(() => import("./tribe/event/EditPage2"));
const CompanyTribeActivities = lazy(() => import("./tribe/Activities"));
const CompanyMatchingDashboard = lazy(() => import("./MatchingDashboard"));
const CompanyMatchingStatistics = lazy(() => import("./matchingStatistics/Gate"));
const CompanyTalentList = lazy(() => import("./talents/List"));
// const CompanyTalentList = lazy(() => import("./TalentList"));
const CompanyJobsList = lazy(() => import("./jobs/List"));
const CompanyJobsView = lazy(() => import("./jobs/View"));
const CompanyJobsAdd = lazy(() => import("./jobs/Add"));
const CompanyJobsEdit = lazy(() => import("./jobs/Edit"));
const CompanyTalentCard = lazy(() => import("./TalentCard"));
const CompanyMyTribe = lazy(() => import("./MyTribe"));
// const AnalyticsHome = lazy(() => import("./analytics/Overview"));
const CompanyJobsPreMatches = lazy(() => import("./tribe/PreMatches"));

// Blog and Settings
const BlogHome = lazy(() => import("./blog/Home"));
const ListGeneralBlog = lazy(() => import("./blog/ListGeneralBlog"));
const ListCategoryBlog = lazy(() => import("./blog/ListCategoryBlog"));
const GeneralBlog = lazy(() => import("./blog/GeneralBlog"));
const CategoryBlog = lazy(() => import("./blog/CategoryBlog"));
const SettingsMagicLinks = lazy(() => import("./blog/settings/MagicLinks"));
const SettingsCircleStatus = lazy(() => import("./blog/settings/CircleStatus"));
const BlogAuthorsList = lazy(() => import("./blog/settings/author/List"));
const BlogAuthorsEdit = lazy(() => import("./blog/settings/author/Edit"));

// Authors
const AuthorsList = lazy(() => import("./author/List"));
const AuthorsEdit = lazy(() => import("./author/Edit"));
const AuthorsInvite = lazy(() => import("./author/Invite"));

// Sign up
const Signup = lazy(() => import("./Signup"));
// Login
const Login = lazy(() => import("./Login"));

const CompanyNotification = lazy(() => import("./Notification"));

const BlogCompanyList = lazy(() => import("./blog/ListCompany"));

const CompanyAnalyticsOverview = lazy(() => import("./analytics/Overview"));
const CompanyAnalyticsInsights = lazy(() => import("./analytics/Insights"));
const CompanyAnalyticsOpportunities = lazy(() => import("./analytics/Opportunities"));
const CompanyAnalyticsExposure = lazy(() => import("./analytics/Exposure"));


const CompanyStudentsCategory = lazy(() => import("./students/Category"));
const CompanyStudentsTalents = lazy(() => import("./students/Talents"));
const CompanyStudentsJobs = lazy(() => import("./students/Jobs"));
const CompanyStudentJobsView = lazy(() => import("./students/JobsView"));
const CompanyStudentJobsAdd = lazy(() => import("./students/JobsAdd"));
const CompanyStudentJobsPreMatches = lazy(() => import("./students/PreMatches"));


import routes from "Pages/company/routes";

const definitions = (
    <>
        <Route
            exact
            path={routes.COMPANY_SIGNUP}
            component={(props) => (
                <Signup {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_LOGIN}
            component={(props) => (
                <Login {...props} key={window.location.pathname} />
            )}
        />
        {/* <Route
            exact
            path={routes.COMPANY_ANALYTICS_HOME}
            component={(props) => (
                <AnalyticsHome {...props} key={window.location.pathname} />
            )}
        /> */}

        <Route
            exact
            path={routes.COMPANY_ANALYTICS_OVERVIEW}
            component={(props) => (
                <CompanyAnalyticsOverview {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_ANALYTICS_INSIGHTS}
            component={(props) => (
                <CompanyAnalyticsInsights {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_ANALYTICS_OPPORTUNITIES}
            component={(props) => (
                <CompanyAnalyticsOpportunities {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_ANALYTICS_EXPOSURE}
            component={(props) => (
                <CompanyAnalyticsExposure {...props} key={window.location.pathname} />
            )}
        />



        <Route
            exact
            path={routes.SETTINGS_MAGIC_LINKS}
            component={(props) => (
                <SettingsMagicLinks {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_AUTHOR_LIST}
            component={(props) => (
                <AuthorsList {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_AUTHOR_CREATE}
            component={(props) => (
                <AuthorsEdit {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_AUTHOR_EDIT}
            component={(props) => (
                <AuthorsEdit {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_AUTHOR_INVITE}
            component={AuthorsInvite}
        />
        <Route
            exact
            path={routes.BLOG_AUTHOR_LIST}
            component={(props) => (
                <BlogAuthorsList {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.BLOG_COMPANY_LIST}
            component={(props) => (
                <BlogCompanyList {...props} key={window.location.pathname} />
            )}
        />

        <Route
            exact
            path={routes.BLOG_AUTHOR_CREATE}
            component={(props) => (
                <BlogAuthorsEdit {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.BLOG_AUTHOR_EDIT}
            component={(props) => (
                <BlogAuthorsEdit {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.SETTINGS_CIRCLE_STATUS}
            component={(props) => (
                <SettingsCircleStatus
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.LIST_GENERAL_BLOG}
            component={(props) => (
                <ListGeneralBlog {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.NEW_GENERAL_BLOG}
            component={(props) => (
                <GeneralBlog {...props} key={window.location.pathname} />
            )}
        />
        <Route
            path={routes.EDIT_GENERAL_BLOG}
            component={(props) => (
                <GeneralBlog {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.LIST_CATEGORY_BLOG}
            component={(props) => (
                <ListCategoryBlog {...props} key={window.location.pathname} />
            )}
        />
        <Route
            path={routes.EDIT_CATEGORY_BLOG}
            component={(props) => (
                <CategoryBlog {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.NEW_CATEGORY_BLOG}
            component={(props) => (
                <CategoryBlog {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.BLOG_HOME}
            component={(props) => (
                <BlogHome {...props} key={window.location.pathname} />
            )}
        />
        <Route
            path={routes.COMPANY_COMPARISON_REPORT_1}
            component={CompanyComparisonPart1}
        />
        <Route
            exact
            path={routes.COMPANY_COMPARISON_REPORT_2}
            component={CompanyComparisonPart2}
        />
        <Route
            exact
            path={routes.COMPANY_COMPARISON_REPORT_2_2}
            component={CompanyComparisonPart2_2}
        />
        <Route
            exact
            path={routes.COMPANY_COMPARISON_REPORT_3}
            component={CompanyComparisonPart3}
        />
        <Route
            exact
            path={routes.COMPANY_COMPARISON_REPORT_3_2}
            component={CompanyComparisonPart3_2}
        />
        <Route
            exact
            path={routes.COMPANY_COMPARISON_REPORT_3_3}
            component={CompanyComparisonPart3_3}
        />
        <Route exact path={routes.COMPANY_PROFILE} component={CompanyProfile} />
        <Route exact path={routes.COMPANY_HOME} component={CompanyHome} />
        <Route exact path={routes.COMPANY_NOTIFICATION} component={CompanyNotification} />
        <Route
            exact
            path={routes.COMPANY_EMPLOYER_PAGE_ABOUT}
            component={CompanyEmployerPageAbout}
        />
        <Route
            exact
            path={routes.COMPANY_EMPLOYER_PAGE_TEAM}
            component={CompanyEmployerPageTeam}
        />
        <Route
            exact
            path={routes.COMPANY_EMPLOYER_PAGE_BENEFITS}
            component={CompanyEmployerPageBenefits}
        />
        <Route
            exact
            path={routes.COMPANY_EMPLOYER_PAGE_EVP}
            component={CompanyEmployerPageEvp}
        />
        <Route
            exact
            path={routes.COMPANY_EMPLOYER_PAGE_CONTACT}
            component={CompanyEmployerPageContact}
        />

        <Route
            exact
            path={routes.COMPANY_EMPLOYER_PAGE_PREVIEW}
            component={CompanyEmployerPagePreview}
        />

        <Route
            exact
            path={routes.COMPANY_TRIBE_CONTENT_OVERVIEW}
            component={CompanyTribeContentOverview}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_PREVIEW}
            component={CompanyTribePreview}
        />

        <Route exact path={routes.COMPANY_CULTURE} component={CompanyCulture} />

        <Route
            exact
            path={routes.COMPANY_EDIT_TRIBE}
            component={(props) => (
                // <CompanyEditTribe {...props} key={window.location.pathname} />
                <CompanyTribeCategory {...props} key={window.location.pathname} />
            )}
        />

        <Route
            exact
            path={routes.COMPANY_TRIBE}
            component={(props) => (
                <CompanyTribeCategory
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_CATEGORY}
            component={(props) => (
                <CompanyEditTribe {...props} key={window.location.pathname} />
            )}
        />

        <Route
            exact
            path={routes.COMPANY_TRIBE_COPY_JOB}
            component={(props) => (
                <CompanyCopyTribeJob
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_JOB}
            component={(props) => (
                <CompanyEditTribeJobPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_JOB_2}
            component={(props) => (
                <CompanyEditTribeJobPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_JOB}
            component={(props) => (
                <CompanyEditTribeJobPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_JOB_2}
            component={(props) => (
                <CompanyEditTribeJobPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_COPY_GIG}
            component={(props) => (
                <CompanyCopyTribeGig
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_GIG}
            component={(props) => (
                <CompanyEditTribeGigPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_GIG_2}
            component={(props) => (
                <CompanyEditTribeGigPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_GIG}
            component={(props) => (
                <CompanyEditTribeGigPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_GIG_2}
            component={(props) => (
                <CompanyEditTribeGigPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_COPY_CONTENT}
            component={(props) => (
                <CompanyCopyTribeContent
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_CONTENT}
            component={(props) => (
                <CompanyEditTribeContentPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_CONTENT_2}
            component={(props) => (
                <CompanyEditTribeContentPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_CONTENT}
            component={(props) => (
                <CompanyEditTribeContentPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_CONTENT_2}
            component={(props) => (
                <CompanyEditTribeContentPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_COPY_EVENT}
            component={(props) => (
                <CompanyCopyTribeEvent
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_EVENT}
            component={(props) => (
                <CompanyEditTribeEventPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_EDIT_EVENT_2}
            component={(props) => (
                <CompanyEditTribeEventPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_EVENT}
            component={(props) => (
                <CompanyEditTribeEventPage1
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_NEW_EVENT_2}
            component={(props) => (
                <CompanyEditTribeEventPage2
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TRIBE_ACTIVITIES}
            component={(props) => (
                <CompanyTribeActivities
                    {...props}
                    key={window.location.pathname}
                />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_MATCHING_DASHBOARD}
            component={CompanyMatchingDashboard}
        />
        <Route
            exact
            path={routes.COMPANY_MATCHING_STATISTICS}
            component={CompanyMatchingStatistics}
        />
        <Route
            exact
            path={routes.COMPANY_TALENT_LIST}
            component={(props) => (
                <CompanyTalentList {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_JOBS_ADD}
            component={(props) => (
                <CompanyJobsAdd {...props} key={window.location.pathname} />
            )}
        />

        <Route
            exact
            path={routes.COMPANY_JOBS_EDIT}
            component={(props) => (
                <CompanyJobsEdit {...props} key={window.location.pathname} />
            )}
        />

        <Route
            exact
            path={routes.COMPANY_JOBS_LIST}
            component={(props) => (
                <CompanyJobsList {...props} key={window.location.pathname} />
            )}
        />

        <Route
            exact
            path={routes.COMPANY_JOBS_VIEW}
            component={(props) => (
                <CompanyJobsView {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_TALENT_CARD}
            component={(props) => (
                <CompanyTalentCard {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_MY_TRIBE}
            component={CompanyMyTribe}
        />
        <Route
            exact
            path={routes.COMPANY_COMMUNICATION_MEMBER}
            component={CompanyCommunicationMember}
        />
        <Route
            exact
            path={routes.COMPANY_COMMUNICATION_CHATBOX}
            component={CompanyCommunicationChatbox}
        />
        <Route
            exact
            path={routes.COMPANY_STUDENT_CATEGORY}
            component={CompanyStudentsCategory}
        />
        <Route
            exact
            path={routes.COMPANY_STUDENT_TALENTS}
            component={CompanyStudentsTalents}
        />
        <Route
            exact
            path={routes.COMPANY_STUDENT_JOBS}
            component={(...props) =>
                <CompanyStudentsJobs {...props} />
            }
        />
        <Route
            exact
            path={routes.COMPANY_STUDENT_JOBS_VIEW}
            component={(props) => (
                <CompanyStudentJobsView {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_STUDENT_JOBS_ADD}
            component={(props) => (
                <CompanyStudentJobsAdd {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_STUDENT_JOBS_EDIT}
            component={(props) => (
                <CompanyStudentJobsAdd {...props} key={window.location.pathname} />
            )}
        />
        <Route
            exact
            path={routes.COMPANY_STUDENT_JOBS_PRE_MATCHES}
            component={(props) => (
                <CompanyStudentJobsPreMatches {...props} key={window.location.pathname} />
            )}
        />

        <Route
            exact
            path={routes.COMPANY_JOBS_PRE_MATCHES}
            component={(props) => (
                <CompanyJobsPreMatches {...props} key={window.location.pathname} />
            )}
        />

    </>
);

export default definitions;

import { takeLatest, call, put, debounce } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_PROFILE_CITY_OF_RESIDENCE_REQUESTED:
        "SAVE_PROFILE_CITY_OF_RESIDENCE_REQUESTED",
    SAVE_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED:
        "SAVE_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED",
    SAVE_PROFILE_CITY_OF_RESIDENCE_FAILED:
        "SAVE_PROFILE_CITY_OF_RESIDENCE_FAILED",
    SEARCH_PROFILE_CITY_OF_RESIDENCE_REQUESTED:
        "SEARCH_PROFILE_CITY_OF_RESIDENCE_REQUESTED",
    SEARCH_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED:
        "SEARCH_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED",
    SEARCH_PROFILE_CITY_OF_RESIDENCE_FAILED:
        "SEARCH_PROFILE_CITY_OF_RESIDENCE_FAILED",
    GET_PROFILE_CITY_OF_RESIDENCE_REQUESTED:
        "GET_PROFILE_CITY_OF_RESIDENCE_REQUESTED",
    GET_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED:
        "GET_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED",
    GET_PROFILE_CITY_OF_RESIDENCE_FAILED: "GET_PROFILE_CITY_OF_RESIDENCE_FAILED"
};

// Action Creators
export const actions = {
    save: (data, callback) => {
        return {
            type: types.SAVE_PROFILE_CITY_OF_RESIDENCE_REQUESTED,
            data,
            callback
        };
    },
    search: (searchTerm, callback) => {
        return {
            type: types.SEARCH_PROFILE_CITY_OF_RESIDENCE_REQUESTED,
            searchTerm,
            callback
        };
    },
    get: callback => {
        return {
            type: types.GET_PROFILE_CITY_OF_RESIDENCE_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_PROFILE_CITY_OF_RESIDENCE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SAVE_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED:
        case types.GET_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_PROFILE_CITY_OF_RESIDENCE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.SAVE_PROFILE_CITY_OF_RESIDENCE_REQUESTED,
        saveWorker
    );
    yield takeLatest(types.GET_PROFILE_CITY_OF_RESIDENCE_REQUESTED, getWorker);
    yield debounce(
        300,
        types.SEARCH_PROFILE_CITY_OF_RESIDENCE_REQUESTED,
        searchWorker
    );
}

// Saga callback
function* saveWorker({ data, callback }) {
    try {
        const response = yield call(save, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_PROFILE_CITY_OF_RESIDENCE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_PROFILE_CITY_OF_RESIDENCE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* searchWorker({ searchTerm, callback }) {
    try {
        const response = yield call(search, { searchTerm });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEARCH_PROFILE_CITY_OF_RESIDENCE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SEARCH_PROFILE_CITY_OF_RESIDENCE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data }) {
    return api.put("/api/demographics/city/talent", { city_id: data });
}

function get() {
    return api.get(`/api/demographics/talent`);
}

function search({ searchTerm }) {
    return api.get(`/api/cities/search/${searchTerm}`);
}

import { combineReducers } from "redux";

import user from "./User";

import cultureCategory from "./culture/Category";
import cultureQuestion from "./culture/Question";
import cultureAnswer from "./culture/Answer";
import cultureResult from "./culture/Result";

import companyCultureCategory from "./company/culture/Category";
import companyCultureQuestion from "./company/culture/Question";
import companyCultureAnswer from "./company/culture/Answer";
import companyCultureResult from "./company/culture/Result";
import companyCultureImportance from "./company/culture/Importance";
import companyCultureTribe from "./company/culture/Tribe";

import matchesMatch from "./matches/Match";
import matchesFollow from "./matches/Follow";

import profileExperience from "./profile/Experience";
import profileProgress from "./profile/Progress";
import profileEducation from "./profile/Education";
import profileJobStatus from "./profile/JobStatus";
import profileSalary from "./profile/Salary";
import profileCityOfResidence from "./profile/CityOfResidence";
import profileGender from "./profile/Gender";
import profileDemographics from "./profile/Demographics";
import profilePreferenceEmployerTypeSize from "./profile/preference/EmployerTypeSize";
import profilePreferenceIndustry from "./profile/preference/Industry";
import profilePreferenceSector from "./profile/preference/Sector";
import profilePreferenceMobilty from "./profile/preference/Mobility";
import profilePreferenceSalary from "./profile/preference/Salary";
import profilePreferenceCompanies from "./profile/preference/Companies";
import profileLanguage from "./profile/Language";
import profileSkill from "./profile/Skill";

import resourceUniversity from "./resource/University";
import resourceCompany from "./resource/Company";
import resourceJobStatus from "./resource/JobStatus";
import resourceCurrency from "./resource/Currency";
import resourceEmployerTypeSize from "./resource/EmployerTypeSize";
import resourceSector from "./resource/Sector";
import resourceMobility from "./resource/Mobility";
import resourceDegree from "./resource/Degree";
import resourceGrade from "./resource/Grade";
import resourceJobTitle from "./resource/JobTitle";
import resourceMajor from "./resource/Major";
import resourceRole from "./resource/Role";
import resourceLanguage from "./resource/Language";
import resourceSkill from "./resource/Skill";
import resourceAreasOfExpertise from "./resource/AreasOfExpertise";
import resourceTechSkills from "./resource/TechSkills";
import resourceTechFields from "./resource/TechFields";

import benchmarksPreferenceEmployerTypeSize from "./benchmarks/preference/EmployerTypeSize";
import benchmarksPreferenceIndustry from "./benchmarks/preference/Industry";
import benchmarksPreferenceSector from "./benchmarks/preference/Sector";
import benchmarksPreferenceMobility from "./benchmarks/preference/Mobility";
import benchmarksSalaryCurrent from "./benchmarks/salary/Current";
import benchmarksSalaryPreffered from "./benchmarks/salary/Preffered";
import benchmarksJobStatus from "./benchmarks/JobStatus";
import benchmarksCulture from "./benchmarks/Culture";
import benchmarksSkill from "./benchmarks/Skill";
import benchmarksLanguage from "./benchmarks/Language";

import companyProfile from "./company/Profile";
import companyProfileLogo from "./company/Profile/Logo";
import companyFollows from "./company/Follows";
import companyMatches from "./company/Matches";
import companyMatchesFiles from "./company/Matches/Files";
import companyMatchesFreeText from "./company/Matches/FreeText";
import companyMatchesFeedback from "./company/Matches/Feedback";
import companyMatchesCulture from "./company/Matches/Culture";
import companyLabel from "./company/Label";
import companyLogo from "./company/Logo";
import companyFilter from "./company/Filter";
import companyTribe from "./company/Tribe";
import companyTribeJob from "./company/Tribe/Job";
import companyTribeGig from "./company/Tribe/Gig";
import companyTribeContent from "./company/Tribe/Content";
import companyGeneralBlog from "./company/GeneralBlog";
import companyCategoryBlog from "./company/CategoryBlog";
import companyTribeEvent from "./company/Tribe/Event";
import companyTribeCover from "./company/Tribe/Cover";
import companyDraftTribe from "./company/DraftTribe";
import companyDraftTribeJob from "./company/DraftTribeJob";
import companyDraftTribeGig from "./company/DraftTribeGig";
import companyDraftTribeContent from "./company/DraftTribeContent";
import companyDraftGeneralBlog from "./company/DraftGeneralBlog";
import companyDraftCategoryBlog from "./company/DraftCategoryBlog";
import companyDraftTribeEvent from "./company/DraftTribeEvent";
import companyDraftProfile from "./company/DraftProfile";
import companyDraftCulture from "./company/DraftCulture";
import companyComparisonIndustry from "./company/comparison/Industry";
import companyComparisonSizeTypeEmployer from "./company/comparison/SizeTypeEmployer";
import companyComparisonCulture from "./company/comparison/Culture";
import companyComparisonOfficeLocation from "./company/comparison/OfficeLocation";
import companyComparisonRecruitmentCity from "./company/comparison/RecruitmentCity";
import companyComparisonTargetProfile from "./company/comparison/TargetProfile";
import companyComparisonExperienceImportance from "./company/comparison/ExperienceImportance";
import companyComparisonSekCompensation from "./company/comparison/SekCompensation";
import companyComparisonMajor from "./company/comparison/Major";
import companyComparisonSector from "./company/comparison/Sector";
import companyComparisonLanguage from "./company/comparison/Language";
import companyComparisonSoftSkill from "./company/comparison/SoftSkill";
import companyComparisonHardSkill from "./company/comparison/HardSkill";
import companyAllCompanies from "./company/AllCompanies";
import companyPDF from "./company/PDF";
import companyLimitedMode from "./company/LimitedMode";
import companySettings from "./company/Settings";
import companySettingsLinks from "./company/Settings/CompanyLinks";
import companyAuthors from "./company/Authors";
import companyBlogAuthors from "./company/BlogAuthors";
import companyBlogInterestTags from "./company/BlogInterestTags";
import companySignup from "./company/Signup";
import companyLogin from "./company/Login";
import companySteps from "./company/Steps";
import companyNotification from "./company/Notification";
import companyCommunication from "./company/Communication";
import companyJobs from "./company/Jobs";
import companyTalents from "./company/Talents";
import companyPreviewLink from "./company/PreviewLink";

import companyStudentCircle from "./company/Student/Circle";
import companyStudentTalent from "./company/Student/Talent";
import companyStudentOpportunity from "./company/Student/Opportunity";


import base from "./base";
// import Opportunities from "../pages/company/analytics/Opportunities";
// import Opportunity from "./company/Student/Opportunity";

export default combineReducers({
    company: combineReducers({
        authors: companyAuthors.reducer,
        blogAuthors: companyBlogAuthors.reducer,
        blogInterestTags: companyBlogInterestTags.reducer,
        limitedMode: companyLimitedMode.reducer,
        profile: companyProfile.reducer,
        profileLogo: companyProfileLogo.reducer,
        draftProfile: companyDraftProfile.reducer,
        draftCulture: companyDraftCulture.reducer,
        tribe: companyTribe.reducer,
        tribeJob: companyTribeJob.reducer,
        tribeGig: companyTribeGig.reducer,
        tribeContent: companyTribeContent.reducer,
        generalBlog: companyGeneralBlog.reducer,
        categoryBlog: companyCategoryBlog.reducer,
        tribeEvent: companyTribeEvent.reducer,
        tribeCover: companyTribeCover.reducer,
        draftTribe: companyDraftTribe.reducer,
        draftTribeJob: companyDraftTribeJob.reducer,
        draftTribeGig: companyDraftTribeGig.reducer,
        draftTribeContent: companyDraftTribeContent.reducer,
        draftGeneralBlog: companyDraftGeneralBlog.reducer,
        draftCategoryBlog: companyDraftCategoryBlog.reducer,
        draftTribeEvent: companyDraftTribeEvent.reducer,
        allCompanies: companyAllCompanies.reducer,
        culture: combineReducers({
            category: companyCultureCategory.reducer,
            question: companyCultureQuestion.reducer,
            answer: companyCultureAnswer.reducer,
            result: companyCultureResult.reducer,
            importance: companyCultureImportance.reducer,
            tribe: companyCultureTribe.reducer
        }),
        matches: companyMatches.reducer,
        matchesFiles: companyMatchesFiles.reducer,
        matchesFreeText: companyMatchesFreeText.reducer,
        matchesFeedback: companyMatchesFeedback.reducer,
        matchesCulture: companyMatchesCulture.reducer,
        follows: companyFollows.reducer,
        label: companyLabel.reducer,
        logo: companyLogo.reducer,
        filter: companyFilter.reducer,
        comparison: combineReducers({
            industry: companyComparisonIndustry.reducer,
            sizeTypeEmployer: companyComparisonSizeTypeEmployer.reducer,
            culture: companyComparisonCulture.reducer,
            officeLocation: companyComparisonOfficeLocation.reducer,
            recruitmentCity: companyComparisonRecruitmentCity.reducer,
            targetProfile: companyComparisonTargetProfile.reducer,
            experienceImportance: companyComparisonExperienceImportance.reducer,
            sekCompensation: companyComparisonSekCompensation.reducer,
            major: companyComparisonMajor.reducer,
            sector: companyComparisonSector.reducer,
            language: companyComparisonLanguage.reducer,
            softSkill: companyComparisonSoftSkill.reducer,
            hardSkill: companyComparisonHardSkill.reducer
        }),
        pdf: companyPDF.reducer,
        settings: companySettings.reducer,
        companyLinks: companySettingsLinks.reducer,
        signup: companySignup.reducer,
        login: companyLogin.reducer,
        steps: companySteps.reducer,
        notification: companyNotification.reducer,
        communication: companyCommunication.reducer,
        jobs: companyJobs.reducer,
        talents: companyTalents.reducer,
        previewLink: companyPreviewLink.reducer,

        student: combineReducers({
            circle: companyStudentCircle.reducer,
            talent: companyStudentTalent.reducer,
            opportunity: companyStudentOpportunity.reducer
        })
    }),
    profile: combineReducers({
        experience: profileExperience.reducer,
        education: profileEducation.reducer,
        jobStatus: profileJobStatus.reducer,
        salary: profileSalary.reducer,
        cityOfResidence: profileCityOfResidence.reducer,
        gender: profileGender.reducer,
        demographics: profileDemographics.reducer,
        progress: profileProgress.reducer,
        preference: combineReducers({
            employerTypeSize: profilePreferenceEmployerTypeSize.reducer,
            industry: profilePreferenceIndustry.reducer,
            sector: profilePreferenceSector.reducer,
            mobility: profilePreferenceMobilty.reducer,
            salary: profilePreferenceSalary.reducer,
            companies: profilePreferenceCompanies.reducer
        }),
        language: profileLanguage.reducer,
        skill: profileSkill.reducer
    }),
    benchmarks: combineReducers({
        preference: combineReducers({
            employerTypeSize: benchmarksPreferenceEmployerTypeSize.reducer,
            industry: benchmarksPreferenceIndustry.reducer,
            sector: benchmarksPreferenceSector.reducer,
            mobility: benchmarksPreferenceMobility.reducer
        }),
        salary: combineReducers({
            current: benchmarksSalaryCurrent.reducer,
            preffered: benchmarksSalaryPreffered.reducer
        }),
        jobStatus: benchmarksJobStatus.reducer,
        culture: benchmarksCulture.reducer,
        skill: benchmarksSkill.reducer,
        language: benchmarksLanguage.reducer
    }),
    resource: combineReducers({
        university: resourceUniversity.reducer,
        company: resourceCompany.reducer,
        jobStatus: resourceJobStatus.reducer,
        currency: resourceCurrency.reducer,
        employerTypeSize: resourceEmployerTypeSize.reducer,
        sector: resourceSector.reducer,
        mobility: resourceMobility.reducer,
        degree: resourceDegree.reducer,
        grade: resourceGrade.reducer,
        jobTitle: resourceJobTitle.reducer,
        major: resourceMajor.reducer,
        role: resourceRole.reducer,
        language: resourceLanguage.reducer,
        skill: resourceSkill.reducer,
        areasOfExpertise: resourceAreasOfExpertise.reducer,
        techSkills: resourceTechSkills.reducer,
        techFields: resourceTechFields.reducer
    }),
    culture: combineReducers({
        category: cultureCategory.reducer,
        question: cultureQuestion.reducer,
        answer: cultureAnswer.reducer,
        result: cultureResult.reducer
    }),
    matches: combineReducers({
        match: matchesMatch.reducer,
        follow: matchesFollow.reducer
    }),
    user: user.reducer,
    base: base.reducer
});

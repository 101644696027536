import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Actions
const types = {
    LIST_COMPANY_STUDENT_OPPORTUNITY_REQUESTED: "LIST_COMPANY_STUDENT_OPPORTUNITY_REQUESTED",
    LIST_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED: "LIST_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED",
    LIST_COMPANY_STUDENT_OPPORTUNITY_FAILED: "LIST_COMPANY_STUDENT_OPPORTUNITY_FAILED",

    SAVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED: "SAVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED",
    SAVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED: "SAVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED",
    SAVE_COMPANY_STUDENT_OPPORTUNITY_FAILED: "SAVE_COMPANY_STUDENT_OPPORTUNITY_FAILED",

    UPDATE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED: "UPDATE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED",
    UPDATE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED: "UPDATE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED",
    UPDATE_COMPANY_STUDENT_OPPORTUNITY_FAILED: "UPDATE_COMPANY_STUDENT_OPPORTUNITY_FAILED",

    DELETE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED: "DELETE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED",
    DELETE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED: "DELETE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED",
    DELETE_COMPANY_STUDENT_OPPORTUNITY_FAILED: "DELETE_COMPANY_STUDENT_OPPORTUNITY_FAILED",

    ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED: "ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED",
    ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED: "ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED",
    ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_FAILED: "ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_FAILED",

    LIST_COMPANY_STUDENT_TALENTS_REQUESTED: "LIST_COMPANY_STUDENT_TALENTS_REQUESTED",
    LIST_COMPANY_STUDENT_TALENTS_SUCCEEDED: "LIST_COMPANY_STUDENT_TALENTS_SUCCEEDED",
    LIST_COMPANY_STUDENT_TALENTS_FAILED: "LIST_COMPANY_STUDENT_TALENTS_FAILED",

    VIEW_COMPANY_STUDENT_TALENTS_REQUESTED: "VIEW_COMPANY_STUDENT_TALENTS_REQUESTED",
    VIEW_COMPANY_STUDENT_TALENTS_SUCCEEDED: "VIEW_COMPANY_STUDENT_TALENTS_SUCCEEDED",
    VIEW_COMPANY_STUDENT_TALENTS_FAILED: "VIEW_COMPANY_STUDENT_TALENTS_FAILED",

    CREATE_COLUMN_JOBS_REQUESTED: "CREATE_COLUMN_JOBS_REQUESTED",
    CREATE_COLUMN_JOBS_SUCCEEDED: "CREATE_COLUMN_JOBS_SUCCEEDED",
    CREATE_COLUMN_JOBS_FAILED: "CREATE_COLUMN_JOBS_FAILED",

    SAVE_PRE_MATCHES_FIELDS_REQUESTED: "SAVE_PRE_MATCHES_FIELDS_REQUESTED",
    SAVE_PRE_MATCHES_FIELDS_SUCCEEDED: "SAVE_PRE_MATCHES_FIELDS_SUCCEEDED",
    SAVE_PRE_MATCHES_FIELDS_FAILED: "SAVE_PRE_MATCHES_FIELDS_FAILED",

    LIST_PRE_MATCHES_REQUESTED: "LIST_PRE_MATCHES_REQUESTED",
    LIST_PRE_MATCHES_SUCCEEDED: "LIST_PRE_MATCHES_SUCCEEDED",
    LIST_PRE_MATCHES_FAILED: "LIST_PRE_MATCHES_FAILED"


};

// Action Creators
const actions = {

    all: (token, circleId, callback) => {
        return {
            type: types.LIST_COMPANY_STUDENT_OPPORTUNITY_REQUESTED,
            token,
            circleId,
            callback
        };
    },

    save: (token, data, callback) => {
        return {
            type: types.SAVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED,
            token,
            data,
            callback
        };
    },

    update: (token, data, callback) => {
        return {
            type: types.UPDATE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED,
            token,
            data,
            callback
        };
    },

    delete: (token, data, callback) => {
        return {
            type: types.DELETE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED,
            token,
            data,
            callback
        };
    },

    archive: (token, jobId, callback) => {
        return {
            type: types.ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED,
            token,
            jobId,
            callback
        };
    },

    getTalents: (token, jobId, callback) => {
        return {
            type: types.LIST_COMPANY_STUDENT_TALENTS_REQUESTED,
            token,
            jobId,
            callback
        };
    },

    getTalentCard: (token, circleId, userId, opportunity_id, callback) => {
        return {
            type: types.VIEW_COMPANY_STUDENT_TALENTS_REQUESTED,
            token,
            circleId,
            userId,
            opportunity_id,
            callback
        };
    },

    createColumn: (token, formdata, callback) => {
        return {
            type: types.CREATE_COLUMN_JOBS_REQUESTED,
            token,
            formdata,
            callback
        };
    },

    savePreMatches: (token, data, callback) => {
        return {
            type: types.SAVE_PRE_MATCHES_FIELDS_REQUESTED,
            token,
            data,
            callback
        };
    },

    listPreMatches: (token, jobId, callback) => {
        return {
            type: types.LIST_PRE_MATCHES_REQUESTED,
            token,
            jobId,
            callback
        };
    }

};


// Reducers
function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.LIST_COMPANY_STUDENT_OPPORTUNITY_REQUESTED:
        case types.SAVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED:
        case types.UPDATE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED:
        case types.DELETE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED:
        case types.ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED:
        case types.LIST_COMPANY_STUDENT_TALENTS_REQUESTED:
        case types.VIEW_COMPANY_STUDENT_TALENTS_REQUESTED:
        case types.CREATE_COLUMN_JOBS_REQUESTED:
        case types.SAVE_PRE_MATCHES_FIELDS_REQUESTED:
        case types.LIST_PRE_MATCHES_REQUESTED:
            return {
                ...state,
                loading: true
            };

        case types.LIST_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED:
        case types.SAVE_PRE_MATCHES_FIELDS_SUCCEEDED:
        case types.LIST_PRE_MATCHES_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };

        case types.SAVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED:
        case types.ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED:
            return {
                ...state,
                //data: [...state.data, action.payload.data],
                data: action.payload.data,
                error: "",
                loading: false
            };

        case types.UPDATE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED:
            return {
                ...state,
                data: [...state.data, action.payload.data],
                error: "",
                loading: false
            };

        case types.DELETE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED:
            return {
                ...state,
                data: [state.data.filter(item => item.id != action.payload.data.id)],
                error: "",
                loading: false
            };

        case types.LIST_COMPANY_STUDENT_TALENTS_SUCCEEDED:
            return {
                ...state,
                talents: action.payload.data,
                error: "",
                loading: false
            };

        case types.VIEW_COMPANY_STUDENT_TALENTS_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                loading: false
            };

        case types.CREATE_COLUMN_JOBS_SUCCEEDED:
            return {
                ...state,
                error: "",
                laoding: false
            };

        case types.LIST_COMPANY_STUDENT_OPPORTUNITY_FAILED:
        case types.SAVE_COMPANY_STUDENT_OPPORTUNITY_FAILED:
        case types.UPDATE_COMPANY_STUDENT_OPPORTUNITY_FAILED:
        case types.DELETE_COMPANY_STUDENT_OPPORTUNITY_FAILED:
        case types.ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_FAILED:
        case types.LIST_COMPANY_STUDENT_TALENTS_FAILED:
        case types.VIEW_COMPANY_STUDENT_TALENTS_FAILED:
        case types.CREATE_COLUMN_JOBS_FAILED:
        case types.SAVE_PRE_MATCHES_FIELDS_FAILED:
        case types.LIST_PRE_MATCHES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        default:
            return state;
    }
}

// Sagas
function* saga() {

    yield takeLatest(types.LIST_COMPANY_STUDENT_OPPORTUNITY_REQUESTED, allWorker);
    yield takeLatest(types.SAVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED, saveWorker);
    yield takeLatest(types.UPDATE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED, updateWorker);
    yield takeLatest(types.DELETE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED, deleteWorker);

    yield takeLatest(types.ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_REQUESTED, archiveWorker);

    yield takeLatest(types.LIST_COMPANY_STUDENT_TALENTS_REQUESTED, getTalentsWorker);
    yield takeLatest(types.VIEW_COMPANY_STUDENT_TALENTS_REQUESTED, getTalentCardWorker);
    yield takeLatest(types.CREATE_COLUMN_JOBS_REQUESTED, createColumnWorker);

    yield takeLatest(types.SAVE_PRE_MATCHES_FIELDS_REQUESTED, savePreMatchesWorker);
    yield takeLatest(types.LIST_PRE_MATCHES_REQUESTED, listPreMatchesWorker);


}

// Saga callback
function* allWorker({ token, circleId, callback }) {
    try {
        const response = yield call(all, { token, circleId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LIST_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.LIST_COMPANY_STUDENT_OPPORTUNITY_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveWorker({ token, data, callback }) {
    try {
        const response = yield call(save, { token, data });
        // console.log('saveWorker---', response);
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_STUDENT_OPPORTUNITY_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateWorker({ token, data, callback }) {
    try {
        const response = yield call(update, { token, data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_STUDENT_OPPORTUNITY_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ token, data, callback }) {
    try {
        const response = yield call(remove, { token, data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED,
            payload: response.data
        });
        // console.log('2222');

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_COMPANY_STUDENT_OPPORTUNITY_FAILED,
            payload: e.errors
        });
        // console.log('---3333');
        callFail(callback, e.errors);
    }
}

function* archiveWorker({ token, jobId, callback }) {
    try {
        const response = yield call(archive, { token, jobId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_SUCCEEDED,
            payload: response.data
        });
        // console.log('2222');

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.ARCHIVE_COMPANY_STUDENT_OPPORTUNITY_FAILED,
            payload: e.errors
        });
        // console.log('---3333');
        callFail(callback, e.errors);
    }
}

function* getTalentsWorker({ token, jobId, callback }) {
    try {
        const response = yield call(getTalents, { token, jobId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LIST_COMPANY_STUDENT_TALENTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.LIST_COMPANY_STUDENT_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getTalentCardWorker({ token, circleId, userId, opportunity_id, callback }) {
    try {
        const response = yield call(getTalentCard, { token, circleId, userId, opportunity_id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.VIEW_COMPANY_STUDENT_TALENTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.VIEW_COMPANY_STUDENT_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* createColumnWorker({ token, formdata, callback }) {
    try {
        const response = yield call(createColumn, { token, formdata });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.CREATE_COLUMN_JOBS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.CREATE_COLUMN_JOBS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function createColumn({ token, formdata }) {
    if (formdata.colomn_id) {
        return api.post(`/api/student/company/job-list/${token}/update-colomn`, formdata);
    } else {
        return api.post(`/api/student/company/job-list/${token}/create-colomn`, formdata);
    }

}

function* savePreMatchesWorker({ token, data, callback }) {
    try {
        const response = yield call(savePreMatches, { token, data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PRE_MATCHES_FIELDS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_PRE_MATCHES_FIELDS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* listPreMatchesWorker({ token, jobId, callback }) {
    try {
        const response = yield call(listPreMatches, { token, jobId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LIST_PRE_MATCHES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.LIST_PRE_MATCHES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}


// API call
function getTalents({ token, jobId }) {
    // return api.get(`/api/student/company/job-talent-list/${token}/${jobId}`);
    return api.get(`/api/student/company/job-list/v2/${token}/${jobId}`);
    // return api.get(`/api/company/${token}/student/talents`);
}

function getTalentCard({ token, circleId, userId, opportunity_id }) {
    return api.get(
        `/api/student/company/${token}/single-talent-card/target-profile/${circleId}/${opportunity_id}/user/${userId}`
    );
    // return api.get(`/api/company/${token}/student/talents`);
}

function all({ token, circleId }) {
    // student/company/job-list/{token}/{circle_id}/{limit}/{offset}
    // return api.get(`/api/company/${token}/student/opportunity`);
    return api.get(`/api/student/company/job-list/v2/${token}/get-opportunities/${circleId}/100/0`);
}

function save({ token, data }) {
    //student/company/job-list/{token}
    if (data.job_id > 0) {
        return api.put(`/api/student/company/job-list/${token}`, data);
    } else {
        return api.post(`/api/student/company/job-list/${token}`, data);
    }
}

function update({ token, data }) {
    return api.put(`/api/student/company/job-list/${token}`, data);
}

function remove({ token, data }) {
    return api.delete(`/api/student/company/job-list/${token}`, { data: data });
}

function archive({ token, jobId }) {
    return api.put(`/api/student/company/job-list/v2/${token}/change_status/${jobId}`);
}

function savePreMatches({ token, data }) {
    // console.log("redux-student-oppor-index", data);
    return api.post(`/api/student/${token}/opportunity-matches/save`, data);
}

function listPreMatches({ token, jobId }) {
    return api.get(`/api/student/${token}/opportunity-matches/pre-matches/${jobId}`);
}

export default { reducer, actions, saga };

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
// import "typeface-roboto";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import "../scss/index.scss";

import api from "./util/api";
import store from "./redux/store";
import App from "./App/App";
import ScrollToTop from "Util/ScrollToTop";

Sentry.init({
    dsn: "https://8409b0964cd74b6e8d204450e48ac55c@o1013260.ingest.sentry.io/5978604",
    integrations: [
        new TracingIntegrations.BrowserTracing({
            // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
    normalizeDepth: 10,
    ignoreErrors: ["ChunkLoadError", /Loading chunk [\d]+ failed/],
    beforeSend: (event) => {
        const localhost = /localhost/;
        const staging = /staging/;
        if (
            localhost.test(window.location.hostname) ||
            staging.test(window.location.hostname)
        ) {
            //console.log("Dont report to sentry");
            return null;
        }
        if (process.env.NODE_ENV === "production") {
            return event;
        }

        return null;
    }
});
try {
    const userCredentials = store.getState().user.data;
    api.setHeader(
        "Authorization",
        `${userCredentials.token_type} ${userCredentials.access_token}`
    );
} catch (e) {
    //
}

ReactDOM.render(
    <Router>
        <ScrollToTop />
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
    document.getElementById("app")
);

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";
import findIndex from "lodash/findIndex";

// Actions
const types = {
    SAVE_COMPANY_TRIBE_JOB_REQUESTED: "SAVE_COMPANY_TRIBE_JOB_REQUESTED",
    SAVE_COMPANY_TRIBE_JOB_SUCCEEDED: "SAVE_COMPANY_TRIBE_JOB_SUCCEEDED",
    SAVE_COMPANY_TRIBE_JOB_FAILED: "SAVE_COMPANY_TRIBE_JOB_FAILED",
    UPDATE_COMPANY_TRIBE_JOB_REQUESTED: "UPDATE_COMPANY_TRIBE_JOB_REQUESTED",
    UPDATE_COMPANY_TRIBE_JOB_SUCCEEDED: "UPDATE_COMPANY_TRIBE_JOB_SUCCEEDED",
    UPDATE_COMPANY_TRIBE_JOB_FAILED: "UPDATE_COMPANY_TRIBE_JOB_FAILED",
    GET_COMPANY_TRIBE_JOB_REQUESTED: "GET_COMPANY_TRIBE_JOB_REQUESTED",
    GET_COMPANY_TRIBE_JOB_SUCCEEDED: "GET_COMPANY_TRIBE_JOB_SUCCEEDED",
    GET_COMPANY_TRIBE_JOB_FAILED: "GET_COMPANY_TRIBE_JOB_FAILED",
    GET_COMPANY_TRIBE_JOB_ALL_REQUESTED: "GET_COMPANY_TRIBE_JOB_ALL_REQUESTED",
    GET_COMPANY_TRIBE_JOB_ALL_SUCCEEDED: "GET_COMPANY_TRIBE_JOB_ALL_SUCCEEDED",
    GET_COMPANY_TRIBE_JOB_ALL_FAILED: "GET_COMPANY_TRIBE_JOB_ALL_FAILED",
    UPDATE_COMPANY_TRIBE_JOB_STATUS_REQUESTED:
        "UPDATE_COMPANY_TRIBE_JOB_STATUS_REQUESTED",
    UPDATE_COMPANY_TRIBE_JOB_STATUS_SUCCEEDED:
        "UPDATE_COMPANY_TRIBE_JOB_STATUS_SUCCEEDED",
    UPDATE_COMPANY_TRIBE_JOB_STATUS_FAILED:
        "UPDATE_COMPANY_TRIBE_JOB_STATUS_FAILED"
};

// Action Creators
export const actions = {
    save: (data, token, callback) => {
        return {
            type: types.SAVE_COMPANY_TRIBE_JOB_REQUESTED,
            data,
            token,
            callback
        };
    },
    update: (data, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_TRIBE_JOB_REQUESTED,
            data,
            token,
            callback
        };
    },
    updateStatus: (itemId, status, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_TRIBE_JOB_STATUS_REQUESTED,
            itemId,
            status,
            token,
            callback
        };
    },
    get: (token, tribeJobId, callback) => {
        return {
            type: types.GET_COMPANY_TRIBE_JOB_REQUESTED,
            token,
            tribeJobId,
            callback
        };
    },
    getAll: (token, profileId, callback) => {
        return {
            type: types.GET_COMPANY_TRIBE_JOB_ALL_REQUESTED,
            token,
            profileId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    single: {},
    error: "",
    loading: false,
    singleLoading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_TRIBE_JOB_ALL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.UPDATE_COMPANY_TRIBE_JOB_REQUESTED:
        case types.SAVE_COMPANY_TRIBE_JOB_REQUESTED:
        case types.GET_COMPANY_TRIBE_JOB_REQUESTED:
            return {
                ...state,
                singleLoading: true
            };
        case types.SAVE_COMPANY_TRIBE_JOB_SUCCEEDED:
            return {
                ...state,
                data: [...state.data, action.payload.data],
                error: "",
                singleLoading: false
            };
        case types.UPDATE_COMPANY_TRIBE_JOB_SUCCEEDED:
            const INDEX = findIndex(state.data, {
                id: action.payload.data.id
            });
            let data = [...state.data];
            data.splice(INDEX, 1, {
                ...data[INDEX],
                name: action.payload.data.name
            });

            return {
                ...state,
                data: data,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.GET_COMPANY_TRIBE_JOB_ALL_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_TRIBE_JOB_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.SAVE_COMPANY_TRIBE_JOB_FAILED:
        case types.UPDATE_COMPANY_TRIBE_JOB_FAILED:
        case types.GET_COMPANY_TRIBE_JOB_ALL_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_TRIBE_JOB_FAILED:
            return {
                ...state,
                singleLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_COMPANY_TRIBE_JOB_REQUESTED, saveWorker);
    yield takeLatest(types.UPDATE_COMPANY_TRIBE_JOB_REQUESTED, updateWorker);
    yield takeLatest(
        types.UPDATE_COMPANY_TRIBE_JOB_STATUS_REQUESTED,
        updateStatusWorker
    );
    yield takeLatest(types.GET_COMPANY_TRIBE_JOB_REQUESTED, singleWorker);
    yield takeLatest(types.GET_COMPANY_TRIBE_JOB_ALL_REQUESTED, allWorker);
}

// Saga callback
function* saveWorker({ data, token, callback }) {
    try {
        const response = yield call(save, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_TRIBE_JOB_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_TRIBE_JOB_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateWorker({ data, token, callback }) {
    try {
        const response = yield call(update, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_TRIBE_JOB_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_TRIBE_JOB_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateStatusWorker({ itemId, status, token, callback }) {
    try {
        const response = yield call(updateStatus, { itemId, status, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_TRIBE_JOB_STATUS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_TRIBE_JOB_STATUS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* singleWorker({ token, tribeJobId, callback }) {
    try {
        const response = yield call(single, { tribeJobId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_TRIBE_JOB_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_TRIBE_JOB_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* allWorker({ token, profileId, callback }) {
    try {
        const response = yield call(all, { token, profileId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_TRIBE_JOB_ALL_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_TRIBE_JOB_ALL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data, token }) {
    return api.post(`/api/company/${token}/jobs`, data);
}

function update({ data, token }) {
    return api.post(`/api/company/${token}/jobs`, data);
}

function updateStatus({ status, itemId, token }) {
    return api.put(`/api/company/${token}/jobs/${itemId}/status/${status}`);
}

function single({ tribeJobId, token }) {
    return api.get(`/api/company/${token}/jobs/${tribeJobId}`);
}

function all({ profileId, token }) {
    return api.get(`/api/company/${token}/target-profile/${profileId}/jobs`);
}

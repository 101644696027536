import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_FREETEXT_REQUESTED: "GET_FREETEXT_REQUESTED",
    GET_FREETEXT_SUCCEEDED: "GET_FREETEXT_SUCCEEDED",
    GET_FREETEXT_FAILED: "GET_FREETEXT_FAILED",
    SAVE_FREETEXT_REQUESTED: "SAVE_FREETEXT_REQUESTED",
    SAVE_FREETEXT_SUCCEEDED: "SAVE_FREETEXT_SUCCEEDED",
    SAVE_FREETEXT_FAILED: "SAVE_FREETEXT_FAILED"
};

// Action Creators
export const actions = {
    get: (token, profileId, userId, callback) => {
        return {
            type: types.GET_FREETEXT_REQUESTED,
            token,
            profileId,
            userId,
            callback
        };
    },
    save: (token, profileId, userId, text, callback) => {
        return {
            type: types.SAVE_FREETEXT_REQUESTED,
            token,
            profileId,
            userId,
            text,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_FREETEXT_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_FREETEXT_SUCCEEDED:
            return {
                ...state,
                data: { ...state.data, [action.userId]: action.payload.data },
                error: "",
                loading: false
            };
        case types.GET_FREETEXT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_FREETEXT_REQUESTED, getWorker);
    yield takeLatest(types.SAVE_FREETEXT_REQUESTED, saveWorker);
}

// Saga callback
function* getWorker({ token, profileId, userId, callback }) {
    try {
        const response = yield call(get, {
            token,
            profileId,
            userId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_FREETEXT_SUCCEEDED,
            payload: response.data,
            userId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_FREETEXT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveWorker({ token, profileId, userId, text, callback }) {
    try {
        const response = yield call(save, {
            token,
            profileId,
            userId,
            text
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_FREETEXT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_FREETEXT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function get({ token, profileId, userId }) {
    return api.get(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/free-text`
    );
}

function save({ token, profileId, userId, text }) {
    return api.put(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/free-text`,
        { free_text: text }
    );
}

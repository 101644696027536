import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    COMPANY_LOGIN_REQUESTED: "COMPANY_LOGIN_REQUESTED",
    COMPANY_LOGIN_SUCCEEDED: "COMPANY_LOGIN_SUCCEEDED",
    COMPANY_LOGIN_FAILED: "COMPANY_LOGIN_FAILED",

    COMPANY_AUTH_USER_REQUESTED: "COMPANY_AUTH_USER_REQUESTED",
    COMPANY_AUTH_USER_SUCCEEDED: "COMPANY_AUTH_USER_SUCCEEDED",
    COMPANY_AUTH_USER_FAILED: "COMPANY_AUTH_USER_FAILED",

    COMPANY_LOGOUT_AUTH_USER_REQUESTED: "COMPANY_LOGOUT_AUTH_USER_REQUESTED",
    COMPANY_LOGOUT_AUTH_USER_SUCCEEDED: "COMPANY_LOGOUT_AUTH_USER_SUCCEEDED",
    COMPANY_LOGOUT_AUTH_USER_FAILED: "COMPANY_LOGOUT_AUTH_USER_FAILED",

    COMPLETE_COMPANY_TUTORIAL_REQUESTED: "COMPLETE_COMPANY_TUTORIAL_REQUESTED",
    COMPLETE_COMPANY_TUTORIAL_SUCCEEDED: "COMPLETE_COMPANY_TUTORIAL_SUCCEEDED",
    COMPLETE_COMPANY_TUTORIAL_FAILED: "COMPLETE_COMPANY_TUTORIAL_FAILED",

    COMPANY_LOGIN_WITH_SOCIAL_REQUESTED: "COMPANY_LOGIN_WITH_SOCIAL_REQUESTED",
    COMPANY_LOGIN_WITH_SOCIAL_SUCCEEDED: "COMPANY_LOGIN_WITH_SOCIAL_SUCCEEDED",
    COMPANY_LOGIN_WITH_SOCIAL_FAILED: "COMPANY_LOGIN_WITH_SOCIAL_FAILED"
};

// Action Creators
export const actions = {
    login: (email, callback) => {
        return {
            type: types.COMPANY_LOGIN_REQUESTED,
            email,
            callback
        };
    },
    authUser: (token, email, callback) => {
        return {
            type: types.COMPANY_AUTH_USER_REQUESTED,
            token,
            email,
            callback
        };
    },
    logoutAuthUser: (token, callback) => {
        return {
            type: types.COMPANY_LOGOUT_AUTH_USER_REQUESTED,
            token,
            callback
        };
    },
    completeTutorial: (authorId, token, callback) => {
        return {
            type: types.COMPLETE_COMPANY_TUTORIAL_REQUESTED,
            authorId,
            token,
            callback
        };
    },
    loginWithSocial: (social, callback) => {
        return {
            type: types.COMPANY_LOGIN_WITH_SOCIAL_REQUESTED,
            social,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: null,
    user: null,
    auth: false,
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.COMPANY_LOGIN_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.COMPANY_LOGIN_SUCCEEDED:
            return {
                ...state,
                data: action.payload.success,
                error: "",
                loading: false
            };
        case types.COMPANY_LOGIN_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.COMPANY_AUTH_USER_REQUESTED:
            return {
                ...state
            };
        case types.COMPANY_AUTH_USER_SUCCEEDED:
            return {
                ...state,
                user: action.payload.data,
                auth: true
            };
        case types.COMPANY_AUTH_USER_FAILED:
            return {
                ...state,
                user: null,
                auth: false
            };
        case types.COMPANY_LOGOUT_AUTH_USER_REQUESTED:
            return {
                ...state,
                user: null,
                auth: false
            };

        case types.COMPANY_LOGIN_WITH_SOCIAL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.COMPANY_LOGIN_WITH_SOCIAL_SUCCEEDED:
            return {
                ...state,
                data: action.payload.success,
                error: "",
                loading: false
            };
        case types.COMPANY_LOGIN_WITH_SOCIAL_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.COMPLETE_COMPANY_TUTORIAL_SUCCEEDED:
            return {
                ...state,
                user: { ...state.user, tutorial_done: true }
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.COMPANY_LOGIN_REQUESTED, loginWorker);
    yield takeLatest(types.COMPANY_AUTH_USER_REQUESTED, authUserWorker);
    yield takeLatest(types.COMPLETE_COMPANY_TUTORIAL_REQUESTED, completeWorker);
    yield takeLatest(types.COMPANY_LOGIN_WITH_SOCIAL_REQUESTED, loginWithSocialWorker);
    yield takeLatest(types.COMPANY_LOGOUT_AUTH_USER_REQUESTED, logoutWorker);
}

// Saga callback
function* loginWorker({ email, callback }) {
    try {
        const response = yield call(login, { email });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.COMPANY_LOGIN_SUCCEEDED,
            payload: response.data
        });
        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.COMPANY_LOGIN_FAILED,
            payload: e.errors
        });
        callback(false, e.errors);
    }
}

function* loginWithSocialWorker({ social, callback }) {
    try {
        const response = yield call(loginWithSocial, { social });

        if (!response.ok) {
            throw response.data;
        }
        yield put({
            type: types.COMPANY_LOGIN_WITH_SOCIAL_SUCCEEDED,
            payload: response.data
        });
        typeof callback == "function" && callback(response.data);
        //callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.COMPANY_LOGIN_WITH_SOCIAL_FAILED,
            payload: e.errors
        });
        callback(false, e.errors);
        //callFail(callback, e.errors);
    }
}

function* authUserWorker({ token, email, callback }) {
    try {
        const response = yield call(authUser, { token, email });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.COMPANY_AUTH_USER_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.COMPANY_AUTH_USER_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* completeWorker({ token, authorId, callback }) {
    try {
        const response = yield call(completeTutorial, { token, authorId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.COMPLETE_COMPANY_TUTORIAL_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.COMPLETE_COMPANY_TUTORIAL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* logoutWorker({ token, callback }) {
    try {
        const response = yield call(logout, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.COMPANY_LOGOUT_AUTH_USER_SUCCEEDED,
            payload: response.data
        });
        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.COMPANY_LOGOUT_AUTH_USER_FAILED,
            payload: e.errors
        });
        callback(false, e.errors);
    }
}

// API call
function login({ email }) {
    return api.post(`/api/request-magic-link`, { email });
}

function authUser({ token, email }) {
    return api.get(`/api/company/${token}/authors/${email}`);
}

function completeTutorial({ token, authorId }) {
    return api.put(`/api/company/${token}/tutorial-done`, {
        author_id: authorId
    });
}

// social login API
function loginWithSocial({ social }) {
    return api.post(`/api/login-with-social`, { social });
}

function logout({ token }) {
    return api.get(`/api/company/${token}/logout`);
}

import React from "react";
import * as Sentry from "@sentry/react";

import styled from "styled-components";

const FlexContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    @media only screen and (max-width: 700px) {
        flex-direction: column;
    }
`;

const FlexChildOne = styled.div`
    flex: 3;
    text-align: center;
`;

const FlexChildTwo = styled.div`
    flex: 1;
    text-align: center;
`;

const ErrorCode = styled.div`
    color: #666666;
    font-family: Roboto Slab, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 250px;
    line-height: 330px;
`;

const ErrorText = styled.div`
    margin-bottom: 40px;
    color: #333333;
    font-family: Roboto Slab, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 40px;
`;

const ErrorInfo = styled.div`
    color: #222222;
    font-family: PT Sans, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
`;

const MailLink = styled.a`
    color: #448da2;
    text-decoration: none;
    font-weight: bold;
`;

function setWithExpiry(key, value, ttl) {
    const item = {
        value: value,
        expiry: new Date().getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

const FallbackComponent = () => {
    return (
        <div>
            <div className="tm_logo">
                <img src="/images/tm_logo_404.png" alt="tm_logo" />
            </div>
            <FlexContainer>
                <FlexChildOne>
                    <ErrorCode>404</ErrorCode>
                    <ErrorText>
                        Sorry! We are just updating the servers right now <br />
                        and will be back shortly.
                    </ErrorText>
                    <ErrorInfo>
                        <p>Please test again in 5 minutes.</p>
                        <p>If you are still facing problems, contact</p>
                        <p>
                            <MailLink href="mailto:info@talentmatchglobal.com">
                                info@talentmatchglobal.com
                            </MailLink>
                        </p>
                    </ErrorInfo>
                </FlexChildOne>
                <FlexChildTwo>
                    <img src="/images/tm_vector_404.png" alt="tm_vector" />
                </FlexChildTwo>
            </FlexContainer>
        </div>
    );
};

const ErrorBoundary = ({ children }) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error }) => {
                // Handle failed lazy loading of a JS/CSS chunk.
                const chunkFailedMessage = /Loading chunk [\d]+ failed/;
                if (
                    error &&
                    error.message &&
                    chunkFailedMessage.test(error.message)
                ) {
                    if (!getWithExpiry("chunk_failed")) {
                        setWithExpiry("chunk_failed", "true", 10000);
                        // console.log("Chunk load failed. Refreshing...");
                        window.location.reload();
                    } else {
                        return <FallbackComponent />;
                    }
                } else {
                    return <FallbackComponent />;
                }
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};

export default ErrorBoundary;

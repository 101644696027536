import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_FILTER_REQUESTED: "SAVE_FILTER_REQUESTED",
    SAVE_FILTER_SUCCEEDED: "SAVE_FILTER_SUCCEEDED",
    SAVE_FILTER_FAILED: "SAVE_FILTER_FAILED",
    GET_FILTERS_REQUESTED: "GET_FILTERS_REQUESTED",
    GET_FILTERS_SUCCEEDED: "GET_FILTERS_SUCCEEDED",
    GET_FILTERS_FAILED: "GET_FILTERS_FAILED",
    DELETE_FILTER_REQUESTED: "DELETE_FILTER_REQUESTED",
    DELETE_FILTER_SUCCEEDED: "DELETE_FILTER_SUCCEEDED",
    DELETE_FILTER_FAILED: "DELETE_FILTER_FAILED"
};

// Action Creators
export const actions = {
    save: (token, filters, name, callback) => {
        return {
            type: types.SAVE_FILTER_REQUESTED,
            filters,
            name,
            token,
            callback
        };
    },
    get: (token, callback) => {
        return {
            type: types.GET_FILTERS_REQUESTED,
            token,
            callback
        };
    },
    deleteFilter: (token, filter, callback) => {
        return {
            type: types.DELETE_FILTER_REQUESTED,
            filter,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SAVE_FILTER_REQUESTED: {
            return {
                ...state
            };
        }
        case types.GET_FILTERS_REQUESTED: {
            return {
                ...state,
                loading: true
            };
        }
        case types.GET_FILTERS_SUCCEEDED: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case types.GET_FILTERS_FAILED: {
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        }
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_FILTER_REQUESTED, saveWorker);
    yield takeLatest(types.GET_FILTERS_REQUESTED, getWorker);
    yield takeLatest(types.DELETE_FILTER_REQUESTED, deleteWorker);
}

// Saga callback
function* saveWorker({ filters, name, token, callback }) {
    try {
        const response = yield call(save, { filters, token, name });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_FILTER_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_FILTER_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_FILTERS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_FILTERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ token, filter, callback }) {
    try {
        const response = yield call(deleteFilter, { token, filter });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_FILTER_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_FILTER_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ token, filters, name }) {
    return api.post(`/api/company/${token}/search-filters`, {
        filter_name: name,
        filter_query: filters
    });
}

function get({ token }) {
    return api.get(`/api/company/${token}/search-filters`);
}

function deleteFilter({ token, filter }) {
    return api.delete(`/api/company/${token}/search-filters/${filter.id}`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_JOBS_REQUESTED: "GET_COMPANY_JOBS_REQUESTED",
    GET_COMPANY_JOBS_SUCCEEDED: "GET_COMPANY_JOBS_SUCCEEDED",
    GET_COMPANY_JOBS_FAILED: "GET_COMPANY_JOBS_FAILED",

    SAVE_COMPANY_JOBS_REQUESTED: "UPDATE_COMPANY_JOBS_REQUESTED",
    SAVE_COMPANY_JOBS_SUCCEEDED: "UPDATE_COMPANY_JOBS_SUCCEEDED",
    SAVE_COMPANY_JOBS_FAILED: "UPDATE_COMPANY_JOBS_FAILED",

    DELETE_COMPANY_JOBS_REQUESTED: "DELETE_COMPANY_JOBS_REQUESTED",
    DELETE_COMPANY_JOBS_SUCCEEDED: "DELETE_COMPANY_JOBS_SUCCEEDED",
    DELETE_COMPANY_JOBS_FAILED: "DELETE_COMPANY_JOBS_FAILED",

    GET_COMPANY_JOBS_TALENTS_REQUESTED: "GET_COMPANY_JOBS_TALENTS_REQUESTED",
    GET_COMPANY_JOBS_TALENTS_SUCCEEDED: "GET_COMPANY_JOBS_TALENTS_SUCCEEDED",
    GET_COMPANY_JOBS_TALENTS_FAILED: "GET_COMPANY_JOBS_TALENTS_FAILED",

    GET_COMPANY_MATCH_CARD_REQUESTED: "GET_COMPANY_MATCH_CARD_REQUESTED",
    GET_COMPANY_MATCH_CARD_SUCCEEDED: "GET_COMPANY_MATCH_CARD_SUCCEEDED",
    GET_COMPANY_MATCH_CARD_FAILED: "GET_COMPANY_MATCH_CARD_FAILED",

    CREATE_COLUMN_JOBS_REQUESTED: "CREATE_COLUMN_JOBS_REQUESTED",
    CREATE_COLUMN_JOBS_SUCCEEDED: "CREATE_COLUMN_JOBS_SUCCEEDED",
    CREATE_COLUMN_JOBS_FAILED: "CREATE_COLUMN_JOBS_FAILED",

    SAVE_COMPANY_PRE_MATCH_REQUESTED: "SAVE_COMPANY_PRE_MATCH_REQUESTED",
    SAVE_COMPANY_PRE_MATCH_SUCCEEDED: "SAVE_COMPANY_PRE_MATCH_SUCCEEDED",
    SAVE_COMPANY_PRE_MATCH_FAILED: "SAVE_COMPANY_PRE_MATCH_FAILED"
};

// Action Creators
export const actions = {
    get: (token, circle_id, page_size, callback) => {
        return {
            type: types.GET_COMPANY_JOBS_REQUESTED,
            token,
            circle_id,
            page_size,
            callback
        };
    },
    save: (token, formdata, callback) => {
        return {
            type: types.SAVE_COMPANY_JOBS_REQUESTED,
            token,
            formdata,
            callback
        };
    },
    savePreMatches: (token, formdata, callback) => {
        return {
            type: types.SAVE_COMPANY_PRE_MATCH_REQUESTED,
            token,
            formdata,
            callback
        };
    },
    delete: (token, job_id, callback) => {
        return {
            type: types.DELETE_COMPANY_JOBS_REQUESTED,
            token,
            job_id,
            callback
        };
    },
    getTalents: (token, id, callback) => {
        return {
            type: types.GET_COMPANY_JOBS_TALENTS_REQUESTED,
            token,
            id,
            callback
        };
    },
    getTalentCard: (token, circle_id, user_id, callback) => {
        return {
            type: types.GET_COMPANY_MATCH_CARD_REQUESTED,
            token,
            circle_id,
            user_id,
            callback
        };
    },
    createColumn: (token, formdata, callback) => {
        return {
            type: types.CREATE_COLUMN_JOBS_REQUESTED,
            token,
            formdata,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SAVE_COMPANY_JOBS_REQUESTED:
        case types.SAVE_COMPANY_PRE_MATCH_REQUESTED:
        case types.CREATE_COLUMN_JOBS_REQUESTED:
            return {
                ...state,
                loading: false
            };

        case types.GET_COMPANY_JOBS_REQUESTED:
        case types.DELETE_COMPANY_JOBS_REQUESTED:
        case types.GET_COMPANY_JOBS_TALENTS_REQUESTED:
        case types.GET_COMPANY_MATCH_CARD_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_JOBS_SUCCEEDED:
        case types.SAVE_COMPANY_JOBS_SUCCEEDED:
        case types.CREATE_COLUMN_JOBS_SUCCEEDED:
        case types.DELETE_COMPANY_JOBS_SUCCEEDED:
        case types.GET_COMPANY_JOBS_TALENTS_SUCCEEDED:
        case types.GET_COMPANY_MATCH_CARD_SUCCEEDED:
        case types.SAVE_COMPANY_PRE_MATCH_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_JOBS_FAILED:
        case types.SAVE_COMPANY_JOBS_FAILED:
        case types.CREATE_COLUMN_JOBS_FAILED:
        case types.DELETE_COMPANY_JOBS_FAILED:
        case types.GET_COMPANY_JOBS_TALENTS_FAILED:
        case types.GET_COMPANY_MATCH_CARD_FAILED:
        case types.SAVE_COMPANY_PRE_MATCH_FAILED:
            return {
                ...state,
                data: [],
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_JOBS_REQUESTED, getWorker);
    yield takeLatest(types.SAVE_COMPANY_JOBS_REQUESTED, saveWorker);
    yield takeLatest(types.SAVE_COMPANY_PRE_MATCH_REQUESTED, savePreMatchWorker);
    yield takeLatest(types.DELETE_COMPANY_JOBS_REQUESTED, deleteWorker);
    yield takeLatest(types.GET_COMPANY_JOBS_TALENTS_REQUESTED, getTalentsWorker);
    yield takeLatest(types.GET_COMPANY_MATCH_CARD_REQUESTED, getTalentCardWorker);
    yield takeLatest(types.CREATE_COLUMN_JOBS_REQUESTED, createColumnWorker);
}

// Saga callback
function* getWorker({ token, circle_id, page_size, callback }) {
    try {
        const response = yield call(get, { token, circle_id, page_size });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.GET_COMPANY_JOBS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_JOBS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function get({ token, circle_id, page_size }) {
    // return api.get(`/api/company/job-list/${token}/${circle_id}/${page_size}/0`);
    return api.get(`/api/company/job-list/v2/${token}/get-opportunities/${circle_id}/${page_size}/0`);
}

//-------------------------
function* saveWorker({ token, formdata, callback }) {
    try {
        const response = yield call(save, { token, formdata });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.SAVE_COMPANY_JOBS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_JOBS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function save({ token, formdata }) {
    if (parseInt(formdata.job_id) > 0) {
        return api.put(`/api/company/job-list/${token}`, formdata);
    } else {
        return api.post(`/api/company/job-list/${token}`, formdata);
    }
}

//---------------
function* savePreMatchWorker({ token, formdata, callback }) {
    try {
        const response = yield call(savePreMatch, { token, formdata });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.SAVE_COMPANY_PRE_MATCH_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_PRE_MATCH_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function savePreMatch({ token, formdata }) {
    return api.post(`/api/company/job-list/${token}/save-pre-matches`, formdata);
}


//-------------------------
function* deleteWorker({ token, job_id, callback }) {
    try {
        const response = yield call(remove, { token, job_id });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.DELETE_COMPANY_JOBS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_COMPANY_JOBS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function remove({ token, job_id }) {
    return api.delete(`/api/company/job-list/${token}/`, { data: { job_id: job_id } });
}

//-------------------------
function* getTalentsWorker({ token, id, callback }) {
    try {
        const response = yield call(getTalents, { token, id });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.GET_COMPANY_JOBS_TALENTS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_JOBS_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function getTalents({ token, id }) {
    // return api.get(`/api/company/job-talent-list/${token}/${id}`);
    return api.get(`/api/company/job-list/v2/${token}/${id}`);
}

//---------------------------
function* getTalentCardWorker({ token, circle_id, user_id, callback }) {
    try {
        const response = yield call(getTalentCard, { token, circle_id, user_id });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.GET_COMPANY_MATCH_CARD_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_MATCH_CARD_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function getTalentCard({ token, circle_id, user_id }) {
    return api.get(
        `/api/company/${token}/single-talent-card/target-profile/${circle_id}/user/${user_id}`
    );
}


function* createColumnWorker({ token, formdata, callback }) {
    try {
        const response = yield call(createColumn, { token, formdata });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.CREATE_COLUMN_JOBS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.CREATE_COLUMN_JOBS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function createColumn({ token, formdata }) {
    if (formdata.colomn_id) {
        return api.post(`/api/company/job-list/${token}/update-colomn`, formdata);
    } else {
        return api.post(`/api/company/job-list/${token}/create-colomn`, formdata);
    }

}

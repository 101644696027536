import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPDATE_COMPANY_DRAFT_PROFILE_REQUESTED:
        "UPDATE_COMPANY_DRAFT_PROFILE_REQUESTED",
    UPDATE_COMPANY_DRAFT_PROFILE_SUCCEEDED:
        "UPDATE_COMPANY_DRAFT_PROFILE_SUCCEEDED",
    UPDATE_COMPANY_DRAFT_PROFILE_FAILED: "UPDATE_COMPANY_DRAFT_PROFILE_FAILED",
    GET_COMPANY_DRAFT_PROFILE_REQUESTED: "GET_COMPANY_DRAFT_PROFILE_REQUESTED",
    GET_COMPANY_DRAFT_PROFILE_SUCCEEDED: "GET_COMPANY_DRAFT_PROFILE_SUCCEEDED",
    GET_COMPANY_DRAFT_PROFILE_FAILED: "GET_COMPANY_DRAFT_PROFILE_FAILED",
    DISCARD_COMPANY_DRAFT_PROFILE_REQUESTED:
        "DISCARD_COMPANY_DRAFT_PROFILE_REQUESTED",
    DISCARD_COMPANY_DRAFT_PROFILE_SUCCEEDED:
        "DISCARD_COMPANY_DRAFT_PROFILE_SUCCEEDED",
    DISCARD_COMPANY_DRAFT_PROFILE_FAILED: "DISCARD_COMPANY_DRAFT_PROFILE_FAILED"
};

// Action Creators
export const actions = {
    update: (profileId, data, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_DRAFT_PROFILE_REQUESTED,
            profileId,
            data,
            token,
            callback
        };
    },
    get: (token, profileId, callback) => {
        return {
            type: types.GET_COMPANY_DRAFT_PROFILE_REQUESTED,
            token,
            profileId,
            callback
        };
    },
    discard: (token, profileId, callback) => {
        return {
            type: types.DISCARD_COMPANY_DRAFT_PROFILE_REQUESTED,
            token,
            profileId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: null,
    error: "",
    loading: true,
    saving: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_DRAFT_PROFILE_REQUESTED:
        case types.DISCARD_COMPANY_DRAFT_PROFILE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.UPDATE_COMPANY_DRAFT_PROFILE_REQUESTED:
            return {
                ...state,
                saving: true
            };
        case types.DISCARD_COMPANY_DRAFT_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: null,
                error: "",
                loading: false
            };
        case types.UPDATE_COMPANY_DRAFT_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                saving: false
            };
        case types.GET_COMPANY_DRAFT_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.DISCARD_COMPANY_DRAFT_PROFILE_FAILED:
            return {
                ...state,
                data: null,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_DRAFT_PROFILE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.UPDATE_COMPANY_DRAFT_PROFILE_FAILED:
            return {
                ...state,
                saving: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.UPDATE_COMPANY_DRAFT_PROFILE_REQUESTED,
        updateWorker
    );
    yield takeLatest(types.GET_COMPANY_DRAFT_PROFILE_REQUESTED, getWorker);
    yield takeLatest(
        types.DISCARD_COMPANY_DRAFT_PROFILE_REQUESTED,
        discardWorker
    );
}

// Saga callback
function* updateWorker({ profileId, data, token, callback }) {
    try {
        const response = yield call(update, { profileId, data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_DRAFT_PROFILE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_DRAFT_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, profileId, callback }) {
    try {
        const response = yield call(get, { profileId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_DRAFT_PROFILE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_DRAFT_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* discardWorker({ profileId, token, callback }) {
    try {
        const response = yield call(deleteDraft, { profileId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DISCARD_COMPANY_DRAFT_PROFILE_SUCCEEDED
        });

        callSuccess(callback, {});
    } catch (e) {
        yield put({
            type: types.DISCARD_COMPANY_DRAFT_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function update({ profileId, data, token }) {
    return api.put(`/api/company/${token}/draft/company/${profileId}`, data);
}

function get({ profileId, token }) {
    return api.get(`/api/company/${token}/draft/company/${profileId}`);
}

function deleteDraft({ profileId, token }) {
    return api.delete(`/api/company/${token}/draft/company/${profileId}`);
}

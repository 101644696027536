const routes = {
    COMPANY_COMPARISON_REPORT_1: "/company/:token/comparison/part1",
    COMPANY_COMPARISON_REPORT_2_2: "/company/:token/comparison/part2/2",
    COMPANY_COMPARISON_REPORT_2: "/company/:token/comparison/part2",
    COMPANY_COMPARISON_REPORT_3: "/company/:token/comparison/:targetProfileId/part3",
    COMPANY_COMPARISON_REPORT_3_2: "/company/:token/comparison/:targetProfileId/part3/2",
    COMPANY_COMPARISON_REPORT_3_3: "/company/:token/comparison/:targetProfileId/part3/3",
    COMPANY_PROFILE: "/company/:token/profile",
    COMPANY_HOME: "/company/:token/home",
    COMPANY_EMPLOYER_PAGE_ABOUT: "/company/:token/employer-page/about",
    COMPANY_EMPLOYER_PAGE_TEAM: "/company/:token/employer-page/team",
    COMPANY_EMPLOYER_PAGE_BENEFITS: "/company/:token/employer-page/benefits",
    COMPANY_EMPLOYER_PAGE_EVP: "/company/:token/employer-page/evp",
    COMPANY_EMPLOYER_PAGE_CONTACT: "/company/:token/employer-page/contact",
    COMPANY_EMPLOYER_PAGE_PREVIEW: "/company/:token/employer-page/preview",
    COMPANY_CULTURE: "/company/:token/culture",

    COMPANY_EDIT_TRIBE: "/company/:token/edit-tribe-category/:targetProfileId",
    COMPANY_TRIBE: "/company/:token/tribe-category",


    COMPANY_TRIBE_CATEGORY: "/company/:token/tribe-category/:category",
    COMPANY_TRIBE_CONTENT_OVERVIEW: "/company/:token/tribe/:targetProfileId/content/overview",
    COMPANY_TRIBE_PREVIEW: "/company/:token/tribe/:targetProfileId/preview",
    COMPANY_COMMUNICATION_MEMBER: "/company/:token/communication/member",
    COMPANY_COMMUNICATION_CHATBOX: "/company/:token/communication/chatbox/:targetProfileId",

    COMPANY_TRIBE_NEW_JOB: "/company/:token/tribe/:targetProfileId/job/part1",
    COMPANY_TRIBE_NEW_JOB_2: "/company/:token/tribe/:targetProfileId/job/part2",
    COMPANY_TRIBE_COPY_JOB: "/company/:token/tribe/:targetProfileId/copy-job/part1/:jobId",
    COMPANY_TRIBE_EDIT_JOB: "/company/:token/tribe/:targetProfileId/job/part1/:jobId",
    COMPANY_TRIBE_EDIT_JOB_2: "/company/:token/tribe/:targetProfileId/job/part2/:jobId",
    COMPANY_TRIBE_NEW_GIG: "/company/:token/tribe/:targetProfileId/gig/part1",
    COMPANY_TRIBE_NEW_GIG_2: "/company/:token/tribe/:targetProfileId/gig/part2",
    COMPANY_TRIBE_COPY_GIG: "/company/:token/tribe/:targetProfileId/copy-gig/part1/:gigId",
    COMPANY_TRIBE_EDIT_GIG: "/company/:token/tribe/:targetProfileId/gig/part1/:gigId",
    COMPANY_TRIBE_EDIT_GIG_2: "/company/:token/tribe/:targetProfileId/gig/part2/:gigId",
    COMPANY_TRIBE_NEW_CONTENT: "/company/:token/tribe/:targetProfileId/content/part1",
    COMPANY_TRIBE_NEW_CONTENT_2: "/company/:token/tribe/:targetProfileId/content/part2",
    COMPANY_TRIBE_COPY_CONTENT: "/company/:token/tribe/:targetProfileId/copy-content/part1/:contentId",
    COMPANY_TRIBE_EDIT_CONTENT: "/company/:token/tribe/:targetProfileId/content/part1/:contentId",
    COMPANY_TRIBE_EDIT_CONTENT_2: "/company/:token/tribe/:targetProfileId/content/part2/:contentId",
    COMPANY_TRIBE_NEW_EVENT: "/company/:token/tribe/:targetProfileId/event/part1",
    COMPANY_TRIBE_NEW_EVENT_2: "/company/:token/tribe/:targetProfileId/event/part2",
    COMPANY_TRIBE_COPY_EVENT: "/company/:token/tribe/:targetProfileId/copy-event/part1/:eventId",
    COMPANY_TRIBE_EDIT_EVENT: "/company/:token/tribe/:targetProfileId/event/part1/:eventId",
    COMPANY_TRIBE_EDIT_EVENT_2: "/company/:token/tribe/:targetProfileId/event/part2/:eventId",
    COMPANY_NEW_BLOG: "/company/:token/:targetProfileId/blog",
    COMPANY_EDIT_BLOG: "/company/:token/:targetProfileId/blog/:blogId",
    COMPANY_MATCHING_DASHBOARD: "/company/:token/matching-dashboard",
    COMPANY_MATCHING_STATISTICS: "/company/:token/matching-statistics/:targetProfileId/:page",

    COMPANY_JOBS_LIST: "/company/:token/jobs/:targetProfileId/list/:page",
    COMPANY_JOBS_ADD: "/company/:token/jobs/:targetProfileId/add",
    COMPANY_JOBS_EDIT: "/company/:token/jobs/:targetProfileId/edit/:jobId",
    COMPANY_JOBS_PRE_MATCHES: "/company/:token/jobs/:targetProfileId/prematch/:jobId",
    COMPANY_JOBS_VIEW: "/company/:token/jobs/:targetProfileId/view/:jobId",

    COMPANY_TALENT_LIST: "/company/:token/talents/:targetProfileId/:page",
    COMPANY_TALENT_CARD: "/company/:token/talent-card/:userEmail/:targetProfileId",

    COMPANY_MY_TRIBE: "/company/:token/my-tribe/:targetProfileId/:page",
    COMPANY_TRIBE_ACTIVITIES: "/company/:token/tribe/:targetProfileId/activities/:activityType",
    BLOG_HOME: "/blog/:token",
    NEW_CATEGORY_BLOG: "/blog/:token/category/new",
    NEW_GENERAL_BLOG: "/blog/:token/general/new",
    EDIT_GENERAL_BLOG: "/blog/:token/general/edit/:blogId",
    EDIT_CATEGORY_BLOG: "/blog/:token/category/edit/:blogId",
    LIST_CATEGORY_BLOG: "/blog/:token/category/list",
    LIST_GENERAL_BLOG: "/blog/:token/general/list",

    // COMPANY_ANALYTICS_HOME: "/company/:token/analytics",

    COMPANY_ANALYTICS_OVERVIEW: "/company/:token/overview",
    COMPANY_ANALYTICS_INSIGHTS: "/company/:token/insights",
    COMPANY_ANALYTICS_OPPORTUNITIES: "/company/:token/opportunities",
    COMPANY_ANALYTICS_EXPOSURE: "/company/:token/exposure",

    SETTINGS_MAGIC_LINKS: "/blog/:token/settings/magic-links",
    SETTINGS_CIRCLE_STATUS: "/blog/:token/settings/circle-status",
    COMPANY_AUTHOR_LIST: "/company/:token/author/list",
    COMPANY_AUTHOR_CREATE: "/company/:token/author/create",
    COMPANY_AUTHOR_INVITE: "/company/:token/author/invite",
    COMPANY_AUTHOR_EDIT: "/company/:token/author/edit/:authorId",
    BLOG_AUTHOR_LIST: "/blog/:token/author/list",
    BLOG_AUTHOR_CREATE: "/blog/:token/author/create",
    BLOG_AUTHOR_EDIT: "/blog/:token/author/edit/:authorId",
    COMPANY_SIGNUP: "/company/sign-up",
    COMPANY_LOGIN: "/company/login",
    COMPANY_NOTIFICATION: "/company/:token/notification",

    BLOG_COMPANY_LIST: "/blog/:token/company/list",

    COMPANY_STUDENT_CATEGORY: "/company/:token/student/category/:categoryId",
    COMPANY_STUDENT_TALENTS: "/company/:token/student/:circleId/talents",
    COMPANY_STUDENT_JOBS: "/company/:token/student/:circleId/jobs",
    COMPANY_STUDENT_JOBS_ADD: "/company/:token/student/:circleId/job/add",
    COMPANY_STUDENT_JOBS_EDIT: "/company/:token/student/:circleId/job/edit/:jobId",
    COMPANY_STUDENT_JOBS_VIEW: "/company/:token/student/:circleId/job/view/:jobId",
    COMPANY_STUDENT_JOBS_PRE_MATCHES: "/company/:token/student/:circleId/job/pre-matches/:jobId"


};

export function getCustomLink(route, parmters) {
    if (typeof parmters != 'object') return '#';
    for (const [key, value] of Object.entries(parmters)) {
        route = route.replaceAll(":" + key, value);
    }
    return route;
}

export default routes;

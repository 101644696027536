import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_BENCHMARKS_CULTURE_REQUESTED: "GET_BENCHMARKS_CULTURE_REQUESTED",
    GET_BENCHMARKS_CULTURE_SUCCEEDED: "GET_BENCHMARKS_CULTURE_SUCCEEDED",
    GET_BENCHMARKS_CULTURE_FAILED: "GET_BENCHMARKS_CULTURE_FAILED"
};

// Action Creators
export const actions = {
    get: callback => {
        return {
            type: types.GET_BENCHMARKS_CULTURE_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_BENCHMARKS_CULTURE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_BENCHMARKS_CULTURE_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_BENCHMARKS_CULTURE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_BENCHMARKS_CULTURE_REQUESTED, getWorker);
}

// Saga callback
function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_BENCHMARKS_CULTURE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_BENCHMARKS_CULTURE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get() {
    return api.get(`/api/benchmarks/culture/category/all`);
}

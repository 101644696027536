import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_CIRCLE_LINKS_REQUESTED: "GET_CIRCLE_LINKS_REQUESTED",
    GET_CIRCLE_LINKS_SUCCEEDED: "GET_CIRCLE_LINKS_SUCCEEDED",
    GET_CIRCLE_LINKS_FAILED: "GET_CIRCLE_LINKS_FAILED",

    GET_COMPANY_LIST_REQUESTED: "GET_COMPANY_LIST_REQUESTED",
    GET_COMPANY_LIST_SUCCEEDED: "GET_COMPANY_LIST_SUCCEEDED",
    GET_COMPANY_LIST_FAILED: "GET_COMPANY_LIST_FAILED"


};

// Action Creators
export const actions = {
    get: (token, callback) => {
        return {
            type: types.GET_CIRCLE_LINKS_REQUESTED,
            token,
            callback
        };
    },
    getCompany: (token, callback) => {
        return {
            type: types.GET_COMPANY_LIST_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_CIRCLE_LINKS_REQUESTED:
        case types.GET_COMPANY_LIST_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_CIRCLE_LINKS_SUCCEEDED:
        case types.GET_COMPANY_LIST_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_CIRCLE_LINKS_FAILED:
        case types.GET_COMPANY_LIST_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_CIRCLE_LINKS_REQUESTED, getWorker);
    yield takeLatest(types.GET_COMPANY_LIST_REQUESTED, getCompanyWorker);
}

// Saga callback
function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CIRCLE_LINKS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_CIRCLE_LINKS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ token }) {
    return api.get(`/api/settings/${token}/magic-links`);
}


function* getCompanyWorker({ token, callback }) {
    try {
        const response = yield call(getCompany, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_LIST_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_LIST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getCompany({ token }) {
    return api.get(`/api/settings/${token}/company`);
}



import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_SOFT_SKILLS_REQUESTED: "GET_SOFT_SKILLS_REQUESTED",
    GET_SOFT_SKILLS_SUCCEEDED: "GET_SOFT_SKILLS_SUCCEEDED",
    GET_SOFT_SKILLS_FAILED: "GET_SOFT_SKILLS_FAILED",
    GET_HARD_SKILLS_REQUESTED: "GET_HARD_SKILLS_REQUESTED",
    GET_HARD_SKILLS_SUCCEEDED: "GET_HARD_SKILLS_SUCCEEDED",
    GET_HARD_SKILLS_FAILED: "GET_HARD_SKILLS_FAILED"
};

// Action Creators
export const actions = {
    getSoft: callback => {
        return {
            type: types.GET_SOFT_SKILLS_REQUESTED,
            callback
        };
    },
    getHard: callback => {
        return {
            type: types.GET_HARD_SKILLS_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: { soft: [], hard: [] },
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_SOFT_SKILLS_REQUESTED:
        case types.GET_HARD_SKILLS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_SOFT_SKILLS_SUCCEEDED:
            return {
                data: { ...state.data, soft: action.payload },
                error: "",
                loading: false
            };
        case types.GET_HARD_SKILLS_SUCCEEDED:
            return {
                data: { ...state.data, hard: action.payload },
                error: "",
                loading: false
            };
        case types.GET_SOFT_SKILLS_FAILED:
        case types.GET_HARD_SKILLS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_SOFT_SKILLS_REQUESTED, getSoftWorker);
    yield takeLatest(types.GET_HARD_SKILLS_REQUESTED, getHardWorker);
}

// Saga callback
function* getSoftWorker({ callback }) {
    try {
        const response = yield call(getSoft);

        if (!response.ok) {
            throw { message: response.data };
        }

        yield put({
            type: types.GET_SOFT_SKILLS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_SOFT_SKILLS_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

function* getHardWorker({ callback }) {
    try {
        const response = yield call(getHard);

        if (!response.ok) {
            throw { message: response.data };
        }

        yield put({
            type: types.GET_HARD_SKILLS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_HARD_SKILLS_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}
// API call
function getSoft() {
    return api.get("/api/skills/soft");
}

function getHard() {
    return api.get("/api/skills/hard");
}

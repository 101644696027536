// Actions
const types = {
    SET_LIMITED_MODE: "SET_LIMITED_MODE"
};

// Action Creators
export const actions = {};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_LIMITED_MODE:
            return {
                ...state,
                data: {
                    limitedMode: action.payload.limitedMode
                },
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {}

// Saga callback

// API call

import { takeLatest, call, put, select } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_COMPANY_PROFILE_REQUESTED: "SAVE_COMPANY_PROFILE_REQUESTED",
    SAVE_COMPANY_PROFILE_SUCCEEDED: "SAVE_COMPANY_PROFILE_SUCCEEDED",
    SAVE_COMPANY_PROFILE_FAILED: "SAVE_COMPANY_PROFILE_FAILED",
    GET_COMPANY_PROFILE_REQUESTED: "GET_COMPANY_PROFILE_REQUESTED",
    GET_COMPANY_PROFILE_SUCCEEDED: "GET_COMPANY_PROFILE_SUCCEEDED",
    GET_COMPANY_PROFILE_FAILED: "GET_COMPANY_PROFILE_FAILED",
    SET_TRASPORT_DATA_COMPANY_PROFILE: "SET_TRASPORT_DATA_COMPANY_PROFILE",
    TOGGLE_ACTIVE_COMPANY_PROFILE_REQUESTED:
        "TOGGLE_ACTIVE_COMPANY_PROFILE_REQUESTED",
    TOGGLE_ACTIVE_COMPANY_PROFILE_SUCCEEDED:
        "TOGGLE_ACTIVE_COMPANY_PROFILE_SUCCEEDED",
    TOGGLE_ACTIVE_COMPANY_PROFILE_FAILED:
        "TOGGLE_ACTIVE_COMPANY_PROFILE_FAILED",
    EMPLOYER_PAGE_SAVE_ABOUT_REQUESTED: "EMPLOYER_PAGE_SAVE_ABOUT_REQUESTED",
    EMPLOYER_PAGE_SAVE_ABOUT_SUCCEEDED: "EMPLOYER_PAGE_SAVE_ABOUT_SUCCEEDED",
    EMPLOYER_PAGE_SAVE_ABOUT_FAILED: "EMPLOYER_PAGE_SAVE_ABOUT_FAILED",
    EMPLOYER_PAGE_GET_ABOUT_REQUESTED: "EMPLOYER_PAGE_GET_ABOUT_REQUESTED",
    EMPLOYER_PAGE_GET_ABOUT_SUCCEEDED: "EMPLOYER_PAGE_GET_ABOUT_SUCCEEDED",
    EMPLOYER_PAGE_GET_ABOUT_FAILED: "EMPLOYER_PAGE_GET_ABOUT_FAILED",
    EMPLOYER_PAGE_SAVE_TEAM_REQUESTED: "EMPLOYER_PAGE_SAVE_TEAM_REQUESTED",
    EMPLOYER_PAGE_SAVE_TEAM_SUCCEEDED: "EMPLOYER_PAGE_SAVE_TEAM_SUCCEEDED",
    EMPLOYER_PAGE_SAVE_TEAM_FAILED: "EMPLOYER_PAGE_SAVE_TEAM_FAILED",
    EMPLOYER_PAGE_GET_TEAM_REQUESTED: "EMPLOYER_PAGE_GET_TEAM_REQUESTED",
    EMPLOYER_PAGE_GET_TEAM_SUCCEEDED: "EMPLOYER_PAGE_GET_TEAM_SUCCEEDED",
    EMPLOYER_PAGE_GET_TEAM_FAILED: "EMPLOYER_PAGE_GET_TEAM_FAILED",
    EMPLOYER_PAGE_SAVE_BENEFITS_REQUESTED:
        "EMPLOYER_PAGE_SAVE_BENEFITS_REQUESTED",
    EMPLOYER_PAGE_SAVE_BENEFITS_SUCCEEDED:
        "EMPLOYER_PAGE_SAVE_BENEFITS_SUCCEEDED",
    EMPLOYER_PAGE_SAVE_BENEFITS_FAILED: "EMPLOYER_PAGE_SAVE_BENEFITS_FAILED",
    EMPLOYER_PAGE_GET_BENEFITS_REQUESTED:
        "EMPLOYER_PAGE_GET_BENEFITS_REQUESTED",
    EMPLOYER_PAGE_GET_BENEFITS_SUCCEEDED:
        "EMPLOYER_PAGE_GET_BENEFITS_SUCCEEDED",
    EMPLOYER_PAGE_GET_BENEFITS_FAILED: "EMPLOYER_PAGE_GET_BENEFITS_FAILED",
    EMPLOYER_PAGE_SAVE_EVP_REQUESTED: "EMPLOYER_PAGE_SAVE_EVP_REQUESTED",
    EMPLOYER_PAGE_SAVE_EVP_SUCCEEDED: "EMPLOYER_PAGE_SAVE_EVP_SUCCEEDED",
    EMPLOYER_PAGE_SAVE_EVP_FAILED: "EMPLOYER_PAGE_SAVE_EVP_FAILED",
    EMPLOYER_PAGE_GET_EVP_REQUESTED: "EMPLOYER_PAGE_GET_EVP_REQUESTED",
    EMPLOYER_PAGE_GET_EVP_SUCCEEDED: "EMPLOYER_PAGE_GET_EVP_SUCCEEDED",
    EMPLOYER_PAGE_GET_EVP_FAILED: "EMPLOYER_PAGE_GET_EVP_FAILED",
    EMPLOYER_PAGE_SAVE_CONTACT_REQUESTED:
        "EMPLOYER_PAGE_SAVE_CONTACT_REQUESTED",
    EMPLOYER_PAGE_SAVE_CONTACT_SUCCEEDED:
        "EMPLOYER_PAGE_SAVE_CONTACT_SUCCEEDED",
    EMPLOYER_PAGE_SAVE_CONTACT_FAILED: "EMPLOYER_PAGE_SAVE_CONTACT_FAILED",
    EMPLOYER_PAGE_GET_CONTACT_REQUESTED: "EMPLOYER_PAGE_GET_CONTACT_REQUESTED",
    EMPLOYER_PAGE_GET_CONTACT_SUCCEEDED: "EMPLOYER_PAGE_GET_CONTACT_SUCCEEDED",
    EMPLOYER_PAGE_GET_CONTACT_FAILED: "EMPLOYER_PAGE_GET_CONTACT_FAILED",
    EMPLOYER_PAGE_PUBLISH_COMPANY_REQUESTED:
        "EMPLOYER_PAGE_PUBLISH_COMPANY_REQUESTED",
    EMPLOYER_PAGE_PUBLISH_COMPANY_SUCCEEDED:
        "EMPLOYER_PAGE_PUBLISH_COMPANY_SUCCEEDED",
    EMPLOYER_PAGE_PUBLISH_COMPANY_FAILED: "EMPLOYER_PAGE_PUBLISH_COMPANY_FAILED"
};

export const getTransportData = (state) => state.company.profile.transportData;

// Action Creators
export const actions = {
    save: (token, callback) => {
        return {
            type: types.SAVE_COMPANY_PROFILE_REQUESTED,
            token,
            callback
        };
    },
    get: (token, callback) => {
        return {
            type: types.GET_COMPANY_PROFILE_REQUESTED,
            token,
            callback
        };
    },
    setTransportData: (data, callback) => {
        return {
            type: types.SET_TRASPORT_DATA_COMPANY_PROFILE,
            data,
            callback
        };
    },
    toggleActive: (token, isActive, callback) => {
        return {
            type: types.TOGGLE_ACTIVE_COMPANY_PROFILE_REQUESTED,
            token,
            isActive,
            callback
        };
    },
    saveAbout: (data, token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_SAVE_ABOUT_REQUESTED,
            data,
            token,
            callback
        };
    },
    getAbout: (token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_GET_ABOUT_REQUESTED,
            token,
            callback
        };
    },
    saveTeam: (data, token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_SAVE_TEAM_REQUESTED,
            data,
            token,
            callback
        };
    },
    getTeam: (token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_GET_TEAM_REQUESTED,
            token,
            callback
        };
    },
    saveBenefits: (data, token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_SAVE_BENEFITS_REQUESTED,
            data,
            token,
            callback
        };
    },
    getBenefits: (token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_GET_BENEFITS_REQUESTED,
            token,
            callback
        };
    },
    saveEvp: (data, token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_SAVE_EVP_REQUESTED,
            data,
            token,
            callback
        };
    },
    getEvp: (token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_GET_EVP_REQUESTED,
            token,
            callback
        };
    },
    saveContact: (data, token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_SAVE_CONTACT_REQUESTED,
            data,
            token,
            callback
        };
    },
    getContact: (token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_GET_CONTACT_REQUESTED,
            token,
            callback
        };
    },
    publish: (published, token, callback) => {
        return {
            type: types.EMPLOYER_PAGE_PUBLISH_COMPANY_REQUESTED,
            published,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    transportData: {},
    error: "",
    loading: false,
    submitting: false,
    about: null,
    aboutLoading: false,
    team: null,
    teamLoading: false,
    benefits: null,
    benefitsLoading: false,
    evp: null,
    evpLoading: false,
    contact: null,
    contactLoading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_TRASPORT_DATA_COMPANY_PROFILE:
            return {
                ...state,
                transportData: action.data
            };
        case types.SAVE_COMPANY_PROFILE_REQUESTED:
            return {
                ...state,
                submitting: true
            };
        case types.GET_COMPANY_PROFILE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SAVE_COMPANY_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                submitting: false
            };
        case types.GET_COMPANY_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.TOGGLE_ACTIVE_COMPANY_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: {
                    ...state.data,
                    is_active: action.payload
                },
                error: "",
                loading: false
            };
        case types.SAVE_COMPANY_PROFILE_FAILED:
        case types.GET_COMPANY_PROFILE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.EMPLOYER_PAGE_SAVE_ABOUT_REQUESTED:
            return {
                ...state,
                aboutLoading: true
            };
        case types.EMPLOYER_PAGE_SAVE_ABOUT_SUCCEEDED:
            return {
                ...state,
                about: action.payload.data,
                error: "",
                aboutLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_ABOUT_FAILED:
            return {
                ...state,
                error: action.payload,
                aboutLoading: false
            };
        case types.EMPLOYER_PAGE_GET_ABOUT_REQUESTED:
            return {
                ...state,
                aboutLoading: true
            };
        case types.EMPLOYER_PAGE_GET_ABOUT_SUCCEEDED:
            return {
                ...state,
                about: action.payload.data,
                error: "",
                aboutLoading: false
            };
        case types.EMPLOYER_PAGE_GET_ABOUT_FAILED:
            return {
                ...state,
                error: action.payload,
                aboutLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_TEAM_REQUESTED:
            return {
                ...state,
                teamLoading: true
            };
        case types.EMPLOYER_PAGE_SAVE_TEAM_SUCCEEDED:
            return {
                ...state,
                team: action.payload.data,
                error: "",
                teamLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_TEAM_FAILED:
            return {
                ...state,
                error: action.payload,
                teamLoading: false
            };
        case types.EMPLOYER_PAGE_GET_TEAM_REQUESTED:
            return {
                ...state,
                teamLoading: true
            };
        case types.EMPLOYER_PAGE_GET_TEAM_SUCCEEDED:
            return {
                ...state,
                team: action.payload.data,
                error: "",
                teamLoading: false
            };
        case types.EMPLOYER_PAGE_GET_TEAM_FAILED:
            return {
                ...state,
                error: action.payload,
                teamLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_BENEFITS_REQUESTED:
            return {
                ...state,
                benefitsLoading: true
            };
        case types.EMPLOYER_PAGE_SAVE_BENEFITS_SUCCEEDED:
            return {
                ...state,
                benefits: action.payload.data,
                error: "",
                benefitsLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_BENEFITS_FAILED:
            return {
                ...state,
                error: action.payload,
                benefitsLoading: false
            };
        case types.EMPLOYER_PAGE_GET_BENEFITS_REQUESTED:
            return {
                ...state,
                benefitsLoading: true
            };
        case types.EMPLOYER_PAGE_GET_BENEFITS_SUCCEEDED:
            return {
                ...state,
                benefits: action.payload.data,
                error: "",
                benefitsLoading: false
            };
        case types.EMPLOYER_PAGE_GET_BENEFITS_FAILED:
            return {
                ...state,
                error: action.payload,
                benefitsLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_EVP_REQUESTED:
            return {
                ...state,
                evpLoading: true
            };
        case types.EMPLOYER_PAGE_SAVE_EVP_SUCCEEDED:
            return {
                ...state,
                evp: action.payload.data,
                error: "",
                evpLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_EVP_FAILED:
            return {
                ...state,
                error: action.payload,
                evpLoading: false
            };
        case types.EMPLOYER_PAGE_GET_EVP_REQUESTED:
            return {
                ...state,
                evpLoading: true
            };
        case types.EMPLOYER_PAGE_GET_EVP_SUCCEEDED:
            return {
                ...state,
                evp: action.payload.data,
                error: "",
                evpLoading: false
            };
        case types.EMPLOYER_PAGE_GET_EVP_FAILED:
            return {
                ...state,
                error: action.payload,
                evpLoading: false
            };
        case types.EMPLOYER_PAGE_SAVE_CONTACT_REQUESTED:
            return {
                ...state
            };
        case types.EMPLOYER_PAGE_SAVE_CONTACT_SUCCEEDED:
            return {
                ...state,
                contact: action.payload.data,
                error: ""
            };
        case types.EMPLOYER_PAGE_SAVE_CONTACT_FAILED:
            return {
                ...state,
                error: action.payload
            };
        case types.EMPLOYER_PAGE_GET_CONTACT_REQUESTED:
            return {
                ...state,
                contactLoading: true
            };
        case types.EMPLOYER_PAGE_GET_CONTACT_SUCCEEDED:
            return {
                ...state,
                contact: action.payload.data,
                error: "",
                contactLoading: false
            };
        case types.EMPLOYER_PAGE_GET_CONTACT_FAILED:
            return {
                ...state,
                error: action.payload,
                contactLoading: false
            };
        case types.EMPLOYER_PAGE_PUBLISH_COMPANY_REQUESTED:
            return {
                ...state
            };
        case types.EMPLOYER_PAGE_PUBLISH_COMPANY_SUCCEEDED:
            return {
                ...state,
                data: {
                    ...state.data,
                    is_published: action.payload.data.is_published
                }
            };
        case types.EMPLOYER_PAGE_PUBLISH_COMPANY_FAILED:
            return {
                ...state
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_COMPANY_PROFILE_REQUESTED, saveWorker);
    yield takeLatest(types.GET_COMPANY_PROFILE_REQUESTED, getWorker);
    yield takeLatest(
        types.TOGGLE_ACTIVE_COMPANY_PROFILE_REQUESTED,
        toggleActiveWorker
    );
    yield takeLatest(types.EMPLOYER_PAGE_SAVE_ABOUT_REQUESTED, saveAboutWorker);
    yield takeLatest(types.EMPLOYER_PAGE_GET_ABOUT_REQUESTED, getAboutWorker);
    yield takeLatest(types.EMPLOYER_PAGE_SAVE_TEAM_REQUESTED, saveTeamWorker);
    yield takeLatest(types.EMPLOYER_PAGE_GET_TEAM_REQUESTED, getTeamWorker);
    yield takeLatest(
        types.EMPLOYER_PAGE_SAVE_BENEFITS_REQUESTED,
        saveBenefitsWorker
    );
    yield takeLatest(
        types.EMPLOYER_PAGE_GET_BENEFITS_REQUESTED,
        getBenefitsWorker
    );
    yield takeLatest(types.EMPLOYER_PAGE_SAVE_EVP_REQUESTED, saveEvpWorker);
    yield takeLatest(types.EMPLOYER_PAGE_GET_EVP_REQUESTED, getEvpWorker);
    yield takeLatest(
        types.EMPLOYER_PAGE_SAVE_CONTACT_REQUESTED,
        saveContactWorker
    );
    yield takeLatest(
        types.EMPLOYER_PAGE_GET_CONTACT_REQUESTED,
        getContactWorker
    );
    yield takeLatest(
        types.EMPLOYER_PAGE_PUBLISH_COMPANY_REQUESTED,
        putPublishWorker
    );
}

// Saga callback
function* saveWorker({ token, callback }) {
    try {
        const data = yield select(getTransportData);
        const response = yield call(save, { data: data.data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_PROFILE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_PROFILE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* toggleActiveWorker({ token, isActive, callback }) {
    try {
        const response = yield call(toggleActive, { isActive, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.TOGGLE_ACTIVE_COMPANY_PROFILE_SUCCEEDED,
            payload: isActive
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.TOGGLE_ACTIVE_COMPANY_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveAboutWorker({ data, token, callback }) {
    try {
        const response = yield call(saveAbout, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_SAVE_ABOUT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_SAVE_ABOUT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getAboutWorker({ token, callback }) {
    try {
        const response = yield call(getAbout, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_GET_ABOUT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_GET_ABOUT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveTeamWorker({ data, token, callback }) {
    try {
        const response = yield call(saveTeam, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_SAVE_TEAM_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_SAVE_TEAM_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getTeamWorker({ token, callback }) {
    try {
        const response = yield call(getTeam, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_GET_TEAM_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_GET_TEAM_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveBenefitsWorker({ data, token, callback }) {
    try {
        const response = yield call(saveBenefits, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_SAVE_BENEFITS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_SAVE_BENEFITS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getBenefitsWorker({ token, callback }) {
    try {
        const response = yield call(getBenefits, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_GET_BENEFITS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_GET_BENEFITS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveEvpWorker({ data, token, callback }) {
    try {
        const response = yield call(saveEvp, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_SAVE_EVP_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_SAVE_EVP_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getEvpWorker({ token, callback }) {
    try {
        const response = yield call(getEvp, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_GET_EVP_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_GET_EVP_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveContactWorker({ data, token, callback }) {
    try {
        const response = yield call(saveContact, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_SAVE_CONTACT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_SAVE_CONTACT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getContactWorker({ token, callback }) {
    try {
        const response = yield call(getContact, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_GET_CONTACT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_GET_CONTACT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* putPublishWorker({ published, token, callback }) {
    try {
        const response = yield call(putPublished, { published, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EMPLOYER_PAGE_PUBLISH_COMPANY_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EMPLOYER_PAGE_PUBLISH_COMPANY_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}
// API call
function save({ data, token }) {
    const formData = new FormData();

    for (let fieldName of Object.keys(data)) {
        if (data[fieldName] && Array.isArray(data[fieldName])) {
            const arrayValues = data[fieldName];
            for (let i = 0; i < arrayValues.length; i++) {
                formData.append(`${fieldName}[]`, arrayValues[i]);
            }
        } else {
            formData.append(fieldName, data[fieldName]);
        }
    }

    formData.append("_method", "PUT");

    return api.post(`/api/company/${token}/profile`, formData);
}

function get({ token }) {
    return api.get(`/api/company/${token}`);
}

function toggleActive({ token, isActive }) {
    // -1 disable, 1 (true) actived, 0 (false) inactive
    if( typeof isActive == 'boolean') {
        isActive = isActive ? 1 : 0;
    }
    return api.put(`/api/company/${token}/is-active/${isActive}`);
}

function saveAbout({ data, token }) {
    return api.put(`/api/company/${token}/about`, data);
}

function getAbout({ token }) {
    return api.get(`/api/company/${token}/about`);
}

function saveTeam({ data, token }) {
    return api.put(`/api/company/${token}/team`, data);
}

function getTeam({ token }) {
    return api.get(`/api/company/${token}/team`);
}

function saveBenefits({ data, token }) {
    return api.put(`/api/company/${token}/benefits`, data);
}

function getBenefits({ token }) {
    return api.get(`/api/company/${token}/benefits`);
}

function saveEvp({ data, token }) {
    return api.put(`/api/company/${token}/evp`, data);
}

function getEvp({ token }) {
    return api.get(`/api/company/${token}/evp`);
}

function saveContact({ data, token }) {
    return api.put(`/api/company/${token}/social`, data);
}

function getContact({ token }) {
    return api.get(`/api/company/${token}/social`);
}

function putPublished({ published, token }) {
    return api.put(`/api/company/${token}/profile/toggle-publish`, {
        is_published: published
    });
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Default state
const defaultState = {
    data: [],
    single: [],
    error: "",
    loading: true
};

// Actions
const types = {
    LIST_STUDENT_TALENTS_REQUESTED: "LIST_STUDENT_TALENTS_REQUESTED",
    LIST_STUDENT_TALENTS_SUCCEEDED: "LIST_STUDENT_TALENTS_SUCCEEDED",
    LIST_STUDENT_TALENTS_FAILED: "LIST_STUDENT_TALENTS_FAILED",

    SENDJOB_STUDENT_TALENTS_REQUESTED: "SENDJOB_STUDENT_TALENTS_REQUESTED",
    SENDJOB_STUDENT_TALENTS_SUCCEEDED: "SENDJOB_STUDENT_TALENTS_SUCCEEDED",
    SENDJOB_STUDENT_TALENTS_FAILED: "SENDJOB_STUDENT_TALENTS_FAILED",

    VIEW_STUDENT_TALENTS_REQUESTED: "VIEW_STUDENT_TALENTS_REQUESTED",
    VIEW_STUDENT_TALENTS_SUCCEEDED: "VIEW_STUDENT_TALENTS_SUCCEEDED",
    VIEW_STUDENT_TALENTS_FAILED: "VIEW_STUDENT_TALENTS_FAILED",

    PROFILE_VIEW_STUDENT_TALENTS_REQUESTED: "PROFILE_VIEW_STUDENT_TALENTS_REQUESTED",
    PROFILE_VIEW_STUDENT_TALENTS_SUCCEEDED: "PROFILE_VIEW_STUDENT_TALENTS_SUCCEEDED",
    PROFILE_VIEW_STUDENT_TALENTS_FAILED: "PROFILE_VIEW_STUDENT_TALENTS_FAILED"

};

// Action Creators
const actions = {

    list: (token, circleId, page, pageSize, searches, callback) => {
        return {
            type: types.LIST_STUDENT_TALENTS_REQUESTED,
            token,
            circleId,
            page,
            pageSize,
            searches,
            callback
        };
    },

    sendJob: (token, formdata, callback) => {
        return {
            type: types.SENDJOB_STUDENT_TALENTS_REQUESTED,
            token,
            formdata,
            callback
        };
    },
    profileView: (token, talent_id, circle_id, callback) => {
        return {
            type: types.PROFILE_VIEW_STUDENT_TALENTS_REQUESTED,
            token,
            talent_id,
            circle_id,
            callback
        };
    }


};


// Reducers
function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.LIST_STUDENT_TALENTS_REQUESTED:
        case types.VIEW_STUDENT_TALENTS_REQUESTED:
        case types.SENDJOB_STUDENT_TALENTS_REQUESTED:
        case types.PROFILE_VIEW_STUDENT_TALENTS_REQUESTED:
            return {
                ...state,
                loading: true
            };

        case types.LIST_STUDENT_TALENTS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };

        case types.SENDJOB_STUDENT_TALENTS_SUCCEEDED:
        case types.PROFILE_VIEW_TALENTS_SUCCEEDED:
            return {
                ...state,
                error: "",
                loading: false
            };

        case types.VIEW_STUDENT_TALENTS_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                loading: false
            };

        case types.LIST_STUDENT_TALENTS_FAILED:
        case types.VIEW_STUDENT_TALENTS_FAILED:
        case types.SENDJOB_STUDENT_TALENTS_FAILED:
        case types.PROFILE_VIEW_TALENTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        default:
            return state;
    }
}

// Sagas
function* saga() {

    yield takeLatest(types.LIST_STUDENT_TALENTS_REQUESTED, listWorker);
    yield takeLatest(types.SENDJOB_STUDENT_TALENTS_REQUESTED, sendJobWorker);
    yield takeLatest(types.PROFILE_VIEW_STUDENT_TALENTS_REQUESTED, profileViewWorker);

}

// Saga callback
function* listWorker({ token, circleId, page, pageSize, searches, callback }) {
    //console.log('====searches===', searches);
    try {
        const response = yield call(list, { token, circleId, page, pageSize, searches });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LIST_STUDENT_TALENTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.LIST_STUDENT_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function list({ token, circleId, page, pageSize, searches }) {
    // console.log('====params===', searches);
    let query = '?';
    for (let p in searches) {
        if (searches[p]) {
            query += (query == '?' ? p : '&' + p) + "=" + searches[p];
        }
    }
    query = query.trim('&');
    // console.log(query);
    return api.get(`/api/student/company/${token}/matches/by-target-profile/${circleId}/paginated/${page}/${pageSize}/1${query}`);
}


function* sendJobWorker({ token, formdata, callback }) {
    try {
        const response = yield call(sendJob, { token, formdata });
        // console.log("ok => ", response);
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.SENDJOB_STUDENT_TALENTS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SENDJOB_STUDENT_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function sendJob({ token, formdata }) {
    // console.log('>>>>sendJob>>>>', formdata);
    return api.post(`/api/student/company/job-list/${token}/send-job`, formdata);
}


function* profileViewWorker({ token, talent_id, circle_id, callback }) {
    try {
        const response = yield call(profileView, { token, talent_id, circle_id });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.PROFILE_VIEW_TALENTS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.PROFILE_VIEW_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function profileView({ token, talent_id, circle_id }) {
    return api.get(`/api/student/company/profile-view/${token}/${talent_id}/${circle_id}`);
}




export default { reducer, actions, saga };

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";
import without from "lodash/without";

// Actions
const types = {
    GET_MATCHES_FOLLOW_REQUESTED: "GET_MATCHES_FOLLOW_REQUESTED",
    GET_MATCHES_FOLLOW_SUCCEEDED: "GET_MATCHES_FOLLOW_SUCCEEDED",
    GET_MATCHES_FOLLOW_FAILED: "GET_MATCHES_FOLLOW_FAILED",
    SET_MATCHES_FOLLOW_REQUESTED: "SET_MATCHES_FOLLOW_REQUESTED",
    SET_MATCHES_FOLLOW_SUCCEEDED: "SET_MATCHES_FOLLOW_SUCCEEDED",
    SET_MATCHES_FOLLOW_FAILED: "SET_MATCHES_FOLLOW_FAILED",
    UNSET_MATCHES_FOLLOW_REQUESTED: "UNSET_MATCHES_FOLLOW_REQUESTED",
    UNSET_MATCHES_FOLLOW_SUCCEEDED: "UNSET_MATCHES_FOLLOW_SUCCEEDED",
    UNSET_MATCHES_FOLLOW_FAILED: "UNSET_MATCHES_FOLLOW_FAILED"
};

// Action Creators
export const actions = {
    get: callback => {
        return {
            type: types.GET_MATCHES_FOLLOW_REQUESTED,
            callback
        };
    },
    follow: (companyId, callback) => {
        return {
            type: types.SET_MATCHES_FOLLOW_REQUESTED,
            companyId,
            callback
        };
    },
    unfollow: (companyId, callback) => {
        return {
            type: types.UNSET_MATCHES_FOLLOW_REQUESTED,
            companyId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_MATCHES_FOLLOW_REQUESTED:
        case types.UNSET_MATCHES_FOLLOW_REQUESTED:
        case types.SET_MATCHES_FOLLOW_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_MATCHES_FOLLOW_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.SET_MATCHES_FOLLOW_SUCCEEDED:
            return {
                data: [...state.data, action.payload],
                error: "",
                loading: false
            };
        case types.UNSET_MATCHES_FOLLOW_SUCCEEDED:
            return {
                data: without(state.data, action.payload),
                error: "",
                loading: false
            };
        case types.GET_MATCHES_FOLLOW_FAILED:
        case types.UNSET_MATCHES_FOLLOW_FAILED:
        case types.SET_MATCHES_FOLLOW_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_MATCHES_FOLLOW_REQUESTED, getWorker);
    yield takeLatest(types.SET_MATCHES_FOLLOW_REQUESTED, setWorker);
    yield takeLatest(types.UNSET_MATCHES_FOLLOW_REQUESTED, unsetWorker);
}

// Saga callback
function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_MATCHES_FOLLOW_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_MATCHES_FOLLOW_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* setWorker({ companyId, callback }) {
    try {
        const response = yield call(set, { companyId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SET_MATCHES_FOLLOW_SUCCEEDED,
            payload: companyId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SET_MATCHES_FOLLOW_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* unsetWorker({ companyId, callback }) {
    try {
        const response = yield call(unset, { companyId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UNSET_MATCHES_FOLLOW_SUCCEEDED,
            payload: companyId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UNSET_MATCHES_FOLLOW_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get() {
    return api.get(`/api/follow/company`);
}

function set({ companyId }) {
    return api.post(`/api/follow/company`, {
        company_id: companyId
    });
}

function unset({ companyId }) {
    return api.post(`/api/unfollow/company`, {
        company_id: companyId
    });
}

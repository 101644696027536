import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_PROFILE_GENDER_REQUESTED: "SAVE_PROFILE_GENDER_REQUESTED",
    SAVE_PROFILE_GENDER_SUCCEEDED: "SAVE_PROFILE_GENDER_SUCCEEDED",
    SAVE_PROFILE_GENDER_FAILED: "SAVE_PROFILE_GENDER_FAILED"
};

// Action Creators
export const actions = {
    save: (data, callback) => {
        return {
            type: types.SAVE_PROFILE_GENDER_REQUESTED,
            data,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SAVE_PROFILE_GENDER_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_PROFILE_GENDER_REQUESTED, saveWorker);
}

// Saga callback
function* saveWorker({ data, callback }) {
    try {
        const response = yield call(save, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PROFILE_GENDER_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_PROFILE_GENDER_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data }) {
    return api.put("/api/demographics/gender/talent", { gender: data });
}

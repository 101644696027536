import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import DateMomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Pages from "Pages/Pages";

const theme = createMuiTheme({
    shape: {
        borderRadius: 0
    },
    palette: {
        background: {
            default: "#FFFFFF"
        },
        text: {
            primary: "#666666"
        },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#524E49"
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // main: "#CCE3E3",
            main: "#F00",
            contrastText: "#FFFFFF"
        }
    },
    typography: {
        fontWeightRegular: 300,
        "@media(min-width:600px)": {
            fontFamily: `'PT Sans Caption', sans-serif`
        },
        fontFamily: `'PT Sans Caption', sans-serif`,
        serif: {
            fontFamily: `'Roboto Slab', serif`
        }
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                b: {
                    fontWeight: "bold"
                },
                strong: {
                    fontWeight: "bold"
                },

                ".ant-modal-mask": {
                    zIndex: "1200 !important;"
                },
                ".ant-modal-wrap": {
                    zIndex: "1201 !important;"
                }
            }
        }
    }
});

function App() {
    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pages />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </Fragment>
    );
}

export default App;

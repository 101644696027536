export const callFail = (callback, data) => {
    if (typeof callback == "function") {
        if (
            data &&
            typeof data == "string" &&
            data.indexOf("Unable to decode") != -1
        ) {
            callback(
                "You are trying to upload a corrupted image file, please try with different image file.",
                null
            );
        } else {
            callback(data || "Internal Server Error", null);
        }
    }
};

export const callSuccess = (callback, data) => {
    typeof callback == "function" && callback(false, data);
};

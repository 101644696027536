import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_MATCH_CULTURE_REQUESTED: "GET_COMPANY_MATCH_CULTURE_REQUESTED",
    GET_COMPANY_MATCH_CULTURE_SUCCEEDED: "GET_COMPANY_MATCH_CULTURE_SUCCEEDED",
    GET_COMPANY_MATCH_CULTURE_FAILED: "GET_COMPANY_MATCH_CULTURE_FAILED"
};

// Action Creators
export const actions = {
    get: (userId, token, callback) => {
        return {
            type: types.GET_COMPANY_MATCH_CULTURE_REQUESTED,
            token,
            userId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_MATCH_CULTURE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_MATCH_CULTURE_SUCCEEDED:
            return {
                ...state,
                data: { ...state.data, [action.userId]: action.payload.data },
                error: "",
                loading: false
            };
        case types.GET_COMPANY_MATCH_CULTURE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_MATCH_CULTURE_REQUESTED, getWorker);
}

// Saga callback
function* getWorker({ token, userId, callback }) {
    try {
        const response = yield call(get, {
            token,
            userId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_MATCH_CULTURE_SUCCEEDED,
            payload: response.data,
            userId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_MATCH_CULTURE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function get({ token, userId }) {
    return api.get(
        `/api/company/${token}/culture-test/results/talent/${userId}`
    );
}

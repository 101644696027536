import { fork, all } from "redux-saga/effects";

import user from "./User";

import cultureCategory from "./culture/Category";
import cultureQuestion from "./culture/Question";
import cultureAnswer from "./culture/Answer";
import cultureResult from "./culture/Result";

import companyCultureCategory from "./company/culture/Category";
import companyCultureQuestion from "./company/culture/Question";
import companyCultureAnswer from "./company/culture/Answer";
import companyCultureResult from "./company/culture/Result";
import companyCultureImportance from "./company/culture/Importance";
import companyCultureTribe from "./company/culture/Tribe";

import matchesMatch from "./matches/Match";
import matchesFollow from "./matches/Follow";

import profileExperience from "./profile/Experience";
import profileProgress from "./profile/Progress";
import profileEducation from "./profile/Education";
import profileJobStatus from "./profile/JobStatus";
import profileSalary from "./profile/Salary";
import profileCityOfResidence from "./profile/CityOfResidence";
import profileGender from "./profile/Gender";
import profileDemographics from "./profile/Demographics";
import profilePreferenceEmployerTypeSize from "./profile/preference/EmployerTypeSize";
import profilePreferenceIndustry from "./profile/preference/Industry";
import profilePreferenceSector from "./profile/preference/Sector";
import profilePreferenceMobilty from "./profile/preference/Mobility";
import profilePreferenceSalary from "./profile/preference/Salary";
import profilePreferenceCompanies from "./profile/preference/Companies";
import profileLanguage from "./profile/Language";
import profileSkill from "./profile/Skill";

import resourceUniversity from "./resource/University";
import resourceCompany from "./resource/Company";
import resourceJobStatus from "./resource/JobStatus";
import resourceCurrency from "./resource/Currency";
import resourceEmployerTypeSize from "./resource/EmployerTypeSize";
import resourceSector from "./resource/Sector";
import resourceMobility from "./resource/Mobility";
import resourceDegree from "./resource/Degree";
import resourceGrade from "./resource/Grade";
import resourceJobTitle from "./resource/JobTitle";
import resourceMajor from "./resource/Major";
import resourceRole from "./resource/Role";
import resourceLanguage from "./resource/Language";
import resourceSkill from "./resource/Skill";
import resourceAreasOfExpertise from "./resource/AreasOfExpertise";
import resourceTechSkills from "./resource/TechSkills";
import resourceTechFields from "./resource/TechFields";

import benchmarksPreferenceEmployerTypeSize from "./benchmarks/preference/EmployerTypeSize";
import benchmarksPreferenceIndustry from "./benchmarks/preference/Industry";
import benchmarksPreferenceSector from "./benchmarks/preference/Sector";
import benchmarksPreferenceMobility from "./benchmarks/preference/Mobility";
import benchmarksSalaryCurrent from "./benchmarks/salary/Current";
import benchmarksSalaryPreffered from "./benchmarks/salary/Preffered";
import benchmarksJobStatus from "./benchmarks/JobStatus";
import benchmarksCulture from "./benchmarks/Culture";
import benchmarksSkill from "./benchmarks/Skill";
import benchmarksLanguage from "./benchmarks/Language";

import companyProfile from "./company/Profile";
import companyProfileLogo from "./company/Profile/Logo";
import companyMatches from "./company/Matches";
import companyMatchesFiles from "./company/Matches/Files";
import companyMatchesFreeText from "./company/Matches/FreeText";
import companyMatchesFeedback from "./company/Matches/Feedback";
import companyMatchesCulture from "./company/Matches/Culture";
import companyFollows from "./company/Follows";
import companyLabel from "./company/Label";
import companyLogo from "./company/Logo";
import companyFilter from "./company/Filter";
import companyTribe from "./company/Tribe";
import companyTribeJob from "./company/Tribe/Job";
import companyTribeGig from "./company/Tribe/Gig";
import companyTribeContent from "./company/Tribe/Content";
import companyGeneralBlog from "./company/GeneralBlog";
import companyCategoryBlog from "./company/CategoryBlog";
import companyTribeEvent from "./company/Tribe/Event";
import companyTribeCover from "./company/Tribe/Cover";
import companyDraftTribeJob from "./company/DraftTribeJob";
import companyDraftTribeGig from "./company/DraftTribeGig";
import companyDraftTribeContent from "./company/DraftTribeContent";
import companyDraftGeneralBlog from "./company/DraftGeneralBlog";
import companyDraftCategoryBlog from "./company/DraftCategoryBlog";
import companyDraftTribeEvent from "./company/DraftTribeEvent";
import companyDraftTribe from "./company/DraftTribe";
import companyDraftProfile from "./company/DraftProfile";
import companyDraftCulture from "./company/DraftCulture";
import companyComparisonIndustry from "./company/comparison/Industry";
import companyComparisonSizeTypeEmployer from "./company/comparison/SizeTypeEmployer";
import companyComparisonCulture from "./company/comparison/Culture";
import companyComparisonOfficeLocation from "./company/comparison/OfficeLocation";
import companyComparisonRecruitmentCity from "./company/comparison/RecruitmentCity";
import companyComparisonTargetProfile from "./company/comparison/TargetProfile";
import companyComparisonExperienceImportance from "./company/comparison/ExperienceImportance";
import companyComparisonSekCompensation from "./company/comparison/SekCompensation";
import companyComparisonMajor from "./company/comparison/Major";
import companyComparisonSector from "./company/comparison/Sector";
import companyComparisonLanguage from "./company/comparison/Language";
import companyComparisonSoftSkill from "./company/comparison/SoftSkill";
import companyComparisonHardSkill from "./company/comparison/HardSkill";
import companyAllCompanies from "./company/AllCompanies";
import companyPDF from "./company/PDF";
import companySettings from "./company/Settings";
import companySettingsLinks from "./company/Settings/CompanyLinks";
import companyAuthors from "./company/Authors";
import companyBlogAuthors from "./company/BlogAuthors";
import companyBlogInterestTags from "./company/BlogInterestTags";
import companySignup from "./company/Signup";
import companyLogin from "./company/Login";
import companySteps from "./company/Steps";
import companyPreviewLink from "./company/PreviewLink";

import companyNotification from "./company/Notification";
import companyCommunication from "./company/Communication";
import companyJobs from "./company/Jobs";
import companyTalents from "./company/Talents";

import companyStudentCircle from "./company/Student/Circle";
import companyStudentOpportunity from "./company/Student/Opportunity";
import companyStudentTalent from "./company/Student/Talent";

import base from "./base";

export default function* root() {
    yield all([
        fork(base.saga),
        fork(user.saga),
        fork(matchesMatch.saga),
        fork(matchesFollow.saga),
        // Culture
        fork(cultureCategory.saga),
        fork(cultureQuestion.saga),
        fork(cultureAnswer.saga),
        fork(cultureResult.saga),
        // Profile
        fork(profileProgress.saga),
        fork(profileExperience.saga),
        fork(profileEducation.saga),
        fork(profileJobStatus.saga),
        fork(profileSalary.saga),
        fork(profileCityOfResidence.saga),
        fork(profileGender.saga),
        fork(profileDemographics.saga),
        fork(profileLanguage.saga),
        fork(profileSkill.saga),
        // Profile Preferences
        fork(profilePreferenceEmployerTypeSize.saga),
        fork(profilePreferenceIndustry.saga),
        fork(profilePreferenceSector.saga),
        fork(profilePreferenceMobilty.saga),
        fork(profilePreferenceCompanies.saga),
        fork(profilePreferenceSalary.saga),
        // Resources
        fork(resourceUniversity.saga),
        fork(resourceCompany.saga),
        fork(resourceJobStatus.saga),
        fork(resourceCurrency.saga),
        fork(resourceEmployerTypeSize.saga),
        fork(resourceSector.saga),
        fork(resourceMobility.saga),
        fork(resourceDegree.saga),
        fork(resourceGrade.saga),
        fork(resourceJobTitle.saga),
        fork(resourceMajor.saga),
        fork(resourceRole.saga),
        fork(resourceLanguage.saga),
        fork(resourceSkill.saga),
        fork(resourceAreasOfExpertise.saga),
        fork(resourceTechFields.saga),
        fork(resourceTechSkills.saga),
        // Benchmarks
        fork(benchmarksPreferenceEmployerTypeSize.saga),
        fork(benchmarksPreferenceIndustry.saga),
        fork(benchmarksPreferenceSector.saga),
        fork(benchmarksPreferenceMobility.saga),
        fork(benchmarksSalaryCurrent.saga),
        fork(benchmarksSalaryPreffered.saga),
        fork(benchmarksJobStatus.saga),
        fork(benchmarksCulture.saga),
        fork(benchmarksSkill.saga),
        fork(benchmarksLanguage.saga),
        // Company
        fork(companyProfile.saga),
        fork(companyProfileLogo.saga),
        fork(companyMatches.saga),
        fork(companyMatchesFiles.saga),
        fork(companyMatchesFreeText.saga),
        fork(companyMatchesFeedback.saga),
        fork(companyMatchesCulture.saga),
        fork(companyFollows.saga),
        fork(companyLabel.saga),
        fork(companyLogo.saga),
        fork(companyFilter.saga),
        fork(companyTribe.saga),
        fork(companyTribeJob.saga),
        fork(companyTribeGig.saga),
        fork(companyTribeContent.saga),
        fork(companyGeneralBlog.saga),
        fork(companyCategoryBlog.saga),
        fork(companyTribeEvent.saga),
        fork(companyTribeCover.saga),
        fork(companyDraftTribe.saga),
        fork(companyDraftTribeJob.saga),
        fork(companyDraftTribeGig.saga),
        fork(companyDraftTribeContent.saga),
        fork(companyDraftGeneralBlog.saga),
        fork(companyDraftCategoryBlog.saga),
        fork(companyDraftTribeEvent.saga),
        fork(companyDraftProfile.saga),
        fork(companyDraftCulture.saga),
        fork(companyComparisonIndustry.saga),
        fork(companyComparisonSizeTypeEmployer.saga),
        fork(companyComparisonCulture.saga),
        fork(companyComparisonOfficeLocation.saga),
        fork(companyComparisonRecruitmentCity.saga),
        fork(companyComparisonTargetProfile.saga),
        fork(companyComparisonExperienceImportance.saga),
        fork(companyComparisonSekCompensation.saga),
        fork(companyComparisonMajor.saga),
        fork(companyComparisonSector.saga),
        fork(companyComparisonLanguage.saga),
        fork(companyComparisonSoftSkill.saga),
        fork(companyComparisonHardSkill.saga),
        fork(companyAllCompanies.saga),
        fork(companyPDF.saga),
        fork(companySettings.saga),
        fork(companySettingsLinks.saga),
        fork(companyAuthors.saga),
        fork(companyBlogAuthors.saga),
        fork(companyBlogInterestTags.saga),
        fork(companySignup.saga),
        fork(companyLogin.saga),
        fork(companySteps.saga),
        fork(companyNotification.saga),
        fork(companyCommunication.saga),
        fork(companyJobs.saga),
        fork(companyTalents.saga),
        fork(companyPreviewLink.saga),

        // Company Culture
        fork(companyCultureCategory.saga),
        fork(companyCultureQuestion.saga),
        fork(companyCultureAnswer.saga),
        fork(companyCultureResult.saga),
        fork(companyCultureImportance.saga),
        fork(companyCultureTribe.saga),

        fork(companyStudentCircle.saga),
        fork(companyStudentTalent.saga),
        fork(companyStudentOpportunity.saga)

    ]);
}

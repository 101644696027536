import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_BENCHMARKS_SOFT_SKILL_REQUESTED: "GET_BENCHMARKS_SOFT_SKILL_REQUESTED",
    GET_BENCHMARKS_SOFT_SKILL_SUCCEEDED: "GET_BENCHMARKS_SOFT_SKILL_SUCCEEDED",
    GET_BENCHMARKS_SOFT_SKILL_FAILED: "GET_BENCHMARKS_SOFT_SKILL_FAILED",
    GET_BENCHMARKS_HARD_SKILL_REQUESTED: "GET_BENCHMARKS_HARD_SKILL_REQUESTED",
    GET_BENCHMARKS_HARD_SKILL_SUCCEEDED: "GET_BENCHMARKS_HARD_SKILL_SUCCEEDED",
    GET_BENCHMARKS_HARD_SKILL_FAILED: "GET_BENCHMARKS_HARD_SKILL_FAILED"
};

// Action Creators
export const actions = {
    getSoft: callback => {
        return {
            type: types.GET_BENCHMARKS_SOFT_SKILL_REQUESTED,
            callback
        };
    },
    getHard: callback => {
        return {
            type: types.GET_BENCHMARKS_HARD_SKILL_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: { soft: [], hard: [] },
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_BENCHMARKS_SOFT_SKILL_REQUESTED:
        case types.GET_BENCHMARKS_HARD_SKILL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_BENCHMARKS_SOFT_SKILL_SUCCEEDED:
            return {
                data: { ...state.data, soft: action.payload.data },
                error: "",
                loading: false
            };
        case types.GET_BENCHMARKS_HARD_SKILL_SUCCEEDED:
            return {
                data: { ...state.data, hard: action.payload.data },
                error: "",
                loading: false
            };
        case types.GET_BENCHMARKS_SOFT_SKILL_FAILED:
        case types.GET_BENCHMARKS_HARD_SKILL_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_BENCHMARKS_SOFT_SKILL_REQUESTED, getSoftWorker);
    yield takeLatest(types.GET_BENCHMARKS_HARD_SKILL_REQUESTED, getHardWorker);
}

// Saga callback
function* getSoftWorker({ callback }) {
    try {
        const response = yield call(getSoft);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_BENCHMARKS_SOFT_SKILL_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_BENCHMARKS_SOFT_SKILL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getHardWorker({ callback }) {
    try {
        const response = yield call(getHard);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_BENCHMARKS_HARD_SKILL_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_BENCHMARKS_HARD_SKILL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getSoft() {
    return api.get(`/api/benchmarks/soft-skills`);
}

function getHard() {
    return api.get(`/api/benchmarks/hard-skills`);
}

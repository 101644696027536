// import reducer, { actions, saga } from './ducks';


import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Default state
const defaultState = {
    data: [],
    single: [],
    error: "",
    loading: true
};

// Actions
const types = {
    LIST_COMPANY_STUDENT_CIRCLES_REQUESTED: "LIST_COMPANY_STUDENT_CIRCLES_REQUESTED",
    LIST_COMPANY_STUDENT_CIRCLES_SUCCEEDED: "LIST_COMPANY_STUDENT_CIRCLES_SUCCEEDED",
    LIST_COMPANY_STUDENT_CIRCLES_FAILED: "LIST_COMPANY_STUDENT_CIRCLES_FAILED",

    GET_COMPANY_STUDENT_CIRCLES_REQUESTED: "GET_COMPANY_STUDENT_CIRCLES_REQUESTED",
    GET_COMPANY_STUDENT_CIRCLES_SUCCEEDED: "GET_COMPANY_STUDENT_CIRCLES_SUCCEEDED",
    GET_COMPANY_STUDENT_CIRCLES_FAILED: "GET_COMPANY_STUDENT_CIRCLES_FAILED",

    SAVE_COMPANY_STUDENT_CIRCLES_REQUESTED: "SAVE_COMPANY_STUDENT_CIRCLES_REQUESTED",
    SAVE_COMPANY_STUDENT_CIRCLES_SUCCEEDED: "SAVE_COMPANY_STUDENT_CIRCLES_SUCCEEDED",
    SAVE_COMPANY_STUDENT_CIRCLES_FAILED: "SAVE_COMPANY_STUDENT_CIRCLES_FAILED"
};

// Action Creators
const actions = {

    all: (token, callback) => {
        return {
            type: types.LIST_COMPANY_STUDENT_CIRCLES_REQUESTED,
            token,
            callback
        };
    },

    get: (token, categoryId, callback) => {
        return {
            type: types.GET_COMPANY_STUDENT_CIRCLES_REQUESTED,
            token,
            categoryId,
            callback
        };
    },

    save: (token, data, callback) => {
        return {
            type: types.SAVE_COMPANY_STUDENT_CIRCLES_REQUESTED,
            token,
            data,
            callback
        };
    }

};


// Reducers
function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.LIST_COMPANY_STUDENT_CIRCLES_REQUESTED:
        case types.GET_COMPANY_STUDENT_CIRCLES_REQUESTED:
        case types.SAVE_COMPANY_STUDENT_CIRCLES_REQUESTED:
            return {
                ...state,
                loading: true
            };

        case types.LIST_COMPANY_STUDENT_CIRCLES_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };

        case types.GET_COMPANY_STUDENT_CIRCLES_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                loading: false
            };

        case types.SAVE_COMPANY_STUDENT_CIRCLES_SUCCEEDED:
            return {
                ...state,
                data: action.payload.success ? [...state.data, action.payload.data] : [...state],
                error: "",
                loading: false
            };

        case types.LIST_COMPANY_STUDENT_CIRCLES_FAILED:
        case types.SAVE_COMPANY_STUDENT_CIRCLES_FAILED:
        case types.GET_COMPANY_STUDENT_CIRCLES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        default:
            return state;
    }
}

// Sagas
function* saga() {

    yield takeLatest(types.LIST_COMPANY_STUDENT_CIRCLES_REQUESTED, allWorker);
    yield takeLatest(types.GET_COMPANY_STUDENT_CIRCLES_REQUESTED, getWorker);
    yield takeLatest(types.SAVE_COMPANY_STUDENT_CIRCLES_REQUESTED, saveWorker);

}

// Saga callback
function* allWorker({ token, callback }) {
    try {
        const response = yield call(all, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LIST_COMPANY_STUDENT_CIRCLES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.LIST_COMPANY_STUDENT_CIRCLES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, categoryId, callback }) {
    try {
        const response = yield call(get, { token, categoryId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_STUDENT_CIRCLES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.LIST_COMPANY_STUDENT_CIRCLES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveWorker({ token, data, callback }) {
    try {
        const response = yield call(save, { token, data });

        // console.log('AAAAASAVE', response);
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_STUDENT_CIRCLES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_STUDENT_CIRCLES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.message ? e.message : e.errors);
    }
}


// API call
function all({ token }) {
    return api.get(`/api/company/${token}/student/circles`);
}

function get({ token, categoryId }) {
    return api.get(`/api/company/${token}/student/circles/${categoryId}`);
}

function save({ token, data }) {
    return api.post(`/api/company/${token}/student/circles/save`, data);
}


export default { reducer, actions, saga };

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_PROFILE_EDUCATION_REQUESTED: "SAVE_PROFILE_EDUCATION_REQUESTED",
    SAVE_PROFILE_EDUCATION_SUCCEEDED: "SAVE_PROFILE_EDUCATION_SUCCEEDED",
    SAVE_PROFILE_EDUCATION_FAILED: "SAVE_PROFILE_EDUCATION_FAILED",
    GET_PROFILE_EDUCATION_REQUESTED: "GET_PROFILE_EDUCATION_REQUESTED",
    GET_PROFILE_EDUCATION_SUCCEEDED: "GET_PROFILE_EDUCATION_SUCCEEDED",
    GET_PROFILE_EDUCATION_FAILED: "GET_PROFILE_EDUCATION_FAILED",
    EDIT_PROFILE_EDUCATION_REQUESTED: "EDIT_PROFILE_EDUCATION_REQUESTED",
    EDIT_PROFILE_EDUCATION_SUCCEEDED: "EDIT_PROFILE_EDUCATION_SUCCEEDED",
    EDIT_PROFILE_EDUCATION_FAILED: "EDIT_PROFILE_EDUCATION_FAILED",
    DELETE_PROFILE_EDUCATION_REQUESTED: "DELETE_PROFILE_EDUCATION_REQUESTED",
    DELETE_PROFILE_EDUCATION_SUCCEEDED: "DELETE_PROFILE_EDUCATION_SUCCEEDED",
    DELETE_PROFILE_EDUCATION_FAILED: "DELETE_PROFILE_EDUCATION_FAILED"
};

// Action Creators
export const actions = {
    save: (data, callback) => {
        return {
            type: types.SAVE_PROFILE_EDUCATION_REQUESTED,
            data,
            callback
        };
    },
    get: callback => {
        return {
            type: types.GET_PROFILE_EDUCATION_REQUESTED,
            callback
        };
    },
    edit: (educationId, data, callback) => {
        return {
            type: types.EDIT_PROFILE_EDUCATION_REQUESTED,
            educationId,
            data,
            callback
        };
    },
    delete: (educationId, callback) => {
        return {
            type: types.DELETE_PROFILE_EDUCATION_REQUESTED,
            educationId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SAVE_PROFILE_EDUCATION_REQUESTED:
        case types.EDIT_PROFILE_EDUCATION_REQUESTED:
        case types.DELETE_PROFILE_EDUCATION_REQUESTED:
        case types.GET_PROFILE_EDUCATION_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SAVE_PROFILE_EDUCATION_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_PROFILE_EDUCATION_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.EDIT_PROFILE_EDUCATION_SUCCEEDED:
            return {
                data: state.data.map(d => {
                    if (d.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return d;
                }),
                error: "",
                loading: false
            };
        case types.DELETE_PROFILE_EDUCATION_SUCCEEDED:
            return {
                data: state.data.filter(d => {
                    return d.id !== Number(action.payload);
                }),
                error: "",
                loading: false
            };
        case types.SAVE_PROFILE_EDUCATION_FAILED:
        case types.EDIT_PROFILE_EDUCATION_FAILED:
        case types.DELETE_PROFILE_EDUCATION_FAILED:
        case types.GET_PROFILE_EDUCATION_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_PROFILE_EDUCATION_REQUESTED, saveWorker);
    yield takeLatest(types.GET_PROFILE_EDUCATION_REQUESTED, getWorker);
    yield takeLatest(types.EDIT_PROFILE_EDUCATION_REQUESTED, editWorker);
    yield takeLatest(types.DELETE_PROFILE_EDUCATION_REQUESTED, deleteWorker);
}

// Saga callback
function* saveWorker({ data, callback }) {
    try {
        const response = yield call(save, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PROFILE_EDUCATION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_PROFILE_EDUCATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PROFILE_EDUCATION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_PROFILE_EDUCATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* editWorker({ educationId, data, callback }) {
    try {
        const response = yield call(edit, { educationId, data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_PROFILE_EDUCATION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EDIT_PROFILE_EDUCATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ educationId, callback }) {
    try {
        const response = yield call(deleteItem, { educationId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_PROFILE_EDUCATION_SUCCEEDED,
            payload: educationId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_PROFILE_EDUCATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data }) {
    return api.post("/api/education", data);
}

function get() {
    return api.get("/api/education");
}

function edit({ educationId, data }) {
    return api.put("/api/education", { education_id: educationId, ...data });
}

function deleteItem({ educationId }) {
    return api.delete(`/api/education/${educationId}`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_CULTURE_ANSWERS_REQUESTED:
        "GET_COMPANY_CULTURE_ANSWERS_REQUESTED",
    GET_COMPANY_CULTURE_ANSWERS_SUCCEEDED:
        "GET_COMPANY_CULTURE_ANSWERS_SUCCEEDED",
    GET_COMPANY_CULTURE_ANSWERS_FAILED: "GET_COMPANY_CULTURE_ANSWERS_FAILED",
    SEND_COMPANY_CULTURE_ANSWERS_REQUESTED:
        "SEND_COMPANY_CULTURE_ANSWERS_REQUESTED",
    SEND_COMPANY_CULTURE_ANSWERS_SUCCEEDED:
        "SEND_COMPANY_CULTURE_ANSWERS_SUCCEEDED",
    SEND_COMPANY_CULTURE_ANSWERS_FAILED: "SEND_COMPANY_CULTURE_ANSWERS_FAILED"
};

// Action Creators
export const actions = {
    get: (token, callback) => {
        return {
            type: types.GET_COMPANY_CULTURE_ANSWERS_REQUESTED,
            token,
            callback
        };
    },
    send: (token, answers, callback) => {
        return {
            type: types.SEND_COMPANY_CULTURE_ANSWERS_REQUESTED,
            answers,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SEND_COMPANY_CULTURE_ANSWERS_REQUESTED:
        case types.GET_COMPANY_CULTURE_ANSWERS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SEND_COMPANY_CULTURE_ANSWERS_SUCCEEDED:
            return {
                ...state,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_CULTURE_ANSWERS_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.SEND_COMPANY_CULTURE_ANSWERS_FAILED:
        case types.GET_COMPANY_CULTURE_ANSWERS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SEND_COMPANY_CULTURE_ANSWERS_REQUESTED, sendWorker);
    yield takeLatest(types.GET_COMPANY_CULTURE_ANSWERS_REQUESTED, getWorker);
}

// Saga callback
function* sendWorker({ token, answers, callback }) {
    try {
        const response = yield call(send, { token, answers });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEND_COMPANY_CULTURE_ANSWERS_SUCCEEDED
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SEND_COMPANY_CULTURE_ANSWERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_CULTURE_ANSWERS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_CULTURE_ANSWERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function send({ token, answers }) {
    return api.post(`/api/company/${token}/culture-test/answers`, {
        all_answers: answers.all_answers,
        author: answers.author
    });
}

function get({ token }) {
    return api.get(`/api/company/${token}/culture-test/answers`);
}

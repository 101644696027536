import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_FOLLOWS_REQUESTED: "GET_COMPANY_FOLLOWS_REQUESTED",
    GET_COMPANY_FOLLOWS_SUCCEEDED: "GET_COMPANY_FOLLOWS_SUCCEEDED",
    GET_COMPANY_FOLLOWS_FAILED: "GET_COMPANY_FOLLOWS_FAILED",
    GET_COMPANY_NEW_FOLLOWS_REQUESTED: "GET_COMPANY_NEW_FOLLOWS_REQUESTED",
    GET_COMPANY_NEW_FOLLOWS_SUCCEEDED: "GET_COMPANY_NEW_FOLLOWS_SUCCEEDED",
    GET_COMPANY_NEW_FOLLOWS_FAILED: "GET_COMPANY_NEW_FOLLOWS_FAILED"
};

// Action Creators
export const actions = {
    get: (token, callback) => {
        return {
            type: types.GET_COMPANY_FOLLOWS_REQUESTED,
            token,
            callback
        };
    },
    getNew: (token, callback) => {
        return {
            type: types.GET_COMPANY_NEW_FOLLOWS_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    new: 0,
    newLoading: true,
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_FOLLOWS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_NEW_FOLLOWS_REQUESTED:
            return {
                ...state,
                newLoading: true
            };
        case types.GET_COMPANY_FOLLOWS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_NEW_FOLLOWS_SUCCEEDED:
            return {
                ...state,
                new: action.payload.data,
                error: "",
                newLoading: false
            };
        case types.GET_COMPANY_FOLLOWS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_NEW_FOLLOWS_FAILED:
            return {
                ...state,
                error: action.payload,
                newLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_FOLLOWS_REQUESTED, getWorker);
    yield takeLatest(types.GET_COMPANY_NEW_FOLLOWS_REQUESTED, getNewWorker);
}

// Saga callback
function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_FOLLOWS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_FOLLOWS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getNewWorker({ token, callback }) {
    try {
        const response = yield call(getNew, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_NEW_FOLLOWS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_NEW_FOLLOWS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ token }) {
    return api.get(`/api/company/${token}/follows`);
}

function getNew({ token }) {
    return api.get(`/api/company/${token}/follows/new`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";

// Actions
const types = {
    GET_ROLE_REQUESTED: "GET_ROLE_REQUESTED",
    GET_ROLE_SUCCEEDED: "GET_ROLE_SUCCEEDED",
    GET_ROLE_FAILED: "GET_ROLE_FAILED"
};

// Action Creators
export const actions = {
    get: callback => {
        return {
            type: types.GET_ROLE_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_ROLE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_ROLE_SUCCEEDED:
            return {
                data: action.payload,
                error: "",
                loading: false
            };
        case types.GET_ROLE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_ROLE_REQUESTED, getWorker);
}

const callSuccess = (callback, data) => {
    typeof callback == "function" && callback(false, data);
};

const callFail = (callback, data) => {
    typeof callback == "function" && callback(data, null);
};

// Saga callback
function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw { message: response.data };
        }

        yield put({
            type: types.GET_ROLE_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_ROLE_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

// API call
function get() {
    return api.get("/api/experience/seniority");
}

import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_CITY_LIST_REQUESTED: "GET_CITY_LIST_REQUESTED",
    GET_CITY_LIST_SUCCEEDED: "GET_CITY_LIST_SUCCEEDED",
    GET_CITY_LIST_FAILED: "GET_CITY_LIST_FAILED"

};

// Action Creators
export const actions = {
    getCityList: (token, mode, callback) => {
        return {
            type: types.GET_CITY_LIST_REQUESTED,
            token,
            mode,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    data: {},
    error: "",
    message: "",
    loading: false,
    auth: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_CITY_LIST_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_CITY_LIST_SUCCEEDED:
            return {
                ...state,
                message: action.message,
                loading: false
            };
        case types.GET_CITY_LIST_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_CITY_LIST_REQUESTED, startGetCity);

}

// Saga callback
function* startGetCity({ token, mode, callback }) {
    try {
        const response = yield call(getCityList, { token, mode });
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CITY_LIST_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.GET_CITY_LIST_FAILED, payload: e.errors });

        callFail(callback, e.errors);
    }
}

// API call
function getCityList({ token, mode = '' }) {

    return api.get(`/api/base/get-city-list/${token}?mode=${mode}`);

}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPLOAD_FILE_REQUESTED: "UPLOAD_FILE_REQUESTED",
    UPLOAD_FILE_SUCCEEDED: "UPLOAD_FILE_SUCCEEDED",
    UPLOAD_FILE_FAILED: "UPLOAD_FILE_FAILED",
    GET_FILES_REQUESTED: "GET_FILES_REQUESTED",
    GET_FILES_SUCCEEDED: "GET_FILES_SUCCEEDED",
    GET_FILES_FAILED: "GET_FILES_FAILED",
    DOWNLOAD_FILE_REQUESTED: "DOWNLOAD_FILE_REQUESTED",
    DOWNLOAD_FILE_SUCCEEDED: "DOWNLOAD_FILE_SUCCEEDED",
    DOWNLOAD_FILE_FAILED: "DOWNLOAD_FILE_FAILED",
    DELETE_FILE_REQUESTED: "DELETE_FILE_REQUESTED",
    DELETE_FILE_SUCCEEDED: "DELETE_FILE_SUCCEEDED",
    DELETE_FILE_FAILED: "DELETE_FILE_FAILED"
};

// Action Creators
export const actions = {
    upload: (token, profileId, userId, file, setProgress, callback) => {
        return {
            type: types.UPLOAD_FILE_REQUESTED,
            token,
            profileId,
            userId,
            file,
            setProgress,
            callback
        };
    },
    get: (token, profileId, userId, callback) => {
        return {
            type: types.GET_FILES_REQUESTED,
            token,
            profileId,
            userId,
            callback
        };
    },
    download: (token, fileName, callback) => {
        return {
            type: types.DOWNLOAD_FILE_REQUESTED,
            token,
            fileName,
            callback
        };
    },
    delete: (token, fileName, callback) => {
        return {
            type: types.DELETE_FILE_REQUESTED,
            token,
            fileName,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_FILES_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_FILES_SUCCEEDED:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.userId]: action.payload.data
                },
                error: "",
                loading: false
            };
        case types.GET_FILES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.UPLOAD_FILE_REQUESTED, uploadWorker);
    yield takeLatest(types.GET_FILES_REQUESTED, getWorker);
    yield takeLatest(types.DOWNLOAD_FILE_REQUESTED, downloadWorker);
    yield takeLatest(types.DELETE_FILE_REQUESTED, deleteWorker);
}

// Saga callback
function* uploadWorker({
    token,
    profileId,
    userId,
    file,
    setProgress,
    callback
}) {
    try {
        const response = yield call(upload, {
            token,
            profileId,
            userId,
            file,
            setProgress
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPLOAD_FILE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPLOAD_FILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, profileId, userId, callback }) {
    try {
        const response = yield call(get, { token, profileId, userId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_FILES_SUCCEEDED,
            payload: {
                data: response.data.data,
                userId
            }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_FILES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* downloadWorker({ token, fileName, callback }) {
    try {
        const response = yield call(download, {
            token,
            fileName
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPLOAD_FILE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPLOAD_FILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ token, fileName, callback }) {
    try {
        const response = yield call(deleteFile, {
            token,
            fileName
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_FILE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_FILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function upload({ token, profileId, userId, file, setProgress }) {
    const data = new FormData();

    data.append("document", file);

    return api.post(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/document`,
        data,
        {
            onUploadProgress: (e) => {
                setProgress((e.loaded / e.total) * 100);
            }
        }
    );
}

function download({ token, fileName }) {
    return api.get(`/api/company/${token}/download-document/${fileName}`, {
        responseType: "blob"
    });
}

function get({ token, profileId, userId }) {
    return api.get(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/document`
    );
}

function deleteFile({ token, fileName }) {
    return api.delete(`/api/company/${token}/document/${fileName}`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPLOAD_LOGO_REQUESTED: "UPLOAD_LOGO_REQUESTED",
    UPLOAD_LOGO_SUCCEEDED: "UPLOAD_LOGO_SUCCEEDED",
    UPLOAD_LOGO_FAILED: "UPLOAD_LOGO_FAILED"
};

// Action Creators
export const actions = {
    upload: (token, file, setProgress, callback) => {
        return {
            type: types.UPLOAD_LOGO_REQUESTED,
            token,
            file,
            setProgress,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.UPLOAD_LOGO_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.UPLOAD_LOGO_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.UPLOAD_LOGO_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.UPLOAD_LOGO_REQUESTED, uploadWorker);
}

// Saga callback
function* uploadWorker({ token, file, setProgress, callback }) {
    try {
        const response = yield call(upload, {
            token,
            file,
            setProgress
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPLOAD_LOGO_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPLOAD_LOGO_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function upload({ token, file, setProgress }) {
    const data = new FormData();

    data.append("logo", file);

    return api.post(`/api/company/${token}/logo`, data, {
        onUploadProgress: (e) => {
            setProgress((e.loaded / e.total) * 100);
        }
    });
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    INVITE_TO_TRIBE_REQUESTED: "INVITE_TO_TRIBE_REQUESTED",
    INVITE_TO_TRIBE_SUCCEEDED: "INVITE_TO_TRIBE_SUCCEEDED",
    INVITE_TO_TRIBE_FAILED: "INVITE_TO_TRIBE_FAILED",
    NOT_INTERESTED_REQUESTED: "NOT_INTERESTED_REQUESTED",
    NOT_INTERESTED_SUCCEEDED: "NOT_INTERESTED_SUCCEEDED",
    NOT_INTERESTED_FAILED: "NOT_INTERESTED_FAILED",
    STATUS_ACCEPTED_REQUESTED: "STATUS_ACCEPTED_REQUESTED",
    STATUS_ACCEPTED_SUCCEEDED: "STATUS_ACCEPTED_SUCCEEDED",
    STATUS_ACCEPTED_FAILED: "STATUS_ACCEPTED_FAILED",
    ADD_TO_TOP_100_REQUESTED: "ADD_TO_TOP_100_REQUESTED",
    ADD_TO_TOP_100_SUCCEEDED: "ADD_TO_TOP_100_SUCCEEDED",
    ADD_TO_TOP_100_FAILED: "ADD_TO_TOP_100_FAILED",
    REMOVE_FROM_TOP_100_REQUESTED: "REMOVE_FROM_TOP_100_REQUESTED",
    REMOVE_FROM_TOP_100_SUCCEEDED: "REMOVE_FROM_TOP_100_SUCCEEDED",
    REMOVE_FROM_TOP_100_FAILED: "REMOVE_FROM_TOP_100_FAILED"
};

// Action Creators
export const actions = {
    inviteToTribe: (token, profileId, userId, callback) => {
        return {
            type: types.INVITE_TO_TRIBE_REQUESTED,
            token,
            profileId,
            userId,
            callback
        };
    },
    notInterested: (token, profileId, userId, callback) => {
        return {
            type: types.NOT_INTERESTED_REQUESTED,
            token,
            profileId,
            userId,
            callback
        };
    },
    accepted: (token, profileId, userId, callback) => {
        return {
            type: types.STATUS_ACCEPTED_REQUESTED,
            token,
            profileId,
            userId,
            callback
        };
    },
    removeFromTop100: (token, profileId, userId, callback) => {
        return {
            type: types.REMOVE_FROM_TOP_100_REQUESTED,
            token,
            profileId,
            userId,
            callback
        };
    },
    addToTop100: (token, profileId, userId, callback) => {
        return {
            type: types.ADD_TO_TOP_100_REQUESTED,
            token,
            profileId,
            userId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.INVITE_TO_TRIBE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.INVITE_TO_TRIBE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.INVITE_TO_TRIBE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.INVITE_TO_TRIBE_REQUESTED, inviteToTribeWorker);
    yield takeLatest(types.NOT_INTERESTED_REQUESTED, notInterestedWorker);
    yield takeLatest(types.STATUS_ACCEPTED_REQUESTED, acceptedWorker);
    yield takeLatest(types.ADD_TO_TOP_100_REQUESTED, addTop100Worker);
    yield takeLatest(types.REMOVE_FROM_TOP_100_REQUESTED, removeTop100Worker);
}

// Saga callback
function* inviteToTribeWorker({ token, profileId, userId, callback }) {
    try {
        const response = yield call(inviteToTribe, {
            token,
            profileId,
            userId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.INVITE_TO_TRIBE_SUCCEEDED,
            payload: response.data,
            userId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        // console.error({ e });
        yield put({
            type: types.INVITE_TO_TRIBE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* notInterestedWorker({ token, profileId, userId, callback }) {
    try {
        const response = yield call(notInterested, {
            token,
            profileId,
            userId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.NOT_INTERESTED_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.NOT_INTERESTED_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* acceptedWorker({ token, profileId, userId, callback }) {
    try {
        const response = yield call(accepted, {
            token,
            profileId,
            userId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.STATUS_ACCEPTED_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.STATUS_ACCEPTED_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* addTop100Worker({ token, profileId, userId, callback }) {
    try {
        const response = yield call(addTop100, {
            token,
            profileId,
            userId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ADD_TO_TOP_100_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.ADD_TO_TOP_100_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

function* removeTop100Worker({ token, profileId, userId, callback }) {
    try {
        const response = yield call(removeTop100, {
            token,
            profileId,
            userId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REMOVE_FROM_TOP_100_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.REMOVE_FROM_TOP_100_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function inviteToTribe({ token, profileId, userId }) {
    return api.put(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/invite-to-tribe`
    );
}

function notInterested({ token, profileId, userId }) {
    return api.put(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/not-interested`
    );
}

function accepted({ token, profileId, userId }) {
    return api.put(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/accept-to-tribe`
    );
}

function addTop100({ token, profileId, userId }) {
    return api.put(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/top-100-priority`,
        {
            priority: "add"
        }
    );
}

function removeTop100({ token, profileId, userId }) {
    return api.put(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/top-100-priority`,
        {
            priority: "remove"
        }
    );
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import stringToSlug from "Util/stringToSlug";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPDATE_COMPANY_DRAFT_TRIBE_REQUESTED:
        "UPDATE_COMPANY_DRAFT_TRIBE_REQUESTED",
    UPDATE_COMPANY_DRAFT_TRIBE_SUCCEEDED:
        "UPDATE_COMPANY_DRAFT_TRIBE_SUCCEEDED",
    UPDATE_COMPANY_DRAFT_TRIBE_FAILED: "UPDATE_COMPANY_DRAFT_TRIBE_FAILED",
    GET_COMPANY_DRAFT_TRIBE_REQUESTED: "GET_COMPANY_DRAFT_TRIBE_REQUESTED",
    GET_COMPANY_DRAFT_TRIBE_SUCCEEDED: "GET_COMPANY_DRAFT_TRIBE_SUCCEEDED",
    GET_COMPANY_DRAFT_TRIBE_FAILED: "GET_COMPANY_DRAFT_TRIBE_FAILED",
    DISCARD_COMPANY_DRAFT_TRIBE_REQUESTED:
        "DISCARD_COMPANY_DRAFT_TRIBE_REQUESTED",
    DISCARD_COMPANY_DRAFT_TRIBE_SUCCEEDED:
        "DISCARD_COMPANY_DRAFT_TRIBE_SUCCEEDED",
    DISCARD_COMPANY_DRAFT_TRIBE_FAILED: "DISCARD_COMPANY_DRAFT_TRIBE_FAILED"
};

// Action Creators
export const actions = {
    update: (category, profileId, data, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_DRAFT_TRIBE_REQUESTED,
            category,
            profileId,
            data,
            token,
            callback
        };
    },
    get: (token, category, profileId, callback) => {
        return {
            type: types.GET_COMPANY_DRAFT_TRIBE_REQUESTED,
            token,
            category,
            profileId,
            callback
        };
    },
    discard: (token, category, profileId, callback) => {
        return {
            type: types.DISCARD_COMPANY_DRAFT_TRIBE_REQUESTED,
            token,
            category,
            profileId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: null,
    error: "",
    loading: true,
    saving: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_DRAFT_TRIBE_REQUESTED:
        case types.DISCARD_COMPANY_DRAFT_TRIBE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.UPDATE_COMPANY_DRAFT_TRIBE_REQUESTED:
            return {
                ...state,
                saving: true
            };
        case types.DISCARD_COMPANY_DRAFT_TRIBE_SUCCEEDED:
            return {
                ...state,
                data: null,
                error: "",
                loading: false
            };
        case types.UPDATE_COMPANY_DRAFT_TRIBE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                saving: false
            };
        case types.GET_COMPANY_DRAFT_TRIBE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.DISCARD_COMPANY_DRAFT_TRIBE_FAILED:
            return {
                ...state,
                data: null,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_DRAFT_TRIBE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.UPDATE_COMPANY_DRAFT_TRIBE_FAILED:
            return {
                ...state,
                saving: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.UPDATE_COMPANY_DRAFT_TRIBE_REQUESTED, updateWorker);
    yield takeLatest(types.GET_COMPANY_DRAFT_TRIBE_REQUESTED, getWorker);
    yield takeLatest(
        types.DISCARD_COMPANY_DRAFT_TRIBE_REQUESTED,
        discardWorker
    );
}

// Saga callback
function* updateWorker({ category, profileId, data, token, callback }) {
    try {
        const response = yield call(update, {
            category,
            profileId,
            data,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_DRAFT_TRIBE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_DRAFT_TRIBE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, category, profileId, callback }) {
    try {
        const response = yield call(get, { category, profileId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_DRAFT_TRIBE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_DRAFT_TRIBE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* discardWorker({ category, profileId, token, callback }) {
    try {
        const response = yield call(deleteDraft, {
            category,
            profileId,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DISCARD_COMPANY_DRAFT_TRIBE_SUCCEEDED
        });

        callSuccess(callback, {});
    } catch (e) {
        yield put({
            type: types.DISCARD_COMPANY_DRAFT_TRIBE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function update({ category, profileId, data, token }) {
    return api.put(
        `/api/company/${token}/draft/circle-${stringToSlug(
            category
        )}/${profileId}`,
        data
    );
}

function get({ category, profileId, token }) {
    return api.get(
        `/api/company/${token}/draft/circle-${stringToSlug(
            category
        )}/${profileId}`
    );
}

function deleteDraft({ category, profileId, token }) {
    return api.delete(
        `/api/company/${token}/draft/circle-${stringToSlug(
            category
        )}/${profileId}`
    );
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_PROFILE_SOFT_SKILL_REQUESTED: "SAVE_PROFILE_SOFT_SKILL_REQUESTED",
    SAVE_PROFILE_SOFT_SKILL_SUCCEEDED: "SAVE_PROFILE_SOFT_SKILL_SUCCEEDED",
    SAVE_PROFILE_SOFT_SKILL_FAILED: "SAVE_PROFILE_SOFT_SKILL_FAILED",
    GET_PROFILE_SOFT_SKILL_REQUESTED: "GET_PROFILE_SOFT_SKILL_REQUESTED",
    GET_PROFILE_SOFT_SKILL_SUCCEEDED: "GET_PROFILE_SOFT_SKILL_SUCCEEDED",
    GET_PROFILE_SOFT_SKILL_FAILED: "GET_PROFILE_SOFT_SKILL_FAILED",
    SAVE_PROFILE_HARD_SKILL_REQUESTED: "SAVE_PROFILE_HARD_SKILL_REQUESTED",
    SAVE_PROFILE_HARD_SKILL_SUCCEEDED: "SAVE_PROFILE_HARD_SKILL_SUCCEEDED",
    SAVE_PROFILE_HARD_SKILL_FAILED: "SAVE_PROFILE_HARD_SKILL_FAILED",
    GET_PROFILE_HARD_SKILL_REQUESTED: "GET_PROFILE_HARD_SKILL_REQUESTED",
    GET_PROFILE_HARD_SKILL_SUCCEEDED: "GET_PROFILE_HARD_SKILL_SUCCEEDED",
    GET_PROFILE_HARD_SKILL_FAILED: "GET_PROFILE_HARD_SKILL_FAILED"
};

// Action Creators
export const actions = {
    saveSoft: (data, callback) => {
        return {
            type: types.SAVE_PROFILE_SOFT_SKILL_REQUESTED,
            data,
            callback
        };
    },
    getSoft: callback => {
        return {
            type: types.GET_PROFILE_SOFT_SKILL_REQUESTED,
            callback
        };
    },
    saveHard: (data, callback) => {
        return {
            type: types.SAVE_PROFILE_HARD_SKILL_REQUESTED,
            data,
            callback
        };
    },
    getHard: callback => {
        return {
            type: types.GET_PROFILE_HARD_SKILL_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: { soft: [], hard: [] },
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SAVE_PROFILE_HARD_SKILL_REQUESTED:
        case types.GET_PROFILE_HARD_SKILL_REQUESTED:
        case types.SAVE_PROFILE_SOFT_SKILL_REQUESTED:
        case types.GET_PROFILE_SOFT_SKILL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SAVE_PROFILE_SOFT_SKILL_SUCCEEDED:
        case types.GET_PROFILE_SOFT_SKILL_SUCCEEDED:
            return {
                data: { ...state.data, soft: action.payload },
                error: "",
                loading: false
            };
        case types.SAVE_PROFILE_HARD_SKILL_SUCCEEDED:
        case types.GET_PROFILE_HARD_SKILL_SUCCEEDED:
            return {
                data: { ...state.data, hard: action.payload },
                error: "",
                loading: false
            };
        case types.SAVE_PROFILE_SOFT_SKILL_FAILED:
        case types.GET_PROFILE_SOFT_SKILL_FAILED:
        case types.SAVE_PROFILE_HARD_SKILL_FAILED:
        case types.GET_PROFILE_HARD_SKILL_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_PROFILE_SOFT_SKILL_REQUESTED, saveSoftWorker);
    yield takeLatest(types.GET_PROFILE_SOFT_SKILL_REQUESTED, getSoftWorker);
    yield takeLatest(types.SAVE_PROFILE_HARD_SKILL_REQUESTED, saveHardWorker);
    yield takeLatest(types.GET_PROFILE_HARD_SKILL_REQUESTED, getHardWorker);
}

// Saga callback
function* saveSoftWorker({ data, callback }) {
    try {
        const response = yield call(saveSoft, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PROFILE_SOFT_SKILL_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_PROFILE_SOFT_SKILL_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

function* getSoftWorker({ callback }) {
    try {
        const response = yield call(getSoft);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PROFILE_SOFT_SKILL_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_PROFILE_SOFT_SKILL_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

function* saveHardWorker({ data, callback }) {
    try {
        const response = yield call(saveHard, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PROFILE_HARD_SKILL_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_PROFILE_HARD_SKILL_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

function* getHardWorker({ callback }) {
    try {
        const response = yield call(getHard);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PROFILE_HARD_SKILL_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_PROFILE_HARD_SKILL_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

// API call
function saveSoft({ data }) {
    return api.put("/api/skills/talent/soft", data);
}

function getSoft() {
    return api.get("/api/skills/talent/soft");
}

function saveHard({ data }) {
    return api.put("/api/skills/talent/hard", data);
}

function getHard() {
    return api.get("/api/skills/talent/hard");
}

import findIndex from "lodash/findIndex";
import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_MATCHES_REQUESTED: "GET_COMPANY_MATCHES_REQUESTED",
    GET_COMPANY_MATCHES_SUCCEEDED: "GET_COMPANY_MATCHES_SUCCEEDED",
    GET_COMPANY_MATCHES_FAILED: "GET_COMPANY_MATCHES_FAILED",
    GET_COMPANY_MATCHES_PAGE_REQUESTED: "GET_COMPANY_MATCHES_PAGE_REQUESTED",
    GET_COMPANY_MATCHES_PAGE_SUCCEEDED: "GET_COMPANY_MATCHES_PAGE_SUCCEEDED",
    GET_COMPANY_MATCHES_PAGE_FAILED: "GET_COMPANY_MATCHES_PAGE_FAILED",
    GET_COMPANY_MATCHES_PAGINATED_REQUESTED:
        "GET_COMPANY_MATCHES_PAGINATED_REQUESTED",
    GET_COMPANY_MATCHES_PAGINATED_SUCCEEDED:
        "GET_COMPANY_MATCHES_PAGINATED_SUCCEEDED",
    GET_COMPANY_MATCHES_PAGINATED_FAILED:
        "GET_COMPANY_MATCHES_PAGINATED_FAILED",
    GET_COMPANY_MATCHES_BY_FEEDBACK_REQUESTED:
        "GET_COMPANY_MATCHES_BY_FEEDBACK_REQUESTED",
    GET_COMPANY_MATCHES_BY_FEEDBACK_SUCCEEDED:
        "GET_COMPANY_MATCHES_BY_FEEDBACK_SUCCEEDED",
    GET_COMPANY_MATCHES_BY_FEEDBACK_FAILED:
        "GET_COMPANY_MATCHES_BY_FEEDBACK_FAILED",
    GET_COMPANY_NEW_MATCHES_REQUESTED: "GET_COMPANY_NEW_MATCHES_REQUESTED",
    GET_COMPANY_NEW_MATCHES_SUCCEEDED: "GET_COMPANY_NEW_MATCHES_SUCCEEDED",
    GET_COMPANY_NEW_MATCHES_FAILED: "GET_COMPANY_NEW_MATCHES_FAILED",
    SEND_SCORE_COMPANY_MATCHES_REQUESTED:
        "SEND_SCORE_COMPANY_MATCHES_REQUESTED",
    SEND_SCORE_COMPANY_MATCHES_SUCCEEDED:
        "SEND_SCORE_COMPANY_MATCHES_SUCCEEDED",
    SEND_SCORE_COMPANY_MATCHES_FAILED: "SEND_SCORE_COMPANY_MATCHES_FAILED",
    SET_FEEDBACK_COMPANY_MATCHES_REQUESTED:
        "SET_FEEDBACK_COMPANY_MATCHES_REQUESTED",
    SET_FEEDBACK_COMPANY_MATCHES_SUCCEEDED:
        "SET_FEEDBACK_COMPANY_MATCHES_SUCCEEDED",
    SET_FEEDBACK_COMPANY_MATCHES_FAILED: "SET_FEEDBACK_COMPANY_MATCHES_FAILED",
    REMOVE_FROM_COMPANY_MATCHES_REQUESTED:
        "REMOVE_FROM_COMPANY_MATCHES_REQUESTED",
    REMOVE_FROM_COMPANY_MATCHES_SUCCEEDED:
        "REMOVE_FROM_COMPANY_MATCHES_SUCCEEDED",
    REMOVE_FROM_COMPANY_MATCHES_FAILED: "REMOVE_FROM_COMPANY_MATCHES_FAILED",
    GET_COMPANY_MATCH_SINGLE_REQUESTED: "GET_COMPANY_MATCH_SINGLE_REQUESTED",
    GET_COMPANY_MATCH_SINGLE_SUCCEEDED: "GET_COMPANY_MATCH_SINGLE_SUCCEEDED",
    GET_COMPANY_MATCH_SINGLE_FAILED: "GET_COMPANY_MATCH_SINGLE_FAILED"
};

// Action Creators
export const actions = {
    getSingle: (token, targetProfileId, email, callback) => {
        return {
            type: types.GET_COMPANY_MATCH_SINGLE_REQUESTED,
            token,
            targetProfileId,
            email,
            callback
        };
    },
    get: (token, callback) => {
        return {
            type: types.GET_COMPANY_MATCHES_REQUESTED,
            token,
            callback
        };
    },
    getPage: (token, page, callback) => {
        return {
            type: types.GET_COMPANY_MATCHES_PAGE_REQUESTED,
            token,
            page,
            callback
        };
    },
    getPaginated: (
        token,
        targetProfileId,
        page,
        perPage,
        labels,
        order,
        showRejected,
        term,
        callback
    ) => {
        return {
            type: types.GET_COMPANY_MATCHES_PAGINATED_REQUESTED,
            token,
            targetProfileId,
            page,
            perPage,
            labels,
            order,
            showRejected,
            term,
            callback
        };
    },
    getByFeedback: (
        token,
        targetProfileId,
        page,
        perPage,
        order,
        feedback,
        callback
    ) => {
        return {
            type: types.GET_COMPANY_MATCHES_BY_FEEDBACK_REQUESTED,
            token,
            targetProfileId,
            page,
            perPage,
            order,
            feedback,
            callback
        };
    },
    sendScore: (talentId, targetProfileId, score, token, page, callback) => {
        return {
            type: types.SEND_SCORE_COMPANY_MATCHES_REQUESTED,
            talentId,
            targetProfileId,
            score,
            token,
            page,
            callback
        };
    },
    setCircleFeedback: (
        talentId,
        targetProfileId,
        feedback,
        token,
        page,
        callback
    ) => {
        return {
            type: types.SET_FEEDBACK_COMPANY_MATCHES_REQUESTED,
            talentId,
            targetProfileId,
            feedback,
            token,
            page,
            callback
        };
    },
    removeFromCircle: (talentId, targetProfileId, token, page, callback) => {
        return {
            type: types.REMOVE_FROM_COMPANY_MATCHES_REQUESTED,
            talentId,
            targetProfileId,
            token,
            callback
        };
    },
    getNew: (token, callback) => {
        return {
            type: types.GET_COMPANY_NEW_MATCHES_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    paginated: { data: [], total: 0 },
    pageData: { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] },
    paginatedLoading: true,
    pageLoading: true,
    new: 0,
    newLoading: true,
    error: "",
    loading: true,
    single: {},
    singleLoading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_MATCHES_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_MATCHES_PAGE_REQUESTED:
            return {
                ...state,
                pageLoading: true
            };
        case types.GET_COMPANY_MATCHES_PAGINATED_REQUESTED:
        case types.GET_COMPANY_MATCHES_BY_FEEDBACK_REQUESTED:
            return {
                ...state,
                paginatedLoading: true
            };
        case types.GET_COMPANY_NEW_MATCHES_REQUESTED:
            return {
                ...state,
                newLoading: true
            };
        case types.GET_COMPANY_MATCHES_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_MATCHES_PAGE_SUCCEEDED:
            return {
                ...state,
                pageData: {
                    ...state.pageData,
                    [action.payload.page]: action.payload.data
                },
                error: "",
                pageLoading: false
            };
        case types.GET_COMPANY_MATCHES_PAGINATED_SUCCEEDED:
        case types.GET_COMPANY_MATCHES_BY_FEEDBACK_SUCCEEDED:
            return {
                ...state,
                paginated: {
                    data: {
                        ...state.paginated.data,
                        [action.payload.page]: action.payload.data.allMatches
                    },
                    total: action.payload.data.totalPages
                },
                error: "",
                paginatedLoading: false
            };
        case types.GET_COMPANY_NEW_MATCHES_SUCCEEDED:
            return {
                ...state,
                new: action.payload.data,
                error: "",
                newLoading: false
            };
        case types.SEND_SCORE_COMPANY_MATCHES_SUCCEEDED:
            const pageMatches = [...state.paginated.data[action.payload.page]];
            const matchIndex = findIndex(pageMatches, function (o) {
                return o.user.id == action.payload.talentId;
            });
            pageMatches[matchIndex].manualMatchScore = action.payload.score;

            return {
                ...state,
                paginated: {
                    ...state.paginated,
                    data: {
                        ...state.paginated.data,
                        [action.payload.page]: pageMatches
                    }
                }
            };
        case types.SET_FEEDBACK_COMPANY_MATCHES_REQUESTED: {
            const pageMatches = [...state.paginated.data[action.page]];
            const matchIndex = findIndex(pageMatches, function (o) {
                return o.user.id == action.talentId;
            });
            pageMatches[matchIndex].circle_feedback = action.feedback;

            return {
                ...state,
                paginated: {
                    ...state.paginated,
                    data: {
                        ...state.paginated.data,
                        [action.page]: pageMatches
                    }
                }
            };
        }
        case types.GET_COMPANY_MATCHES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_MATCHES_PAGE_FAILED:
            return {
                ...state,
                error: action.payload,
                pageLoading: false
            };
        case types.GET_COMPANY_MATCHES_PAGINATED_FAILED:
        case types.GET_COMPANY_MATCHES_BY_FEEDBACK_FAILED:
            return {
                ...state,
                error: action.payload,
                paginatedLoading: false
            };
        case types.GET_COMPANY_NEW_MATCHES_FAILED:
            return {
                ...state,
                error: action.payload,
                newLoading: false
            };
        case types.GET_COMPANY_MATCH_SINGLE_REQUESTED:
            return {
                ...state,
                singleLoading: true
            };
        case types.GET_COMPANY_MATCH_SINGLE_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                singleLoading: false
            };
        case types.GET_COMPANY_MATCH_SINGLE_FAILED:
            return {
                ...state,
                singleLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_MATCHES_REQUESTED, getWorker);
    yield takeLatest(types.GET_COMPANY_MATCH_SINGLE_REQUESTED, getSingleWorker);
    yield takeLatest(types.GET_COMPANY_MATCHES_PAGE_REQUESTED, getPageWorker);
    yield takeLatest(
        types.GET_COMPANY_MATCHES_PAGINATED_REQUESTED,
        getPaginatedWorker
    );
    yield takeLatest(
        types.GET_COMPANY_MATCHES_BY_FEEDBACK_REQUESTED,
        getByFeedbackWorker
    );
    yield takeLatest(types.GET_COMPANY_NEW_MATCHES_REQUESTED, getNewWorker);
    yield takeLatest(
        types.SEND_SCORE_COMPANY_MATCHES_REQUESTED,
        sendScoreWorker
    );
    yield takeLatest(
        types.REMOVE_FROM_COMPANY_MATCHES_REQUESTED,
        removeMatchWorker
    );
}

// Saga callback
function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_MATCHES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_MATCHES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getSingleWorker({ token, targetProfileId, email, callback }) {
    try {
        const response = yield call(getSingle, {
            token,
            targetProfileId,
            email
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_MATCH_SINGLE_SUCCEEDED,
            payload: response.data
        });

        yield put({
            type: "SET_LIMITED_MODE",
            payload: { limitedMode: response.data.limitedMode }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_MATCH_SINGLE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getPageWorker({ token, page, callback }) {
    try {
        const response = yield call(getPage, { token, page });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_MATCHES_PAGE_SUCCEEDED,
            payload: { ...response.data, page }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_MATCHES_PAGE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getPaginatedWorker({
    token,
    targetProfileId,
    page,
    perPage,
    labels,
    order,
    showRejected,
    term,
    callback
}) {
    try {
        const response = yield call(getPaginated, {
            token,
            targetProfileId,
            page,
            perPage,
            labels,
            order,
            showRejected,
            term
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_MATCHES_PAGINATED_SUCCEEDED,
            payload: { ...response.data, page }
        });

        yield put({
            type: "SET_LIMITED_MODE",
            payload: { limitedMode: response.data.limitedMode }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_MATCHES_PAGINATED_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getByFeedbackWorker({
    token,
    targetProfileId,
    page,
    perPage,
    order,
    feedback,
    callback
}) {
    try {
        const response = yield call(getByFeedback, {
            token,
            targetProfileId,
            page,
            perPage,
            order,
            feedback
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_MATCHES_BY_FEEDBACK_SUCCEEDED,
            payload: { ...response.data, page }
        });

        yield put({
            type: "SET_LIMITED_MODE",
            payload: { limitedMode: response.data.limitedMode }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_MATCHES_BY_FEEDBACK_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getNewWorker({ token, callback }) {
    try {
        const response = yield call(getNew, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_NEW_MATCHES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_NEW_MATCHES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* sendScoreWorker({
    talentId,
    targetProfileId,
    score,
    token,
    page = 1,
    callback
}) {
    try {
        const response = yield call(sendScore, {
            talentId,
            targetProfileId,
            score,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEND_SCORE_COMPANY_MATCHES_SUCCEEDED,
            payload: { talentId, score, page }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SEND_SCORE_COMPANY_MATCHES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* removeMatchWorker({ talentId, targetProfileId, token, callback }) {
    try {
        const response = yield call(remove, {
            talentId,
            targetProfileId,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REMOVE_FROM_COMPANY_MATCHES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.REMOVE_FROM_COMPANY_MATCHES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ token }) {
    return api.get(`/api/company/${token}/matches`);
}

function getSingle({ token, targetProfileId, email }) {
    return api.get(
        `/api/company/${token}/single-talent-card/target-profile/${targetProfileId}/user/${email}`
    );
}

function getPage({ token, page }) {
    if (page == "by-target-profile") {
        return api.get(`/api/company/${token}/matches/by-target-profile`);
    }
    return api.get(`/api/company/${token}/matches/page/${page}`);
}

function getPaginated({
    token,
    targetProfileId,
    page,
    perPage,
    labels,
    order,
    showRejected = false,
    term = ""
}) {
    return api.get(
        `/api/company/${token}/matches/by-target-profile/${targetProfileId}/paginated/${page}/${perPage}/${order}?term=${term}&labels=${labels ? labels.join(",") : ""
        }&showRejected=${showRejected ? "1" : ""}`
    );
}

function getByFeedback({
    token,
    targetProfileId,
    page,
    perPage,
    order,
    feedback = ""
}) {
    return api.get(
        `/api/company/${token}/matches/by-target-profile/${targetProfileId}/by-feedback/${page}/${perPage}/${order}?feedback=${feedback}`
    );
}

function getNew({ token }) {
    return api.get(`/api/company/${token}/matches/new`);
}

function sendScore({ talentId, targetProfileId, score, token }) {
    return api.post(`/api/company/${token}/matches/score`, {
        talent_id: talentId,
        target_profile_id: Number(targetProfileId),
        score: Number(score)
    });
}

function remove({ talentId, targetProfileId, token }) {
    return api.post(`/api/company/${token}/matches/remove`, {
        talent_id: talentId,
        target_profile_id: Number(targetProfileId)
    });
}

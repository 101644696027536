import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

// import PlaceholderPage from "Components/company/v2/placeholder/Page";
import ErrorBoundary from "Util/ErrorBoundary";
const NotFound = lazy(() => import("./NotFound"));

import companyPages from "./company/Pages";

import CircularProgress from '@material-ui/core/CircularProgress';


// Login
const Redirect = lazy(() => import("./company/Redirect"));

const pages = () => (
    <ErrorBoundary>

        <Suspense fallback={
            <div style={{ "width": "100%", "height": "100%", "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                <CircularProgress disableShrink />
            </div>
        }>
            <Switch>
                <Route
                    exact
                    path="/"
                    component={() => (
                        <Redirect />
                    )}
                />
                {companyPages}
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    </ErrorBoundary>
);

export default pages;

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPLOAD_COMPANY_PROFILE_LOGO_REQUESTED:
        "UPLOAD_COMPANY_PROFILE_LOGO_REQUESTED",
    UPLOAD_COMPANY_PROFILE_LOGO_SUCCEEDED:
        "UPLOAD_COMPANY_PROFILE_LOGO_SUCCEEDED",
    UPLOAD_COMPANY_PROFILE_LOGO_FAILED: "UPLOAD_COMPANY_PROFILE_LOGO_FAILED",
    GET_COMPANY_PROFILE_LOGO_REQUESTED: "GET_COMPANY_PROFILE_LOGO_REQUESTED",
    GET_COMPANY_PROFILE_LOGO_SUCCEEDED: "GET_COMPANY_PROFILE_LOGO_SUCCEEDED",
    GET_COMPANY_PROFILE_LOGO_FAILED: "GET_COMPANY_PROFILE_LOGO_FAILED",
    DELETE_COMPANY_PROFILE_LOGO_REQUESTED:
        "DELETE_COMPANY_PROFILE_LOGO_REQUESTED",
    DELETE_COMPANY_PROFILE_LOGO_SUCCEEDED:
        "DELETE_COMPANY_PROFILE_LOGO_SUCCEEDED",
    DELETE_COMPANY_PROFILE_LOGO_FAILED: "DELETE_COMPANY_PROFILE_LOGO_FAILED"
};

// Action Creators
export const actions = {
    upload: (token, profileId, file, setProgress, callback) => {
        return {
            type: types.UPLOAD_COMPANY_PROFILE_LOGO_REQUESTED,
            token,
            profileId,
            file,
            setProgress,
            callback
        };
    },
    get: (token, profileId, callback) => {
        return {
            type: types.GET_COMPANY_PROFILE_LOGO_REQUESTED,
            token,
            profileId,
            callback
        };
    },
    delete: (token, callback) => {
        return {
            type: types.DELETE_COMPANY_PROFILE_LOGO_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_PROFILE_LOGO_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_PROFILE_LOGO_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_PROFILE_LOGO_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.UPLOAD_COMPANY_PROFILE_LOGO_REQUESTED, uploadWorker);
    yield takeLatest(types.GET_COMPANY_PROFILE_LOGO_REQUESTED, getWorker);
    yield takeLatest(types.DELETE_COMPANY_PROFILE_LOGO_REQUESTED, deleteWorker);
}

// Saga callback
function* uploadWorker({ token, profileId, file, setProgress, callback }) {
    try {
        const response = yield call(upload, {
            token,
            profileId,
            file,
            setProgress
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPLOAD_COMPANY_PROFILE_LOGO_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPLOAD_COMPANY_PROFILE_LOGO_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, profileId, callback }) {
    try {
        const response = yield call(get, { token, profileId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_PROFILE_LOGO_SUCCEEDED,
            payload: {
                data: response.data.data
            }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_PROFILE_LOGO_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ token, callback }) {
    try {
        const response = yield call(deleteFile, {
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_COMPANY_PROFILE_LOGO_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_COMPANY_PROFILE_LOGO_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function upload({ token, file, setProgress }) {
    const data = new FormData();

    data.append("image", file);
    data.append("_method", "put");

    return api.post(`/api/company/${token}/logo`, data, {
        onUploadProgress: (e) => {
            setProgress((e.loaded / e.total) * 100);
        }
    });
}

function get({ token }) {
    return api.get(`/api/company/${token}/logo`);
}

function deleteFile({ token }) {
    return api.delete(`/api/company/${token}/logo`);
}

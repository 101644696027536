import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SIGN_UP_USER_REQUESTED: "SIGN_UP_USER_REQUESTED",
    SIGN_UP_USER_SUCCEEDED: "SIGN_UP_USER_SUCCEEDED",
    SIGN_UP_USER_FAILED: "SIGN_UP_USER_FAILED",
    PASSWORDLESS_REQUEST_TOKEN_REQUESTED:
        "PASSWORDLESS_REQUEST_TOKEN_REQUESTED",
    PASSWORDLESS_REQUEST_TOKEN_SUCCEEDED:
        "PASSWORDLESS_REQUEST_TOKEN_SUCCEEDED",
    PASSWORDLESS_REQUEST_TOKEN_FAILED: "PASSWORDLESS_REQUEST_TOKEN_FAILED",
    PASSWORDLESS_SIGN_UP_USER_REQUESTED: "PASSWORDLESS_SIGN_UP_USER_REQUESTED",
    PASSWORDLESS_SIGN_UP_USER_SUCCEEDED: "PASSWORDLESS_SIGN_UP_USER_SUCCEEDED",
    PASSWORDLESS_SIGN_UP_USER_FAILED: "PASSWORDLESS_SIGN_UP_USER_FAILED",
    LOGIN_USER_REQUESTED: "LOGIN_USER_REQUESTED",
    LOGIN_USER_SUCCEEDED: "LOGIN_USER_SUCCEEDED",
    LOGIN_USER_FAILED: "LOGIN_USER_FAILED",
    PASSWORDLESS_LOGIN_USER_REQUESTED: "PASSWORDLESS_LOGIN_USER_REQUESTED",
    PASSWORDLESS_LOGIN_USER_SUCCEEDED: "PASSWORDLESS_LOGIN_USER_SUCCEEDED",
    PASSWORDLESS_LOGIN_USER_FAILED: "PASSWORDLESS_LOGIN_USER_FAILED",
    LOGOUT_USER_REQUESTED: "LOGOUT_USER_REQUESTED",
    LOGOUT_USER_SUCCEEDED: "LOGOUT_USER_SUCCEEDED",
    LOGOUT_USER_FAILED: "LOGOUT_USER_FAILED",
    SUBMIT_PASSWORD_REQUESTED: "SUBMIT_PASSWORD_REQUESTED",
    SUBMIT_PASSWORD_SUCCEEDED: "SUBMIT_PASSWORD_SUCCEEDED",
    SUBMIT_PASSWORD_FAILED: "SUBMIT_PASSWORD_FAILED",
    CONFIRM_REGISTARTION_REQUESTED: "CONFIRM_REGISTARTION_REQUESTED",
    CONFIRM_REGISTARTION_SUCCEEDED: "CONFIRM_REGISTARTION_SUCCEEDED",
    CONFIRM_REGISTARTION_FAILED: "CONFIRM_REGISTARTION_FAILED",
    GIVE_PERMISSION_REQUESTED: "GIVE_PERMISSION_REQUESTED",
    GIVE_PERMISSION_SUCCEEDED: "GIVE_PERMISSION_SUCCEEDED",
    GIVE_PERMISSION_FAILED: "GIVE_PERMISSION_FAILED",
    UNSUBSCRIBE_REQUESTED: "UNSUBSCRIBE_REQUESTED",
    UNSUBSCRIBE_SUCCEEDED: "UNSUBSCRIBE_SUCCEEDED",
    UNSUBSCRIBE_FAILED: "UNSUBSCRIBE_FAILED",
    FORGOT_PASSWORD_REQUESTED: "FORGOT_PASSWORD_REQUESTED",
    FORGOT_PASSWORD_SUCCEEDED: "FORGOT_PASSWORD_SUCCEEDED",
    FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
    RESET_PASSWORD_REQUESTED: "RESET_PASSWORD_REQUESTED",
    RESET_PASSWORD_SUCCEEDED: "RESET_PASSWORD_SUCCEEDED",
    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
    SIGN_IN_WITH_LINKEDIN_REQUESTED: "SIGN_IN_WITH_LINKEDIN_REQUESTED",
    SIGN_IN_WITH_LINKEDIN_SUCCEEDED: "SIGN_IN_WITH_LINKEDIN_SUCCEEDED",
    SIGN_IN_WITH_LINKEDIN_FAILED: "SIGN_IN_WITH_LINKEDIN_FAILED",
    SIGN_UP_WITH_LINKEDIN_REQUESTED: "SIGN_UP_WITH_LINKEDIN_REQUESTED",
    SIGN_UP_WITH_LINKEDIN_SUCCEEDED: "SIGN_UP_WITH_LINKEDIN_SUCCEEDED",
    SIGN_UP_WITH_LINKEDIN_FAILED: "SIGN_UP_WITH_LINKEDIN_FAILED",
    PASSWORDLESS_REGISTER_USER_REQUESTED:
        "PASSWORDLESS_REGISTER_USER_REQUESTED",
    PASSWORDLESS_REGISTER_USER_SUCCEEDED:
        "PASSWORDLESS_REGISTER_USER_SUCCEEDED",
    PASSWORDLESS_REGISTER_USER_FAILED: "PASSWORDLESS_REGISTER__USER_FAILED",
    LOGINV3_USER_REQUESTED: "LOGINV3_USER_REQUESTED",
    LOGINV3_USER_SUCCEEDED: "LOGINV3_USER_SUCCEEDED",
    LOGINV3_USER_FAILED: "LOGINV3_USER_FAILED"
};

// Action Creators
export const actions = {
    passwordlessRequestNewToken: (userData, callback) => {
        return {
            type: types.PASSWORDLESS_REQUEST_TOKEN_REQUESTED,
            userData,
            callback
        };
    },
    passwordlessLogin: (token, callback) => {
        return {
            type: types.PASSWORDLESS_LOGIN_USER_REQUESTED,
            token,
            callback
        };
    },
    login: (userData, callback) => {
        return {
            type: types.LOGIN_USER_REQUESTED,
            userData,
            callback
        };
    },
    loginV3: (callback) => {
        return {
            type: types.LOGINV3_USER_REQUESTED,
            callback
        };
    },
    passwordlessSignUp: (userData, callback) => {
        return {
            type: types.PASSWORDLESS_SIGN_UP_USER_REQUESTED,
            userData,
            callback
        };
    },
    passwordlessRegister: (userData, callback) => {
        return {
            type: types.PASSWORDLESS_REGISTER_USER_REQUESTED,
            userData,
            callback
        };
    },
    signUp: (userData, callback) => {
        return {
            type: types.SIGN_UP_USER_REQUESTED,
            userData,
            callback
        };
    },
    signInWithLinkedIn: (userData, callback) => {
        return {
            type: types.SIGN_IN_WITH_LINKEDIN_REQUESTED,
            userData,
            callback
        };
    },
    signUpWithLinkedIn: (userData, callback) => {
        return {
            type: types.SIGN_UP_WITH_LINKEDIN_REQUESTED,
            userData,
            callback
        };
    },
    logout: (callback) => {
        return {
            type: types.LOGOUT_USER_REQUESTED,
            callback
        };
    },
    clearAuthDataUser: (callback) => {
        return {
            type: types.LOGOUT_USER_SUCCEEDED,
            callback
        };
    },
    submitPassword: (password, hash, organizationId, callback) => {
        return {
            type: types.SUBMIT_PASSWORD_REQUESTED,
            password,
            hash,
            organizationId,
            callback
        };
    },
    verify: (magicLink, callback) => {
        return {
            type: types.CONFIRM_REGISTARTION_REQUESTED,
            magicLink,
            callback
        };
    },
    givePermission: (organizationId, hash, callback) => {
        return {
            type: types.GIVE_PERMISSION_REQUESTED,
            organizationId,
            hash,
            callback
        };
    },
    unsubscribe: (hash, callback) => {
        return {
            type: types.UNSUBSCRIBE_REQUESTED,
            hash,
            callback
        };
    },
    forgotPassword: (email, callback) => {
        return {
            type: types.FORGOT_PASSWORD_REQUESTED,
            email,
            callback
        };
    },
    resetPassword: (values, token, callback) => {
        return {
            type: types.RESET_PASSWORD_REQUESTED,
            values,
            token,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    data: {},
    error: "",
    message: "",
    loading: false,
    auth: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.FORGOT_PASSWORD_REQUESTED:
        case types.RESET_PASSWORD_REQUESTED:
        case types.SIGN_IN_WITH_LINKEDIN_REQUESTED:
        case types.SIGN_UP_WITH_LINKEDIN_REQUESTED:
        case types.GIVE_PERMISSION_REQUESTED:
        case types.UNSUBSCRIBE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GIVE_PERMISSION_SUCCEEDED:
        case types.UNSUBSCRIBE_SUCCEEDED:
            return {
                ...state,
                message: action.message,
                loading: false
            };
        case types.GIVE_PERMISSION_FAILED:
        case types.GUNSUBSCRIBE_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case types.LOGIN_USER_REQUESTED:
        case types.LOGINV3_USER_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.PASSWORDLESS_REGISTER_USER_REQUESTED:
        case types.SUBMIT_PASSWORD_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SIGN_UP_USER_SUCCEEDED:
            return {
                data: action.data.data.tokens,
                auth: true,
                error: "",
                loading: false
            };
        case types.SIGN_UP_USER_FAILED:
        case types.PASSWORDLESS_SIGN_UP_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.CONFIRM_REGISTARTION_SUCCEEDED:
        case types.PASSWORDLESS_LOGIN_USER_SUCCEEDED:
            return {
                data: {
                    token_type: "Bearer",
                    access_token: action.payload
                },
                auth: true,
                error: "",
                loading: false
            };
        case types.LOGIN_USER_SUCCEEDED:
            return {
                data: action.data.data.tokens,
                auth: true,
                error: "",
                loading: false
            };
        case types.LOGIN_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.LOGOUT_USER_SUCCEEDED:
            return {
                data: {},
                auth: false,
                error: "",
                loading: false
            };
        case types.LOGOUT_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.FORGOT_PASSWORD_SUCCEEDED:
        case types.FORGOT_PASSWORD_FAILED:
        case types.RESET_PASSWORD_SUCCEEDED:
        case types.RESET_PASSWORD_FAILED:
            return {
                ...state,
                loading: false
            };
        case types.SIGN_IN_WITH_LINKEDIN_SUCCEEDED:
            return {
                data: action.data.data.tokens,
                auth: true,
                error: "",
                loading: false
            };
        case types.SIGN_UP_WITH_LINKEDIN_SUCCEEDED:
            return {
                data: action.data.data.tokens,
                auth: true,
                error: "",
                loading: false
            };
        case types.SIGN_IN_WITH_LINKEDIN_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.SIGN_UP_WITH_LINKEDIN_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.PASSWORDLESS_REGISTER_USER_SUCCEEDED:
            return {
                data: {
                    token_type: "Bearer",
                    access_token: action.payload.accessToken
                },
                auth: true,
                error: "",
                loading: false
            };
        case types.PASSWORDLESS_REGISTER_USER_FAILED:
        case types.LOGINV3_USER_SUCCEEDED:
            return {
                data: {
                    token_type: "Bearer",
                    access_token: action.payload
                },
                auth: true,
                error: "",
                loading: false
            };
        case types.LOGINV3_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SIGN_UP_USER_REQUESTED, startSignUpWorker);
    yield takeLatest(types.LOGIN_USER_REQUESTED, startLoginWorker);
    yield takeLatest(
        types.PASSWORDLESS_LOGIN_USER_REQUESTED,
        startPasswordlessLoginWorker
    );
    yield takeLatest(types.LOGOUT_USER_REQUESTED, startLogoutWorker);
    yield takeLatest(
        types.CONFIRM_REGISTARTION_REQUESTED,
        startConfirmRegistrationWorker
    );
    yield takeLatest(
        types.SUBMIT_PASSWORD_REQUESTED,
        startSubmitPasswordWorker
    );
    yield takeLatest(
        types.GIVE_PERMISSION_REQUESTED,
        startGivePermissionWorker
    );
    yield takeLatest(types.UNSUBSCRIBE_REQUESTED, startUnsubscribeWorker);
    yield takeLatest(
        types.FORGOT_PASSWORD_REQUESTED,
        startForgotPasswordWorker
    );
    yield takeLatest(types.RESET_PASSWORD_REQUESTED, startResetPasswordWorker);
    yield takeLatest(
        types.PASSWORDLESS_SIGN_UP_USER_REQUESTED,
        startPasswordlessSignUpWorker
    );
    yield takeLatest(
        types.PASSWORDLESS_REQUEST_TOKEN_REQUESTED,
        startPasswordlessRequestNewToken
    );
    yield takeLatest(
        types.SIGN_IN_WITH_LINKEDIN_REQUESTED,
        startSignInWithLinkedInWorker
    );
    yield takeLatest(
        types.SIGN_UP_WITH_LINKEDIN_REQUESTED,
        startSignUpWithLinkedInWorker
    );
    yield takeLatest(
        types.PASSWORDLESS_REGISTER_USER_REQUESTED,
        startPasswordlessRegisterWorker
    );
    yield takeLatest(types.LOGINV3_USER_REQUESTED, startLoginV3Worker);
}

// Saga callback
function* startSignUpWorker({ userData, callback }) {
    try {
        const response = yield call(signUp, { userData });
        if (!response.ok) {
            throw response.data;
        }

        // yield put({
        //     type: types.SIGN_UP_USER_SUCCEEDED,
        //     data: response.data
        // });

        // callSuccess(callback, response.data);
        const userCredentials = response.data.data.tokens;
        api.setHeader(
            "Authorization",
            `${userCredentials.token_type} ${userCredentials.access_token}`
        );

        yield put({
            type: types.SIGN_UP_USER_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.SIGN_UP_USER_FAILED, payload: e.errors });

        callFail(callback, e.errors);
    }
}

function* startPasswordlessSignUpWorker({ userData, callback }) {
    try {
        const response = yield call(passwordlessSignUp, { userData });
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SIGN_UP_USER_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.SIGN_UP_USER_FAILED, payload: e.errors });

        callFail(callback, e.errors);
    }
}

function* startPasswordlessRequestNewToken({ userData, callback }) {
    try {
        const response = yield call(passwordlessRequestNewToken, { userData });
        if (!response.ok) {
            throw response.data;
        }

        callSuccess(callback, response.data);
    } catch (e) {
        callFail(callback, e.errors);
    }
}

function* startConfirmRegistrationWorker({ magicLink, callback }) {
    try {
        const response = yield call(confirmRegistration, { magicLink });

        if (!response.ok) {
            throw response.data;
        }

        const token = response.data.token;
        api.setHeader("Authorization", `Bearer ${token}`);

        yield put({
            type: types.CONFIRM_REGISTARTION_SUCCEEDED,
            payload: response.data.token
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        typeof callback == "function" && callback(e);
        yield put({
            type: types.CONFIRM_REGISTARTION_FAILED,
            payload: e.error
        });
    }
}

function* startPasswordlessLoginWorker({ token, callback }) {
    try {
        const response = yield call(passwordlessLogin, { token });

        if (!response.ok) {
            throw response.data;
        }

        const accessToken = response.data.accessToken;
        api.setHeader("Authorization", `Bearer ${accessToken}`);

        yield put({
            type: types.PASSWORDLESS_LOGIN_USER_SUCCEEDED,
            payload: response.data.accessToken
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        typeof callback == "function" && callback(e);
        yield put({
            type: types.PASSWORDLESS_LOGIN_USER_FAILED,
            payload: e.error
        });
    }
}

function* startLoginWorker({ userData, callback }) {
    try {
        const response = yield call(loginUser, { userData });

        if (!response.ok) {
            throw response.data;
        }

        const userCredentials = response.data.data.tokens;
        api.setHeader(
            "Authorization",
            `${userCredentials.token_type} ${userCredentials.access_token}`
        );

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.LOGIN_USER_FAILED, payload: e.errors });

        callFail(callback, e.errors);
    }
}

function* startLogoutWorker({ callback }) {
    try {
        const response = yield call(logoutUser);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LOGOUT_USER_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.LOGIN_USER_FAILED, payload: e.error });
    }
}

function* startSubmitPasswordWorker({
    password,
    hash,
    organizationId,
    callback
}) {
    try {
        const response = yield call(submitPassword, {
            password,
            hash,
            organizationId
        });

        if (!response.ok) {
            throw response.data;
        }

        const userCredentials = response.data.data.tokens;
        api.setHeader(
            "Authorization",
            `${userCredentials.token_type} ${userCredentials.access_token}`
        );

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.LOGIN_USER_FAILED, payload: e.error });
    }
}

function* startGivePermissionWorker({ organizationId, hash, callback }) {
    try {
        const response = yield call(givePermission, { organizationId, hash });

        if (!response.ok) {
            throw response.data;
        }

        const userCredentials = response.data.data.tokens;
        api.setHeader(
            "Authorization",
            `${userCredentials.token_type} ${userCredentials.access_token}`
        );

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GIVE_PERMISSION_FAILED, error: e.error });
    }
}

function* startUnsubscribeWorker({ hash, callback }) {
    try {
        const response = yield call(unsubscribe, { hash });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UNSUBSCRIBE_SUCCEEDED,
            message: response.data.message
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.UNSUBSCRIBE_FAILED, error: e.error });
    }
}

function* startForgotPasswordWorker({ email, callback }) {
    try {
        const response = yield call(forgotPassword, { email });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.FORGOT_PASSWORD_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(email);
    } catch (e) {
        yield put({ type: types.FORGOT_PASSWORD_FAILED, payload: e.errors });
        callback(false, e.errors);
    }
}

function* startResetPasswordWorker({ values, token, callback }) {
    try {
        const response = yield call(resetPassword, { values, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.RESET_PASSWORD_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.RESET_PASSWORD_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* startSignInWithLinkedInWorker({ userData, callback }) {
    try {
        const response = yield call(signInWithLinkedIn, { userData });

        if (!response.ok) {
            throw response.data;
        }

        const userCredentials = response.data.data.tokens;
        api.setHeader(
            "Authorization",
            `${userCredentials.token_type} ${userCredentials.access_token}`
        );

        yield put({
            type: types.SIGN_IN_WITH_LINKEDIN_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SIGN_IN_WITH_LINKEDIN_FAILED,
            payload: e.errors
        });

        callFail(callback, e.errors);
    }
}

function* startSignUpWithLinkedInWorker({ userData, callback }) {
    try {
        const response = yield call(signUpWithLinkedIn, { userData });
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SIGN_UP_WITH_LINKEDIN_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SIGN_UP_WITH_LINKEDIN_FAILED,
            payload: e.errors
        });

        callFail(callback, e.errors);
    }
}

function* startPasswordlessRegisterWorker({ userData, callback }) {
    try {
        const response = yield call(passwordLessRegister, { userData });
        if (!response.ok) {
            throw response.data;
        }

        const accessToken = response.data.accessToken;
        api.setHeader("Authorization", `Bearer ${accessToken}`);

        yield put({
            type: types.PASSWORDLESS_REGISTER_USER_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        typeof callback == "function" && callback(e);
        yield put({
            type: types.PASSWORDLESS_REGISTER_USER_FAILED,
            payload: e.error
        });
    }
}

// Saga callback
function* startLoginV3Worker({ callback }) {
    try {
        const response = yield call(getLoginV3);
        if (!response.ok) {
            throw response.data;
        }

        const accessToken = response.data.accessToken;
        api.setHeader("Authorization", `Bearer ${accessToken}`);

        yield put({
            type: types.LOGINV3_USER_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        typeof callback == "function" && callback(e);
        yield put({
            type: types.LOGINV3_USER_FAILED,
            payload: e.error
        });
    }
}

// API call
function signUp({ userData }) {
    return api.post("/api/register-user", { ...userData });
}

function passwordlessSignUp({ userData }) {
    return api.post("/api/register-magic-link", { ...userData });
}

function passwordlessRequestNewToken({ userData }) {
    return api.get(`/api/login-magic-link/${userData.email}`);
}

function loginUser({ userData }) {
    return api.post("/api/login", {
        email: userData.email,
        password: userData.password
    });
}

function logoutUser() {
    return api.post("/api/user/logout");
}

function submitPassword({ password, hash, organizationId }) {
    return api.post("/api/user/confirm-registration", {
        password,
        users_encrypted_email: hash,
        organization_id: organizationId
    });
}

function confirmRegistration({ magicLink }) {
    return api.post(`/api/user/confirm-registration`, {
        verification_code: magicLink
    });
}

function passwordlessLogin({ token }) {
    return api.post(`/api/login-magic-link/${token}`);
}

function givePermission({ organizationId, hash }) {
    return api.post(`/api/user/give-permission-to-organization`, {
        organizationId,
        token: hash
    });
}

function unsubscribe({ hash }) {
    return api.post(`/api/user/unsubscribe`, {
        users_encrypted_email: hash
    });
}

function forgotPassword({ email }) {
    return api.post("/api/user/password/send-reset-email", {
        email
    });
}

function resetPassword({ values, token }) {
    return api.post("/api/user/password/reset", {
        ...values,
        reset_token: token
    });
}

function signInWithLinkedIn({ userData }) {
    return api.post("/api/signin_with_linkedin", {
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name
    });
}

function signUpWithLinkedIn({ userData }) {
    return api.post("/api/register_with_linkedin", { ...userData });
}

function passwordLessRegister({ userData }) {
    return api.post("/api/password-less-register", { ...userData });
}

function getLoginV3() {
    return api.get(`/api/login-v3`);
}

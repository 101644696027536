import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    LIST_COMPANY_TALENTS_REQUESTED: "LIST_COMPANY_TALENTS_REQUESTED",
    LIST_COMPANY_TALENTS_SUCCEEDED: "LIST_COMPANY_TALENTS_SUCCEEDED",
    LIST_COMPANY_TALENTS_FAILED: "LIST_COMPANY_TALENTS_FAILED",

    INVITE_TO_CIRCLE_REQUESTED: "INVITE_TO_CIRCLE_REQUESTED",
    INVITE_TO_CIRCLE_SUCCEEDED: "INVITE_TO_CIRCLE_SUCCEEDED",
    INVITE_TO_CIRCLE_FAILED: "INVITE_TO_CIRCLE_FAILED",

    REMOVE_FROM_CIRCLE_REQUESTED: "REMOVE_FROM_CIRCLE_REQUESTED",
    REMOVE_FROM_CIRCLE_SUCCEEDED: "REMOVE_FROM_CIRCLE_SUCCEEDED",
    REMOVE_FROM_CIRCLE_FAILED: "REMOVE_FROM_CIRCLE_FAILED",

    SEND_JOB_TO_TALENT_REQUESTED: "SEND_JOB_TO_TALENT_REQUESTED",
    SEND_JOB_TO_TALENT_SUCCEEDED: "SEND_JOB_TO_TALENT_SUCCEEDED",
    SEND_JOB_TO_TALENT_FAILED: "SEND_JOB_TO_TALENT_FAILED",

    PROFILE_VIEW_TALENTS_REQUESTED: "PROFILE_VIEW_TALENTS_REQUESTED",
    PROFILE_VIEW_TALENTS_SUCCEEDED: "PROFILE_VIEW_TALENTS_SUCCEEDED",
    PROFILE_VIEW_TALENTS_FAILED: "PROFILE_VIEW_TALENTS_FAILED"
};

// Action Creators
export const actions = {
    list: (token, circle_id, page, limit, params, callback) => {
        return {
            type: types.LIST_COMPANY_TALENTS_REQUESTED,
            token,
            circle_id,
            page,
            limit,
            params,
            callback
        };
    },
    inviteToCircle: (token, circle_id, user_id, callback) => {
        return {
            type: types.INVITE_TO_CIRCLE_REQUESTED,
            token,
            circle_id,
            user_id,
            callback
        };
    },
    removeFromCircle: (token, circle_id, user_id, callback) => {
        return {
            type: types.REMOVE_FROM_CIRCLE_REQUESTED,
            token,
            circle_id,
            user_id,
            callback
        };
    },
    sendJob: (token, formdata, callback) => {
        return {
            type: types.SEND_JOB_TO_TALENT_REQUESTED,
            token,
            formdata,
            callback
        };
    },
    profileView: (token, talent_id, circle_id, callback) => {
        return {
            type: types.PROFILE_VIEW_TALENTS_REQUESTED,
            token,
            talent_id,
            circle_id,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.LIST_COMPANY_TALENTS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.INVITE_TO_CIRCLE_REQUESTED:
        case types.REMOVE_FROM_CIRCLE_REQUESTED:
        case types.PROFILE_VIEW_TALENTS_REQUESTED:
            return {
                ...state,
                loading: false
            };
        case types.LIST_COMPANY_TALENTS_SUCCEEDED:
        case types.INVITE_TO_CIRCLE_SUCCEEDED:
        case types.REMOVE_FROM_CIRCLE_SUCCEEDED:
        case types.SEND_JOB_TO_TALENT_SUCCEEDED:
        case types.PROFILE_VIEW_TALENTS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.LIST_COMPANY_TALENTS_FAILED:
        case types.INVITE_TO_CIRCLE_FAILED:
        case types.REMOVE_FROM_CIRCLE_FAILED:
        case types.SEND_JOB_TO_TALENT_FAILED:
        case types.PROFILE_VIEW_TALENTS_FAILED:
            return {
                ...state,
                data: [],
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.LIST_COMPANY_TALENTS_REQUESTED, listWorker);
    yield takeLatest(types.INVITE_TO_CIRCLE_REQUESTED, inviteToCircleWorker);
    yield takeLatest(types.REMOVE_FROM_CIRCLE_REQUESTED, removeFromCircleWorker);
    yield takeLatest(types.SEND_JOB_TO_TALENT_REQUESTED, sendJobWorker);
    yield takeLatest(types.PROFILE_VIEW_TALENTS_REQUESTED, profileViewWorker);
}

// Saga callback
function* listWorker({ token, circle_id, page, limit, params, callback }) {
    try {
        const response = yield call(list, { token, circle_id, page, limit, params });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.LIST_COMPANY_TALENTS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.LIST_COMPANY_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function list({ token, circle_id, page, limit, params }) {
    let query = '?';
    for (let p in params) {
        if (params[p]) {
            query += p + "=" + params[p] + "&";
        }
    }
    return api.get(`/api/company/${token}/matches/by-target-profile/${circle_id}/paginated/${page}/${limit}/total${query}`);
}

//-----------------

function* inviteToCircleWorker({ token, circle_id, user_id, callback }) {
    try {
        const response = yield call(inviteToCircle, { token, circle_id, user_id });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.INVITE_TO_CIRCLE_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.INVITE_TO_CIRCLE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function inviteToCircle({ token, circle_id, user_id }) {
    return api.put(`/api/company/${token}/target-profile/${circle_id}/user/${user_id}/invite-to-tribe`);
}


//-------------------------------------

function* removeFromCircleWorker({ token, circle_id, user_id, callback }) {
    try {
        const response = yield call(removeFromCircle, { token, circle_id, user_id });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.REMOVE_FROM_CIRCLE_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.REMOVE_FROM_CIRCLE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function removeFromCircle({ token, circle_id, user_id }) {
    return api.put(`/api/company/${token}/target-profile/${circle_id}/user/${user_id}/not-interested`);
}

//-----------------

function* sendJobWorker({ token, formdata, callback }) {
    try {
        const response = yield call(sendJob, { token, formdata });
        // console.log("ok => ", response);
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.SEND_JOB_TO_TALENT_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SEND_JOB_TO_TALENT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function sendJob({ token, formdata }) {
    return api.post(`/api/company/job-list/${token}/send-job`, formdata);
}

//------------------
function* profileViewWorker({ token, talent_id, circle_id, callback }) {
    try {
        const response = yield call(profileView, { token, talent_id, circle_id });
        if (!response.ok) { throw response.data; }
        yield put({
            type: types.PROFILE_VIEW_TALENTS_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.PROFILE_VIEW_TALENTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function profileView({ token, talent_id, circle_id }) {
    return api.get(`/api/company/profile-view/${token}/${talent_id}/${circle_id}`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";
import findIndex from "lodash/findIndex";

// Actions
const types = {
    SAVE_COMPANY_GENERAL_BLOG_REQUESTED: "SAVE_COMPANY_GENERAL_BLOG_REQUESTED",
    SAVE_COMPANY_GENERAL_BLOG_SUCCEEDED: "SAVE_COMPANY_GENERAL_BLOG_SUCCEEDED",
    SAVE_COMPANY_GENERAL_BLOG_FAILED: "SAVE_COMPANY_GENERAL_BLOG_FAILED",

    UPDATE_COMPANY_GENERAL_BLOG_REQUESTED: "UPDATE_COMPANY_GENERAL_BLOG_REQUESTED",
    UPDATE_COMPANY_GENERAL_BLOG_SUCCEEDED: "UPDATE_COMPANY_GENERAL_BLOG_SUCCEEDED",
    UPDATE_COMPANY_GENERAL_BLOG_FAILED: "UPDATE_COMPANY_GENERAL_BLOG_FAILED",

    GET_COMPANY_GENERAL_BLOG_REQUESTED: "GET_COMPANY_GENERAL_BLOG_REQUESTED",
    GET_COMPANY_GENERAL_BLOG_SUCCEEDED: "GET_COMPANY_GENERAL_BLOG_SUCCEEDED",
    GET_COMPANY_GENERAL_BLOG_FAILED: "GET_COMPANY_GENERAL_BLOG_FAILED",

    GET_COMPANY_GENERAL_BLOG_ALL_REQUESTED: "GET_COMPANY_GENERAL_BLOG_ALL_REQUESTED",
    GET_COMPANY_GENERAL_BLOG_ALL_SUCCEEDED: "GET_COMPANY_GENERAL_BLOG_ALL_SUCCEEDED",
    GET_COMPANY_GENERAL_BLOG_ALL_FAILED: "GET_COMPANY_GENERAL_BLOG_ALL_FAILED",

    UPDATE_COMPANY_GENERAL_BLOG_STATUS_REQUESTED: "UPDATE_COMPANY_GENERAL_BLOG_STATUS_REQUESTED",
    UPDATE_COMPANY_GENERAL_BLOG_STATUS_SUCCEEDED: "UPDATE_COMPANY_GENERAL_BLOG_STATUS_SUCCEEDED",
    UPDATE_COMPANY_GENERAL_BLOG_STATUS_FAILED: "UPDATE_COMPANY_GENERAL_BLOG_STATUS_FAILED",

    DELETE_COMPANY_GENERAL_BLOG_REQUESTED: "DELETE_COMPANY_GENERAL_BLOG_REQUESTED",
    DELETE_COMPANY_GENERAL_BLOG_SUCCEEDED: "DELETE_COMPANY_GENERAL_BLOG_SUCCEEDED",
    DELETE_COMPANY_GENERAL_BLOG_FAILED: "DELETE_COMPANY_GENERAL_BLOG_FAILED"
};

// Action Creators
export const actions = {
    save: (data, token, callback) => {
        return {
            type: types.SAVE_COMPANY_GENERAL_BLOG_REQUESTED,
            data,
            token,
            callback
        };
    },
    update: (data, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_GENERAL_BLOG_REQUESTED,
            data,
            token,
            callback
        };
    },
    delete: (itemId, token, callback) => {
        return {
            type: types.DELETE_COMPANY_GENERAL_BLOG_REQUESTED,
            itemId,
            token,
            callback
        };
    },
    updateStatus: (itemId, status, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_GENERAL_BLOG_STATUS_REQUESTED,
            itemId,
            status,
            token,
            callback
        };
    },
    get: (token, blogId, callback) => {
        return {
            type: types.GET_COMPANY_GENERAL_BLOG_REQUESTED,
            token,
            blogId,
            callback
        };
    },
    getAll: (token, callback) => {
        return {
            type: types.GET_COMPANY_GENERAL_BLOG_ALL_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    single: {},
    error: "",
    loading: false,
    singleLoading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_GENERAL_BLOG_ALL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.UPDATE_COMPANY_GENERAL_BLOG_REQUESTED:
        case types.SAVE_COMPANY_GENERAL_BLOG_REQUESTED:
        case types.GET_COMPANY_GENERAL_BLOG_REQUESTED:
            return {
                ...state,
                singleLoading: true
            };
        case types.SAVE_COMPANY_GENERAL_BLOG_SUCCEEDED:
            return {
                ...state,
                data: [...state.data, action.payload.data],
                error: "",
                singleLoading: false
            };
        case types.UPDATE_COMPANY_GENERAL_BLOG_SUCCEEDED:
            const INDEX = findIndex(state.data, {
                id: action.payload.data.id
            });
            let data = [...state.data];
            data.splice(INDEX, 1, {
                ...data[INDEX],
                name: action.payload.data.name
            });

            return {
                ...state,
                data: data,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.GET_COMPANY_GENERAL_BLOG_ALL_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_GENERAL_BLOG_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.SAVE_COMPANY_GENERAL_BLOG_FAILED:
        case types.UPDATE_COMPANY_GENERAL_BLOG_FAILED:
        case types.GET_COMPANY_GENERAL_BLOG_ALL_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_GENERAL_BLOG_FAILED:
            return {
                ...state,
                singleLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_COMPANY_GENERAL_BLOG_REQUESTED, saveWorker);
    yield takeLatest(types.UPDATE_COMPANY_GENERAL_BLOG_REQUESTED, updateWorker);
    yield takeLatest(types.UPDATE_COMPANY_GENERAL_BLOG_STATUS_REQUESTED, updateStatusWorker);
    yield takeLatest(types.GET_COMPANY_GENERAL_BLOG_REQUESTED, singleWorker);
    yield takeLatest(types.GET_COMPANY_GENERAL_BLOG_ALL_REQUESTED, allWorker);
    yield takeLatest(types.DELETE_COMPANY_GENERAL_BLOG_REQUESTED, deleteWorker);
}

// Saga callback
function* saveWorker({ data, token, callback }) {
    try {
        const response = yield call(save, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_GENERAL_BLOG_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_GENERAL_BLOG_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateWorker({ data, token, callback }) {
    try {
        const response = yield call(update, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_GENERAL_BLOG_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_GENERAL_BLOG_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ itemId, token, callback }) {
    try {
        const response = yield call(remove, { itemId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_COMPANY_GENERAL_BLOG_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_COMPANY_GENERAL_BLOG_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateStatusWorker({ itemId, status, token, callback }) {
    try {
        const response = yield call(updateStatus, { itemId, status, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_GENERAL_BLOG_STATUS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_GENERAL_BLOG_STATUS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* singleWorker({ token, blogId, callback }) {
    try {
        const response = yield call(single, { blogId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_GENERAL_BLOG_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_GENERAL_BLOG_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* allWorker({ token, callback }) {
    try {
        const response = yield call(all, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_GENERAL_BLOG_ALL_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_GENERAL_BLOG_ALL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data, token }) {
    return api.post(`/api/blog/${token}/blog-post`, data);
}

function update({ data, token }) {
    return api.post(`/api/blog/${token}/blog-post`, data);
}

function updateStatus({ status, itemId, token }) {
    return api.put(`/api/blog/${token}/blog-post/${itemId}/status/${status}`);
}

function remove({ itemId, token }) {
    return api.delete(`/api/blog/${token}/blog-post/${itemId}`);
}

function single({ blogId, token }) {
    return api.get(`/api/blog/${token}/blog-post/${blogId}`);
}

function all({ token }) {
    return api.get(`/api/blog/${token}/blog-post/light`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_CIRCLE_SETTING_REQUESTED: "SAVE_CIRCLE_SETTING_REQUESTED",
    SAVE_CIRCLE_SETTING_SUCCEEDED: "SAVE_CIRCLE_SETTING_SUCCEEDED",
    SAVE_CIRCLE_SETTING_FAILED: "SAVE_CIRCLE_SETTING_FAILED",
    GET_CIRCLE_SETTINGS_REQUESTED: "GET_CIRCLE_SETTINGS_REQUESTED",
    GET_CIRCLE_SETTINGS_SUCCEEDED: "GET_CIRCLE_SETTINGS_SUCCEEDED",
    GET_CIRCLE_SETTINGS_FAILED: "GET_CIRCLE_SETTINGS_FAILED"
};

// Action Creators
export const actions = {
    save: (data, token, callback) => {
        return {
            type: types.SAVE_CIRCLE_SETTING_REQUESTED,
            data,
            token,
            callback
        };
    },
    get: (token, callback) => {
        return {
            type: types.GET_CIRCLE_SETTINGS_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_CIRCLE_SETTINGS_REQUESTED:
        case types.SAVE_CIRCLE_SETTING_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_CIRCLE_SETTINGS_SUCCEEDED:
        case types.SAVE_CIRCLE_SETTING_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.SAVE_CIRCLE_SETTING_FAILED:
        case types.GET_CIRCLE_SETTINGS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_CIRCLE_SETTING_REQUESTED, saveWorker);
    yield takeLatest(types.GET_CIRCLE_SETTINGS_REQUESTED, getWorker);
}

// Saga callback
function* saveWorker({ data, token, callback }) {
    try {
        const response = yield call(save, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_CIRCLE_SETTING_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_CIRCLE_SETTING_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CIRCLE_SETTINGS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_CIRCLE_SETTINGS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data, token }) {
    return api.put(`/api/settings/${token}`, data);
}

function get({ token }) {
    return api.get(`/api/settings/${token}`);
}

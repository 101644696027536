import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    COMPANY_SIGNUP_REQUESTED: "COMPANY_SIGNUP_REQUESTED",
    COMPANY_SIGNUP_SUCCEEDED: "COMPANY_SIGNUP_SUCCEEDED",
    COMPANY_SIGNUP_FAILED: "COMPANY_SIGNUP_FAILED",

    COMPANY_SIGNUPWITHSOCIAL_REQUESTED: "COMPANY_SIGNUPWITHSOCIAL_REQUESTED",
    COMPANY_SIGNUPWITHSOCIAL_SUCCEEDED: "COMPANY_SIGNUPWITHSOCIAL_SUCCEEDED",
    COMPANY_SIGNUPWITHSOCIAL_FAILED: "COMPANY_SIGNUPWITHSOCIAL_FAILED"
};

// Action Creators
export const actions = {
    signUp: (email, callback) => {
        return {
            type: types.COMPANY_SIGNUP_REQUESTED,
            email,
            callback
        };
    },
    signUpWithSocial: (email, callback) => {
        return {
            type: types.COMPANY_SIGNUPWITHSOCIAL_REQUESTED,
            email,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: null,
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.COMPANY_SIGNUP_REQUESTED:
        case types.COMPANY_SIGNUPWITHSOCIAL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.COMPANY_SIGNUP_SUCCEEDED:
        case types.COMPANY_SIGNUPWITHSOCIAL_SUCCEEDED:    
            return {
                ...state,
                data: action.payload.success,
                error: "",
                loading: false
            };
        case types.COMPANY_SIGNUP_FAILED:
        case types.COMPANY_SIGNUPWITHSOCIAL_FAILED:
            return {
                ...state,
                data: false,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.COMPANY_SIGNUP_REQUESTED, signUpWorker);
    yield takeLatest(types.COMPANY_SIGNUPWITHSOCIAL_REQUESTED, signUpWithSocialWorker);
}

// Saga callback
function* signUpWorker({ email, callback }) {
    try {
        const response = yield call(signUp, { email });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.COMPANY_SIGNUP_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.COMPANY_SIGNUP_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// Saga callback
function* signUpWithSocialWorker({ email, callback }) {
    try {
        const response = yield call(signUpWithSocial, { email });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.COMPANY_SIGNUP_SUCCEEDED,
            payload: response.data
        });
        callback(response.data, "");
    } catch (e) {
        yield put({
            type: types.COMPANY_SIGNUP_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function signUp({ email }) {
    return api.post(`/api/register-new-company`, { email });
}

// Social API call
function signUpWithSocial({ email }) {
    return api.post(`/api/register-new-company-with-social`, { email });
}

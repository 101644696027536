import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_REQUESTED:
        "GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_REQUESTED",
    GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_SUCCEEDED:
        "GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_SUCCEEDED",
    GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_FAILED:
        "GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_FAILED",
    GET_COMPANY_COMPARISON_CULTURE_RESULTS_REQUESTED:
        "GET_COMPANY_COMPARISON_CULTURE_RESULTS_REQUESTED",
    GET_COMPANY_COMPARISON_CULTURE_RESULTS_SUCCEEDED:
        "GET_COMPANY_COMPARISON_CULTURE_RESULTS_SUCCEEDED",
    GET_COMPANY_COMPARISON_CULTURE_RESULTS_FAILED:
        "GET_COMPANY_COMPARISON_CULTURE_RESULTS_FAILED",
    GET_COMPANY_COMPARISON_CULTURE_TEXTS_REQUESTED:
        "GET_COMPANY_COMPARISON_CULTURE_TEXTS_REQUESTED",
    GET_COMPANY_COMPARISON_CULTURE_TEXTS_SUCCEEDED:
        "GET_COMPANY_COMPARISON_CULTURE_TEXTS_SUCCEEDED",
    GET_COMPANY_COMPARISON_CULTURE_TEXTS_FAILED:
        "GET_COMPANY_COMPARISON_CULTURE_TEXTS_FAILED",
    GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_REQUESTED:
        "GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_REQUESTED",
    GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_SUCCEEDED:
        "GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_SUCCEEDED",
    GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_FAILED:
        "GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_FAILED"
};

// Action Creators
export const actions = {
    getImportance: (token, callback) => {
        return {
            type: types.GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_REQUESTED,
            token,
            callback
        };
    },
    getResults: (token, callback) => {
        return {
            type: types.GET_COMPANY_COMPARISON_CULTURE_RESULTS_REQUESTED,
            token,
            callback
        };
    },
    getTexts: (token, callback) => {
        return {
            type: types.GET_COMPANY_COMPARISON_CULTURE_TEXTS_REQUESTED,
            token,
            callback
        };
    },
    getOthersCategory: (token, categoryId, callback) => {
        return {
            type:
                types.GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_REQUESTED,
            token,
            categoryId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    texts: [],
    importance: {},
    others: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_REQUESTED:
        case types.GET_COMPANY_COMPARISON_CULTURE_RESULTS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_SUCCEEDED:
            return {
                ...state,
                importance: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_COMPARISON_CULTURE_RESULTS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_COMPARISON_CULTURE_TEXTS_SUCCEEDED:
            return {
                ...state,
                texts: action.payload.data
            };
        case types.GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_SUCCEEDED:
            return {
                ...state,
                others: {
                    ...state.others,
                    [action.categoryId]: action.payload.data
                }
            };
        case types.GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_FAILED:
        case types.GET_COMPANY_COMPARISON_CULTURE_RESULTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_REQUESTED,
        getImportanceWorker
    );
    yield takeLatest(
        types.GET_COMPANY_COMPARISON_CULTURE_RESULTS_REQUESTED,
        getResultsWorker
    );
    yield takeEvery(
        types.GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_REQUESTED,
        getOthersCategoryWorker
    );
    yield takeEvery(
        types.GET_COMPANY_COMPARISON_CULTURE_TEXTS_REQUESTED,
        getTextsWorker
    );
}

// Saga callback
function* getImportanceWorker({ token, callback }) {
    try {
        const response = yield call(getImportance, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_COMPARISON_CULTURE_IMPORTANCE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getResultsWorker({ token, callback }) {
    try {
        const response = yield call(getResults, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_COMPARISON_CULTURE_RESULTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_COMPARISON_CULTURE_RESULTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getOthersCategoryWorker({ token, categoryId, callback }) {
    try {
        const response = yield call(getOthersCategory, { token, categoryId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type:
                types.GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_SUCCEEDED,
            payload: response.data,
            categoryId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_COMPARISON_CULTURE_OTHERS_CATEGORY_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getTextsWorker({ token, callback }) {
    try {
        const response = yield call(getTexts, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_COMPARISON_CULTURE_TEXTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_COMPARISON_CULTURE_TEXTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getImportance({ token }) {
    return api.get(`/api/company/${token}/culture-category-importance`);
}

function getTexts({ token }) {
    return api.get(`/api/company/${token}/culture/texts`);
}

function getResults({ token }) {
    return api.get(`/api/company/${token}/culture/results`);
}

function getOthersCategory({ token, categoryId }) {
    return api.get(
        `/api/company/${token}/culture/category/${categoryId}/average/results`
    );
}

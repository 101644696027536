import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";
import findIndex from "lodash/findIndex";

// Actions
const types = {
    GET_COMPANY_STEPS_REQUESTED: "GET_COMPANY_STEPS_REQUESTED",
    GET_COMPANY_STEPS_SUCCEEDED: "GET_COMPANY_STEPS_SUCCEEDED",
    GET_COMPANY_STEPS_FAILED: "GET_COMPANY_STEPS_FAILED",
    GET_COMPANY_TOGGLE_STEP_REQUESTED: "GET_COMPANY_TOGGLE_STEP_REQUESTED",
    GET_COMPANY_TOGGLE_STEP_SUCCEEDED: "GET_COMPANY_TOGGLE_STEP_SUCCEEDED",
    GET_COMPANY_TOGGLE_STEP_FAILED: "GET_COMPANY_TOGGLE_STEP_FAILED"
};

// Action Creators
export const actions = {
    get: (token, callback) => {
        return {
            type: types.GET_COMPANY_STEPS_REQUESTED,
            token,
            callback
        };
    },
    toggle: (id, value, token, callback) => {
        return {
            type: types.GET_COMPANY_TOGGLE_STEP_REQUESTED,
            id,
            value,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_STEPS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_STEPS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_STEPS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_TOGGLE_STEP_SUCCEEDED:
            const INDEX = findIndex(state.data, {
                id: action.payload.id
            });
            let data = [...state.data];
            data.splice(INDEX, 1, {
                ...data[INDEX],
                pivot: {
                    ...data[INDEX].pivot,
                    complete: action.payload.value ? 1 : 0
                }
            });

            return {
                ...state,
                data: data,
                error: "",
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_STEPS_REQUESTED, getWorker);
    yield takeLatest(types.GET_COMPANY_TOGGLE_STEP_REQUESTED, toggleWorker);
}

// Saga callback
function* getWorker({ token, callback }) {
    try {
        const response = yield call(all, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_STEPS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_STEPS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* toggleWorker({ id, value, token, callback }) {
    try {
        const response = yield call(toggle, { id, value, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_TOGGLE_STEP_SUCCEEDED,
            payload: { id, value }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_TOGGLE_STEP_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function all({ token }) {
    return api.get(`/api/company/${token}/profile-steps`);
}

function toggle({ id, value, token }) {
    return api.put(`/api/company/${token}/profile-steps`, {
        step_id: id,
        complete: value ? 1 : 0
    });
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_NOTIFICATION_REQUESTED: "GET_COMPANY_NOTIFICATION_REQUESTED",
    GET_COMPANY_NOTIFICATION_SUCCEEDED: "GET_COMPANY_NOTIFICATION_SUCCEEDED",
    GET_COMPANY_NOTIFICATION_FAILED: "GET_COMPANY_NOTIFICATION_FAILED",

    UPDATE_COMPANY_NOTIFICATION_REQUESTED: "UPDATE_COMPANY_NOTIFICATION_REQUESTED",
    UPDATE_COMPANY_NOTIFICATION_SUCCEEDED: "UPDATE_COMPANY_NOTIFICATION_SUCCEEDED",
    UPDATE_COMPANY_NOTIFICATION_FAILED: "UPDATE_COMPANY_NOTIFICATION_FAILED"

};

// Action Creators
export const actions = {
    all: (token, email, callback) => {
        return {
            type: types.GET_COMPANY_NOTIFICATION_REQUESTED,
            token,
            email,
            callback
        };
    },
    update: (token, id, callback) => {
        return {
            type: types.UPDATE_COMPANY_NOTIFICATION_REQUESTED,
            token,
            id,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    newLoading: true,
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_NOTIFICATION_REQUESTED:
        case types.UPDATE_COMPANY_NOTIFICATION_REQUESTED:    
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_NOTIFICATION_SUCCEEDED:
        case types.UPDATE_COMPANY_NOTIFICATION_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_NOTIFICATION_FAILED:
        case types.UPDATE_COMPANY_NOTIFICATION_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_NOTIFICATION_REQUESTED, allWorker);
    yield takeLatest(types.UPDATE_COMPANY_NOTIFICATION_REQUESTED, updateWorker);
}

// Saga callback
function* allWorker({ token, email, callback }) {
    try {
        const response = yield call(all, { token, email });
        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_NOTIFICATION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_NOTIFICATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// Saga callback
function* updateWorker({ token, id, callback }) {
    try {
        const response = yield call(update, { token, id });
        if (!response.ok) {
            throw response.data;
        }
        yield put({
            type: types.UPDATE_COMPANY_NOTIFICATION_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_NOTIFICATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function all({ token, email }) {
    return api.get(`/api/company/${token}/notification/${email}`);
}

function update({ token, id }) {
    return api.put(`/api/company/${token}/notification/${id}`);
}

import { takeEvery, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_REQUESTED:
        "GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_REQUESTED",
    GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_SUCCEEDED:
        "GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_SUCCEEDED",
    GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_FAILED:
        "GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_FAILED"
};

// Action Creators
export const actions = {
    get: (token, targetProfileId, categoryId, callback) => {
        return {
            type:
                types.GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_REQUESTED,
            token,
            targetProfileId,
            categoryId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_SUCCEEDED:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.categoryId]: action.payload.data
                }
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeEvery(
        types.GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_REQUESTED,
        getOthersCategoryWorker
    );
}

// Saga callback
function* getOthersCategoryWorker({
    token,
    targetProfileId,
    categoryId,
    callback
}) {
    try {
        const response = yield call(get, {
            token,
            targetProfileId,
            categoryId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type:
                types.GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_SUCCEEDED,
            payload: response.data,
            categoryId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type:
                types.GET_COMPANY_TARGET_PROFILE_CULTURE_CATEGORY_RESULTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ token, targetProfileId, categoryId }) {
    return api.get(
        `/api/company/${token}/target-profile/${targetProfileId}/culture/matches/category/${categoryId}/average/results`
    );
}

import axios from "axios";
// import userStore from "../redux/User";
import { getDomain } from "./tools";

let url = "";
if (process.env.NODE_ENV === "production") {
    url = "";
} else {
    if (process.env.TEAM === "tdl") {
        url = "http://tm-company-side.tdl.localhost";
    } else {
        url = "http://tc-stag.talentmatchglobal.com";
    }
    url = "http://tc-stag.talentmatchglobal.com";
}

const domain = getDomain();

const api = axios.create({
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
});

api.setHeader = function (key, value) {
    this.defaults.headers.common[key] = value;
};

api.interceptors.response.use(
    function (response) {
        return {
            ...response,
            ok: true
        };
    },
    function (error) {
        if (error.response.status === 401) {
            window.location = domain.tm + "/login?type=company&expire=1";
            // store.dispatch(userStore.actions.clearAuthDataUser());
        }

        return {
            ...error.response,
            ok: false
        };
    }
);

export default api;

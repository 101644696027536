import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";

import { saveState, loadState } from "./localStorage";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

// Disable = {} || loadState()
const presistedState = loadState();

const sagaMiddleware = createSagaMiddleware();

let store = createStore(
    rootReducer,
    presistedState,
    applyMiddleware(sagaMiddleware)
);

if (process.env.NODE_ENV === "development") {
    store = createStore(
        rootReducer,
        presistedState,
        composeWithDevTools(applyMiddleware(sagaMiddleware))
    );
}

sagaMiddleware.run(rootSaga);

store.subscribe(
    throttle(() => {
        saveState({
            user: {
                auth: store.getState().user.auth,
                data: store.getState().user.data
            },
            company: {
                login: {
                    auth: store.getState().company.login.auth,
                    user: store.getState().company.login.user
                }
            }
        });
    }),
    1000
);

export default store;

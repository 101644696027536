import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";
import findIndex from "lodash/findIndex";

// Actions
const types = {
    SAVE_COMPANY_TRIBE_REQUESTED: "SAVE_COMPANY_TRIBE_REQUESTED",
    SAVE_COMPANY_TRIBE_SUCCEEDED: "SAVE_COMPANY_TRIBE_SUCCEEDED",
    SAVE_COMPANY_TRIBE_FAILED: "SAVE_COMPANY_TRIBE_FAILED",
    UPDATE_COMPANY_TRIBE_REQUESTED: "UPDATE_COMPANY_TRIBE_REQUESTED",
    UPDATE_COMPANY_TRIBE_SUCCEEDED: "UPDATE_COMPANY_TRIBE_SUCCEEDED",
    UPDATE_COMPANY_TRIBE_FAILED: "UPDATE_COMPANY_TRIBE_FAILED",
    GET_COMPANY_TRIBES_REQUESTED: "GET_COMPANY_TRIBES_REQUESTED",
    GET_COMPANY_TRIBES_SUCCEEDED: "GET_COMPANY_TRIBES_SUCCEEDED",
    GET_COMPANY_TRIBES_FAILED: "GET_COMPANY_TRIBES_FAILED",
    GET_COMPANY_SINGLE_TRIBE_REQUESTED: "GET_COMPANY_SINGLE_TRIBE_REQUESTED",
    GET_COMPANY_SINGLE_TRIBE_SUCCEEDED: "GET_COMPANY_SINGLE_TRIBE_SUCCEEDED",
    GET_COMPANY_SINGLE_TRIBE_FAILED: "GET_COMPANY_SINGLE_TRIBE_FAILED",
    TOGGLE_ACTIVE_COMPANY_TRIBE_REQUESTED: "TOGGLE_ACTIVE_COMPANY_TRIBE_REQUESTED",
    TOGGLE_ACTIVE_COMPANY_TRIBE_SUCCEEDED: "TOGGLE_ACTIVE_COMPANY_TRIBE_SUCCEEDED",
    TOGGLE_ACTIVE_COMPANY_TRIBE_FAILED: "TOGGLE_ACTIVE_COMPANY_TRIBE_FAILED"
};

// Action Creators
export const actions = {
    save: (data, token, callback) => {
        return {
            type: types.SAVE_COMPANY_TRIBE_REQUESTED,
            data,
            token,
            callback
        };
    },
    update: (profileId, data, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_TRIBE_REQUESTED,
            profileId,
            data,
            token,
            callback
        };
    },

    get: (token, callback) => {
        return {
            type: types.GET_COMPANY_TRIBES_REQUESTED,
            token,
            callback
        };
    },
    getStudentCircles: (token, callback) => {
        return {
            type: types.GET_COMPANY_STUDENT_CIRCLES_REQUESTED,
            token,
            callback
        };
    },
    getSingle: (token, profileId, callback) => {
        return {
            type: types.GET_COMPANY_SINGLE_TRIBE_REQUESTED,
            token,
            profileId,
            callback
        };
    },
    toggleActive: (token, profileId, isActive, callback) => {
        return {
            type: types.TOGGLE_ACTIVE_COMPANY_TRIBE_REQUESTED,
            token,
            profileId,
            isActive,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    student: [],
    single: {},
    error: "",
    loading: true,
    singleLoading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_TRIBES_REQUESTED:
        case types.UPDATE_COMPANY_TRIBE_REQUESTED:
        case types.SAVE_COMPANY_TRIBE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_SINGLE_TRIBE_REQUESTED:
            return {
                ...state,
                singleLoading: true
            };
        case types.SAVE_COMPANY_TRIBE_SUCCEEDED:
            return {
                ...state,
                data: [...state.data, action.payload.data],
                error: "",
                loading: false
            };
        case types.UPDATE_COMPANY_TRIBE_SUCCEEDED:
            const profileIndex = findIndex(state.data, {
                id: action.payload.data.id
            });
            let data = [...state.data];
            data.splice(profileIndex, 1, {
                ...data[profileIndex],
                name: action.payload.data.name
            });

            return {
                ...state,
                data: data,
                single: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_TRIBES_SUCCEEDED:

            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_SINGLE_TRIBE_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.SAVE_COMPANY_TRIBE_FAILED:
        case types.UPDATE_COMPANY_TRIBE_FAILED:
        case types.GET_COMPANY_TRIBES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_SINGLE_TRIBE_FAILED:
            return {
                ...state,
                singleLoading: false
            };
        case types.TOGGLE_ACTIVE_COMPANY_TRIBE_SUCCEEDED:
            return {
                ...state,
                single: {
                    ...state.single,
                    is_active: action.payload
                },
                error: "",
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_COMPANY_TRIBE_REQUESTED, saveWorker);
    yield takeLatest(types.UPDATE_COMPANY_TRIBE_REQUESTED, updateWorker);
    yield takeLatest(types.GET_COMPANY_TRIBES_REQUESTED, getWorker);
    yield takeLatest(types.GET_COMPANY_SINGLE_TRIBE_REQUESTED, singleWorker);
    yield takeLatest(types.TOGGLE_ACTIVE_COMPANY_TRIBE_REQUESTED, toggleActiveWorker);
}

// Saga callback
function* saveWorker({ data, token, callback }) {
    try {
        const response = yield call(save, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_TRIBE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_TRIBE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateWorker({ profileId, data, token, callback }) {
    try {
        const response = yield call(update, { profileId, data, token });

        if (!response.ok) {
            throw response.data;
        }

        if (profileId) {
            yield put({
                type: types.UPDATE_COMPANY_TRIBE_SUCCEEDED,
                payload: response.data
            });
        } else {
            yield put({
                type: types.SAVE_COMPANY_TRIBE_SUCCEEDED,
                payload: response.data
            });
        }

        callSuccess(callback, response.data);
    } catch (e) {
        if (profileId) {
            yield put({
                type: types.UPDATE_COMPANY_TRIBE_FAILED,
                payload: e.errors
            });
        } else {
            yield put({
                type: types.SAVE_COMPANY_TRIBE_FAILED,
                payload: e.errors
            });
        }
        callFail(callback, e.errors);
    }
}

function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_TRIBES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_TRIBES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* singleWorker({ token, profileId, callback }) {
    try {
        const response = yield call(single, { profileId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_SINGLE_TRIBE_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_SINGLE_TRIBE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* toggleActiveWorker({ token, profileId, isActive, callback }) {
    try {
        const response = yield call(toggleActive, {
            isActive,
            profileId,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.TOGGLE_ACTIVE_COMPANY_TRIBE_SUCCEEDED,
            payload: isActive
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.TOGGLE_ACTIVE_COMPANY_TRIBE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data, token }) {
    return api.post(`/api/company/${token}/v2/target-profile`, data);
}

function update({ profileId, data, token }) {
    if (!profileId) {
        return api.post(`/api/company/${token}/target-profile`, data);
    }
    return api.put(`/api/company/${token}/target-profile/${profileId}`, data);
}

function get({ token }) {
    return api.get(`/api/company/${token}/target-profiles/light`);
}

function single({ profileId, token }) {
    return api.get(`/api/company/${token}/target-profile/${profileId}`);
}

function toggleActive({ token, profileId, isActive }) {
    return api.put(
        `/api/company/${token}/target-profile/${profileId}/is-active/${isActive ? 1 : 0
        }`
    );
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_COMPARISON_OFFICE_LOCATION_REQUESTED:
        "GET_COMPANY_COMPARISON_OFFICE_LOCATION_REQUESTED",
    GET_COMPANY_COMPARISON_OFFICE_LOCATION_SUCCEEDED:
        "GET_COMPANY_COMPARISON_OFFICE_LOCATION_SUCCEEDED",
    GET_COMPANY_COMPARISON_OFFICE_LOCATION_FAILED:
        "GET_COMPANY_COMPARISON_OFFICE_LOCATION_FAILED"
};

// Action Creators
export const actions = {
    get: (token, callback) => {
        return {
            type: types.GET_COMPANY_COMPARISON_OFFICE_LOCATION_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_COMPARISON_OFFICE_LOCATION_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_COMPARISON_OFFICE_LOCATION_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_COMPARISON_OFFICE_LOCATION_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.GET_COMPANY_COMPARISON_OFFICE_LOCATION_REQUESTED,
        getWorker
    );
}

// Saga callback
function* getWorker({ token, callback }) {
    try {
        const response = yield call(get, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_COMPARISON_OFFICE_LOCATION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_COMPARISON_OFFICE_LOCATION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ token }) {
    return api.get(`/api/company/${token}/office-location`);
}

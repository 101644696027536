import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SAVE_PROFILE_JOB_SATUS_REQUESTED: "SAVE_PROFILE_JOB_SATUS_REQUESTED",
    SAVE_PROFILE_JOB_SATUS_SUCCEEDED: "SAVE_PROFILE_JOB_SATUS_SUCCEEDED",
    SAVE_PROFILE_JOB_SATUS_FAILED: "SAVE_PROFILE_JOB_SATUS_FAILED",
    GET_PROFILE_JOB_SATUS_REQUESTED: "GET_PROFILE_JOB_SATUS_REQUESTED",
    GET_PROFILE_JOB_SATUS_SUCCEEDED: "GET_PROFILE_JOB_SATUS_SUCCEEDED",
    GET_PROFILE_JOB_SATUS_FAILED: "GET_PROFILE_JOB_SATUS_FAILED"
};

// Action Creators
export const actions = {
    save: (data, callback) => {
        return {
            type: types.SAVE_PROFILE_JOB_SATUS_REQUESTED,
            data,
            callback
        };
    },
    get: callback => {
        return {
            type: types.GET_PROFILE_JOB_SATUS_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_PROFILE_JOB_SATUS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SAVE_PROFILE_JOB_SATUS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SAVE_PROFILE_JOB_SATUS_SUCCEEDED:
            return {
                data: action.payload,
                error: "",
                loading: false
            };
        case types.GET_PROFILE_JOB_SATUS_SUCCEEDED:
            return {
                data: action.payload,
                error: "",
                loading: false
            };
        case types.SAVE_PROFILE_JOB_SATUS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_PROFILE_JOB_SATUS_REQUESTED, saveWorker);
    yield takeLatest(types.GET_PROFILE_JOB_SATUS_REQUESTED, getWorker);
}

// Saga callback
function* saveWorker({ data, callback }) {
    try {
        const response = yield call(save, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PROFILE_JOB_SATUS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_PROFILE_JOB_SATUS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PROFILE_JOB_SATUS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_PROFILE_JOB_SATUS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data }) {
    return api.put("/api/job-status", data);
}

function get() {
    return api.get("/api/job-status/talent");
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPLOAD_COMPANY_TRIBE_JOB_COVER_REQUESTED:
        "UPLOAD_COMPANY_TRIBE_JOB_COVER_REQUESTED",
    UPLOAD_COMPANY_TRIBE_JOB_COVER_SUCCEEDED:
        "UPLOAD_COMPANY_TRIBE_JOB_COVER_SUCCEEDED",
    UPLOAD_COMPANY_TRIBE_JOB_COVER_FAILED:
        "UPLOAD_COMPANY_TRIBE_JOB_COVER_FAILED",
    GET_COMPANY_TRIBE_JOB_COVER_REQUESTED:
        "GET_COMPANY_TRIBE_JOB_COVER_REQUESTED",
    GET_COMPANY_TRIBE_JOB_COVER_SUCCEEDED:
        "GET_COMPANY_TRIBE_JOB_COVER_SUCCEEDED",
    GET_COMPANY_TRIBE_JOB_COVER_FAILED: "GET_COMPANY_TRIBE_JOB_COVER_FAILED",
    DELETE_COMPANY_TRIBE_JOB_COVER_REQUESTED:
        "DELETE_COMPANY_TRIBE_JOB_COVER_REQUESTED",
    DELETE_COMPANY_TRIBE_JOB_COVER_SUCCEEDED:
        "DELETE_COMPANY_TRIBE_JOB_COVER_SUCCEEDED",
    DELETE_COMPANY_TRIBE_JOB_COVER_FAILED:
        "DELETE_COMPANY_TRIBE_JOB_COVER_FAILED"
};

// Action Creators
export const actions = {
    upload: (token, image, meta, callback) => {
        return {
            type: types.UPLOAD_COMPANY_TRIBE_JOB_COVER_REQUESTED,
            token,
            image,
            meta,
            callback
        };
    },
    get: (token, profileId, callback) => {
        return {
            type: types.GET_COMPANY_TRIBE_JOB_COVER_REQUESTED,
            token,
            profileId,
            callback
        };
    },
    delete: (token, callback) => {
        return {
            type: types.DELETE_COMPANY_TRIBE_JOB_COVER_REQUESTED,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_TRIBE_JOB_COVER_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_TRIBE_JOB_COVER_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_TRIBE_JOB_COVER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.UPLOAD_COMPANY_TRIBE_JOB_COVER_REQUESTED,
        uploadWorker
    );
    yield takeLatest(types.GET_COMPANY_TRIBE_JOB_COVER_REQUESTED, getWorker);
    yield takeLatest(
        types.DELETE_COMPANY_TRIBE_JOB_COVER_REQUESTED,
        deleteWorker
    );
}

// Saga callback
function* uploadWorker({ token, image, meta, callback }) {
    try {
        const response = yield call(upload, { token, image, meta });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPLOAD_COMPANY_TRIBE_JOB_COVER_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPLOAD_COMPANY_TRIBE_JOB_COVER_FAILED,
            payload: e.errors
        });
        callFail(callback, e.message);
    }
}

function* getWorker({ token, profileId, callback }) {
    try {
        const response = yield call(get, { token, profileId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_TRIBE_JOB_COVER_SUCCEEDED,
            payload: {
                data: response.data.data
            }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_TRIBE_JOB_COVER_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ token, callback }) {
    try {
        const response = yield call(deleteFile, {
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_COMPANY_TRIBE_JOB_COVER_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_COMPANY_TRIBE_JOB_COVER_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function upload({ token, image, meta }) {
    
    const data = new FormData();
    data.append("image", image);
    data.append("x_coordinate", meta.x);
    data.append("y_coordinate", meta.y);
    data.append("width", meta.width);
    data.append("height", meta.height);
    return api.post(`/api/company/${token}/upload-image`, data);
}

function get({ token }) {
    return api.get(`/api/company/${token}/logo`);
}

function deleteFile({ token }) {
    return api.delete(`/api/company/${token}/logo`);
}

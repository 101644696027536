import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_MATCHES_TOP_REQUESTED: "GET_MATCHES_TOP_REQUESTED",
    GET_MATCHES_TOP_SUCCEEDED: "GET_MATCHES_TOP_SUCCEEDED",
    GET_MATCHES_TOP_FAILED: "GET_MATCHES_TOP_FAILED",
    SET_MATCHES_COMPANY_REQUESTED: "SET_MATCHES_COMPANY_REQUESTED",
    SET_MATCHES_COMPANY_SUCCEEDED: "SET_MATCHES_COMPANY_SUCCEEDED",
    SET_MATCHES_COMPANY_FAILED: "SET_MATCHES_COMPANY_FAILED"
};

// Action Creators
export const actions = {
    get: callback => {
        return {
            type: types.GET_MATCHES_TOP_REQUESTED,
            callback
        };
    },
    setCompany: (company, callback) => {
        return {
            type: types.SET_MATCHES_COMPANY_REQUESTED,
            company,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    company: null,
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_MATCHES_TOP_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SET_MATCHES_COMPANY_REQUESTED: {
            callSuccess(action.callback);
            return {
                ...state,
                company: action.company
            };
        }
        case types.GET_MATCHES_TOP_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_MATCHES_TOP_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_MATCHES_TOP_REQUESTED, getWorker);
}

// Saga callback
function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_MATCHES_TOP_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_MATCHES_TOP_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get() {
    return api.get(`/api/matching/talent`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    INIT_LABELS_REQUESTED: "INIT_LABELS_REQUESTED",
    INIT_LABELS_SUCCEEDED: "INIT_LABELS_SUCCEEDED",
    INIT_LABELS_FAILED: "INIT_LABELS_FAILED",
    GET_LABELS_REQUESTED: "GET_LABELS_REQUESTED",
    GET_LABELS_SUCCEEDED: "GET_LABELS_SUCCEEDED",
    GET_LABELS_FAILED: "GET_LABELS_FAILED",
    ADD_LABEL_REQUESTED: "ADD_LABEL_REQUESTED",
    ADD_LABEL_SUCCEEDED: "ADD_LABEL_SUCCEEDED",
    ADD_LABEL_FAILED: "ADD_LABEL_FAILED",
    REMOVE_LABEL_REQUESTED: "REMOVE_LABEL_REQUESTED",
    REMOVE_LABEL_SUCCEEDED: "REMOVE_LABEL_SUCCEEDED",
    REMOVE_LABEL_FAILED: "REMOVE_LABEL_FAILED",
    UPLOAD_LABELS_REQUESTED: "UPLOAD_LABELS_REQUESTED",
    UPLOAD_LABELS_SUCCEEDED: "UPLOAD_LABELS_SUCCEEDED",
    UPLOAD_LABELS_FAILED: "UPLOAD_LABELS_FAILED"
};

// Action Creators
export const actions = {
    init: (userId, labels, callback) => {
        return {
            type: types.INIT_LABELS_REQUESTED,
            userId,
            labels,
            callback
        };
    },
    get: (callback) => {
        return {
            type: types.GET_LABELS_REQUESTED,
            callback
        };
    },
    add: (token, profileId, userId, label, callback) => {
        return {
            type: types.ADD_LABEL_REQUESTED,
            token,
            profileId,
            userId,
            label,
            callback
        };
    },
    remove: (token, profileId, userId, label, callback) => {
        return {
            type: types.REMOVE_LABEL_REQUESTED,
            token,
            profileId,
            userId,
            label,
            callback
        };
    },
    upload: (token, profileId, file, setProgress, callback) => {
        return {
            type: types.UPLOAD_LABELS_REQUESTED,
            token,
            profileId,
            file,
            setProgress,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    selectedLabels: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.INIT_LABELS_REQUESTED: {
            let selectedLabels = { ...state.selectedLabels };

            selectedLabels[action.userId] = action.labels;

            return {
                ...state,
                selectedLabels
            };
        }
        case types.ADD_LABEL_SUCCEEDED: {
            let selectedLabels = { ...state.selectedLabels };

            if (selectedLabels[action.userId]) {
                const labelAlreadyExists = selectedLabels[action.userId].find(
                    (label) => label.id == action.label.id
                );
                if (!labelAlreadyExists) {
                    selectedLabels = {
                        ...selectedLabels,
                        [action.userId]: [
                            ...selectedLabels[action.userId],
                            action.label
                        ]
                    };
                }
            } else {
                selectedLabels = {
                    ...state.selectedLabels,
                    [action.userId]: [action.label]
                };
            }
            return {
                ...state,
                selectedLabels: selectedLabels
            };
        }
        case types.REMOVE_LABEL_SUCCEEDED: {
            let selectedLabels = { ...state.selectedLabels };
            if (selectedLabels[action.userId]) {
                const labelAlreadyExists = selectedLabels[
                    action.userId
                ].findIndex((label) => label.id == action.label.id);

                if (labelAlreadyExists != -1) {
                    selectedLabels[action.userId].splice(labelAlreadyExists, 1);
                }
            }

            return {
                ...state,
                selectedLabels: selectedLabels
            };
        }
        case types.GET_LABELS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_LABELS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_LABELS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_LABELS_REQUESTED, getWorker);
    yield takeLatest(types.ADD_LABEL_REQUESTED, addWorker);
    yield takeLatest(types.REMOVE_LABEL_REQUESTED, removeWorker);
    yield takeLatest(types.UPLOAD_LABELS_REQUESTED, uploadWorker);
}

// Saga callback
function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_LABELS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_LABELS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* addWorker({ token, profileId, userId, label, callback }) {
    try {
        const response = yield call(add, { token, profileId, userId, label });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ADD_LABEL_SUCCEEDED,
            label,
            userId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.ADD_LABEL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* removeWorker({ token, profileId, userId, label, callback }) {
    try {
        const response = yield call(remove, {
            token,
            profileId,
            userId,
            label
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REMOVE_LABEL_SUCCEEDED,
            label,
            userId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.REMOVE_LABEL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* uploadWorker({ token, profileId, file, setProgress, callback }) {
    try {
        const response = yield call(upload, {
            token,
            profileId,
            file,
            setProgress
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPLOAD_LABELS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPLOAD_LABELS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get() {
    return api.get(`/api/labels`);
}

function add({ token, profileId, userId, label }) {
    return api.post(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/label`,
        {
            label_id: label.id
        }
    );
}

function remove({ token, profileId, userId, label }) {
    return api.delete(
        `/api/company/${token}/target-profile/${profileId}/user/${userId}/label`,
        {
            data: {
                label_id: label.id
            }
        }
    );
}

function upload({ token, profileId, file, setProgress }) {
    const data = new FormData();

    data.append("labels_csv", file);

    return api.post(
        `/api/company/${token}/labels/import-csv/${profileId}`,
        data,
        {
            onUploadProgress: (e) => {
                setProgress((e.loaded / e.total) * 100);
            }
        }
    );
}

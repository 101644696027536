import { takeLatest, call, put, debounce } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_UNIVERSITIES_REQUESTED: "GET_UNIVERSITIES_REQUESTED",
    GET_UNIVERSITIES_SUCCEEDED: "GET_UNIVERSITIES_SUCCEEDED",
    GET_UNIVERSITIES_FAILED: "GET_UNIVERSITIES_FAILED",
    SEARCH_UNIVERSITIES_REQUESTED: "SEARCH_UNIVERSITIES_REQUESTED",
    SEARCH_UNIVERSITIES_SUCCEEDED: "SEARCH_UNIVERSITIES_SUCCEEDED",
    SEARCH_UNIVERSITIES_FAILED: "SEARCH_UNIVERSITIES_FAILED"
};

// Action Creators
export const actions = {
    get: callback => {
        return {
            type: types.GET_UNIVERSITIES_REQUESTED,
            callback
        };
    },
    search: (searchTerm, callback) => {
        return {
            type: types.SEARCH_UNIVERSITIES_REQUESTED,
            searchTerm,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_UNIVERSITIES_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_UNIVERSITIES_SUCCEEDED:
            return {
                data: action.payload,
                error: "",
                loading: false
            };
        case types.GET_UNIVERSITIES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_UNIVERSITIES_REQUESTED, getWorker);
    yield debounce(300, types.SEARCH_UNIVERSITIES_REQUESTED, searchWorker);
}

// Saga callback
function* getWorker({ callback }) {
    try {
        const response = yield call(get);

        if (!response.ok) {
            throw { message: response.data };
        }

        yield put({
            type: types.GET_UNIVERSITIES_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_UNIVERSITIES_FAILED,
            payload: e.message
        });
        callFail(callback, e.message);
    }
}

function* searchWorker({ searchTerm, callback }) {
    try {
        const response = yield call(search, { searchTerm });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEARCH_UNIVERSITIES_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data.data);
    } catch (e) {
        yield put({
            type: types.SEARCH_UNIVERSITIES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get() {
    return api.get("/api/education/institutions");
}

function search({ searchTerm }) {
    return api.get(`/api/education/institutions/search/${searchTerm}`);
}

import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";
import findIndex from "lodash/findIndex";

// Actions
const types = {
    SAVE_COMPANY_TRIBE_CONTENT_REQUESTED:
        "SAVE_COMPANY_TRIBE_CONTENT_REQUESTED",
    SAVE_COMPANY_TRIBE_CONTENT_SUCCEEDED:
        "SAVE_COMPANY_TRIBE_CONTENT_SUCCEEDED",
    SAVE_COMPANY_TRIBE_CONTENT_FAILED: "SAVE_COMPANY_TRIBE_CONTENT_FAILED",
    UPDATE_COMPANY_TRIBE_CONTENT_REQUESTED:
        "UPDATE_COMPANY_TRIBE_CONTENT_REQUESTED",
    UPDATE_COMPANY_TRIBE_CONTENT_SUCCEEDED:
        "UPDATE_COMPANY_TRIBE_CONTENT_SUCCEEDED",
    UPDATE_COMPANY_TRIBE_CONTENT_FAILED: "UPDATE_COMPANY_TRIBE_CONTENT_FAILED",
    GET_COMPANY_TRIBE_CONTENT_REQUESTED: "GET_COMPANY_TRIBE_CONTENT_REQUESTED",
    GET_COMPANY_TRIBE_CONTENT_SUCCEEDED: "GET_COMPANY_TRIBE_CONTENT_SUCCEEDED",
    GET_COMPANY_TRIBE_CONTENT_FAILED: "GET_COMPANY_TRIBE_CONTENT_FAILED",
    GET_COMPANY_TRIBE_CONTENT_ALL_REQUESTED:
        "GET_COMPANY_TRIBE_CONTENT_ALL_REQUESTED",
    GET_COMPANY_TRIBE_CONTENT_ALL_SUCCEEDED:
        "GET_COMPANY_TRIBE_CONTENT_ALL_SUCCEEDED",
    GET_COMPANY_TRIBE_CONTENT_ALL_FAILED:
        "GET_COMPANY_TRIBE_CONTENT_ALL_FAILED",
    UPDATE_COMPANY_TRIBE_CONTENT_STATUS_REQUESTED:
        "UPDATE_COMPANY_TRIBE_CONTENT_STATUS_REQUESTED",
    UPDATE_COMPANY_TRIBE_CONTENT_STATUS_SUCCEEDED:
        "UPDATE_COMPANY_TRIBE_CONTENT_STATUS_SUCCEEDED",
    UPDATE_COMPANY_TRIBE_CONTENT_STATUS_FAILED:
        "UPDATE_COMPANY_TRIBE_CONTENT_STATUS_FAILED",

    DELETE_COMPANY_TRIBE_CONTENT_REQUESTED: "DELETE_COMPANY_TRIBE_CONTENT_REQUESTED",
    DELETE_COMPANY_TRIBE_CONTENT_SUCCEEDED: "DELETE_COMPANY_TRIBE_CONTENT_SUCCEEDED",
    DELETE_COMPANY_TRIBE_CONTENT_FAILED: "DELETE_COMPANY_TRIBE_CONTENT_FAILED"
};

// Action Creators
export const actions = {
    save: (data, token, callback) => {
        return {
            type: types.SAVE_COMPANY_TRIBE_CONTENT_REQUESTED,
            data,
            token,
            callback
        };
    },
    update: (data, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_TRIBE_CONTENT_REQUESTED,
            data,
            token,
            callback
        };
    },
    updateStatus: (itemId, status, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_TRIBE_CONTENT_STATUS_REQUESTED,
            itemId,
            status,
            token,
            callback
        };
    },
    delete: (itemId, token, callback) => {
        return {
            type: types.DELETE_COMPANY_TRIBE_CONTENT_REQUESTED,
            itemId,
            token,
            callback
        };
    },
    get: (token, tribeContentId, callback) => {
        return {
            type: types.GET_COMPANY_TRIBE_CONTENT_REQUESTED,
            token,
            tribeContentId,
            callback
        };
    },
    getAll: (token, profileId, callback) => {
        return {
            type: types.GET_COMPANY_TRIBE_CONTENT_ALL_REQUESTED,
            token,
            profileId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    single: {},
    error: "",
    loading: false,
    singleLoading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_TRIBE_CONTENT_ALL_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.UPDATE_COMPANY_TRIBE_CONTENT_REQUESTED:
        case types.SAVE_COMPANY_TRIBE_CONTENT_REQUESTED:
        case types.GET_COMPANY_TRIBE_CONTENT_REQUESTED:
            return {
                ...state,
                singleLoading: true
            };
        case types.SAVE_COMPANY_TRIBE_CONTENT_SUCCEEDED:
            return {
                ...state,
                data: [...state.data, action.payload.data],
                error: "",
                singleLoading: false
            };
        case types.UPDATE_COMPANY_TRIBE_CONTENT_SUCCEEDED:
            const INDEX = findIndex(state.data, {
                id: action.payload.data.id
            });
            let data = [...state.data];
            data.splice(INDEX, 1, {
                ...data[INDEX],
                name: action.payload.data.name
            });

            return {
                ...state,
                data: data,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.GET_COMPANY_TRIBE_CONTENT_ALL_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_TRIBE_CONTENT_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.SAVE_COMPANY_TRIBE_CONTENT_FAILED:
        case types.UPDATE_COMPANY_TRIBE_CONTENT_FAILED:
        case types.GET_COMPANY_TRIBE_CONTENT_ALL_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_TRIBE_CONTENT_FAILED:
            return {
                ...state,
                singleLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SAVE_COMPANY_TRIBE_CONTENT_REQUESTED, saveWorker);
    yield takeLatest(
        types.UPDATE_COMPANY_TRIBE_CONTENT_REQUESTED,
        updateWorker
    );
    yield takeLatest(
        types.UPDATE_COMPANY_TRIBE_CONTENT_STATUS_REQUESTED,
        updateStatusWorker
    );
    yield takeLatest(types.GET_COMPANY_TRIBE_CONTENT_REQUESTED, singleWorker);
    yield takeLatest(types.GET_COMPANY_TRIBE_CONTENT_ALL_REQUESTED, allWorker);
    yield takeLatest(types.DELETE_COMPANY_TRIBE_CONTENT_REQUESTED, deleteContentWorker);
}

// Saga callback
function* saveWorker({ data, token, callback }) {
    try {
        const response = yield call(save, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_TRIBE_CONTENT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_TRIBE_CONTENT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateWorker({ data, token, callback }) {
    try {
        const response = yield call(update, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_TRIBE_CONTENT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_TRIBE_CONTENT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* updateStatusWorker({ itemId, status, token, callback }) {
    try {
        const response = yield call(updateStatus, { itemId, status, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_TRIBE_CONTENT_STATUS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_TRIBE_CONTENT_STATUS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteContentWorker({ itemId, token, callback }) {
    try {
        const response = yield call(deleteContent, { itemId, token });

        if (!response.ok) { throw response.data; }

        yield put({
            type: types.DELETE_COMPANY_TRIBE_CONTENT_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_COMPANY_TRIBE_CONTENT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* singleWorker({ token, tribeContentId, callback }) {
    try {
        const response = yield call(single, { tribeContentId, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_TRIBE_CONTENT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_TRIBE_CONTENT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* allWorker({ token, profileId, callback }) {
    try {
        const response = yield call(all, { token, profileId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_TRIBE_CONTENT_ALL_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_TRIBE_CONTENT_ALL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function save({ data, token }) {
    return api.post(`/api/company/${token}/contents`, data);
}

function update({ data, token }) {
    return api.post(`/api/company/${token}/contents`, data);
}

function updateStatus({ status, itemId, token }) {
    return api.put(`/api/company/${token}/contents/${itemId}/status/${status}`);
}

function deleteContent({ itemId, token }) {
    return api.delete(`/api/company/${token}/contents/${itemId}`);
}

function single({ tribeContentId, token }) {
    return api.get(`/api/company/${token}/contents/${tribeContentId}`);
}

function all({ profileId, token }) {
    return api.get(
        `/api/company/${token}/target-profile/${profileId}/contents`
    );
}

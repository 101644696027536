import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED:
        "UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED",
    UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED:
        "UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED",
    UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_FAILED:
        "UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_FAILED",
    GET_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED:
        "GET_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED",
    GET_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED:
        "GET_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED",
    GET_COMPANY_DRAFT_CATEGORY_BLOG_FAILED:
        "GET_COMPANY_DRAFT_CATEGORY_BLOG_FAILED",
    DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED:
        "DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED",
    DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED:
        "DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED",
    DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_FAILED:
        "DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_FAILED"
};

// Action Creators
export const actions = {
    update: (page, blogId, data, token, callback) => {
        return {
            type: types.UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED,
            page,
            blogId,
            data,
            token,
            callback
        };
    },
    get: (page, token, blogId, callback) => {
        return {
            type: types.GET_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED,
            page,
            token,
            blogId,
            callback
        };
    },
    discard: (page, token, blogId, callback) => {
        return {
            type: types.DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED,
            page,
            token,
            blogId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: null,
    error: "",
    loading: true,
    saving: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED:
        case types.DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED:
            return {
                ...state,
                saving: true
            };
        case types.DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED:
            return {
                ...state,
                data: null,
                error: "",
                loading: false
            };
        case types.UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                saving: false
            };
        case types.GET_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_FAILED:
            return {
                ...state,
                data: null,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_DRAFT_CATEGORY_BLOG_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_FAILED:
            return {
                ...state,
                saving: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED,
        updateWorker
    );
    yield takeLatest(
        types.GET_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED,
        getWorker
    );
    yield takeLatest(
        types.DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_REQUESTED,
        discardWorker
    );
}

// Saga callback
function* updateWorker({ page, blogId, data, token, callback }) {
    try {
        const response = yield call(update, {
            page,
            blogId,
            data,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.UPDATE_COMPANY_DRAFT_CATEGORY_BLOG_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ page, token, blogId, callback }) {
    try {
        const response = yield call(get, {
            page,
            blogId,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_DRAFT_CATEGORY_BLOG_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* discardWorker({ page, blogId, token, callback }) {
    try {
        const response = yield call(deleteDraft, {
            page,
            blogId,
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_SUCCEEDED
        });

        callSuccess(callback, {});
    } catch (e) {
        yield put({
            type: types.DISCARD_COMPANY_DRAFT_CATEGORY_BLOG_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function update({ page, blogId, data, token }) {
    return api.put(
        `/api/company/${token}/draft/category-blog-page-${page}/${blogId}`,
        data
    );
}

function get({ page, blogId, token }) {
    return api.get(
        `/api/company/${token}/draft/category-blog-page-${page}/${blogId}`
    );
}

function deleteDraft({ page, blogId, token }) {
    return api.delete(
        `/api/company/${token}/draft/category-blog-page-${page}/${blogId}`
    );
}

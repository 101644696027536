import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPANY_AUTHORS_REQUESTED: "GET_COMPANY_AUTHORS_REQUESTED",
    GET_COMPANY_AUTHORS_SUCCEEDED: "GET_COMPANY_AUTHORS_SUCCEEDED",
    GET_COMPANY_AUTHORS_FAILED: "GET_COMPANY_AUTHORS_FAILED",
    GET_COMPANY_AUTHOR_REQUESTED: "GET_COMPANY_AUTHOR_REQUESTED",
    GET_COMPANY_AUTHOR_SUCCEEDED: "GET_COMPANY_AUTHOR_SUCCEEDED",
    GET_COMPANY_AUTHOR_FAILED: "GET_COMPANY_AUTHOR_FAILED",
    SAVE_COMPANY_AUTHOR_REQUESTED: "SAVE_COMPANY_AUTHOR_REQUESTED",
    SAVE_COMPANY_AUTHOR_SUCCEEDED: "SAVE_COMPANY_AUTHOR_SUCCEEDED",
    SAVE_COMPANY_AUTHOR_FAILED: "SAVE_COMPANY_AUTHOR_FAILED",
    DELETE_COMPANY_AUTHOR_REQUESTED: "DELETE_COMPANY_AUTHOR_REQUESTED",
    DELETE_COMPANY_AUTHOR_SUCCEEDED: "DELETE_COMPANY_AUTHOR_SUCCEEDED",
    DELETE_COMPANY_AUTHOR_FAILED: "DELETE_COMPANY_AUTHOR_FAILED",
    INVITE_COMPANY_AUTHOR_REQUESTED: "INVITE_COMPANY_AUTHOR_REQUESTED",
    INVITE_COMPANY_AUTHOR_SUCCEEDED: "INVITE_COMPANY_AUTHOR_SUCCEEDED",
    INVITE_COMPANY_AUTHOR_FAILED: "INVITE_COMPANY_AUTHOR_FAILED"
};

// Action Creators
export const actions = {
    getAll: (token, callback) => {
        return {
            type: types.GET_COMPANY_AUTHORS_REQUESTED,
            token,
            callback
        };
    },
    get: (id, token, callback) => {
        return {
            type: types.GET_COMPANY_AUTHOR_REQUESTED,
            id,
            token,
            callback
        };
    },
    save: (data, token, callback) => {
        return {
            type: types.SAVE_COMPANY_AUTHOR_REQUESTED,
            data,
            token,
            callback
        };
    },
    invite: (data, token, callback) => {
        return {
            type: types.INVITE_COMPANY_AUTHOR_REQUESTED,
            data,
            token,
            callback
        };
    },
    delete: (id, token, callback) => {
        return {
            type: types.DELETE_COMPANY_AUTHOR_REQUESTED,
            id,
            token,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    single: {},
    error: "",
    loading: false,
    singleLoading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPANY_AUTHORS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_AUTHORS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_COMPANY_AUTHORS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_COMPANY_AUTHOR_REQUESTED:
            return {
                ...state,
                singleLoading: true
            };
        case types.GET_COMPANY_AUTHOR_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                error: "",
                singleLoading: false
            };
        case types.GET_COMPANY_AUTHOR_FAILED:
            return {
                ...state,
                error: action.payload,
                singleLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_COMPANY_AUTHORS_REQUESTED, allWorker);
    yield takeLatest(types.GET_COMPANY_AUTHOR_REQUESTED, singleWorker);
    yield takeLatest(types.SAVE_COMPANY_AUTHOR_REQUESTED, saveWorker);
    yield takeLatest(types.INVITE_COMPANY_AUTHOR_REQUESTED, inviteWorker);
    yield takeLatest(types.DELETE_COMPANY_AUTHOR_REQUESTED, deleteWorker);
}

// Saga callback
function* allWorker({ token, callback }) {
    try {
        const response = yield call(all, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_AUTHORS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_AUTHORS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* singleWorker({ id, token, callback }) {
    try {
        const response = yield call(single, { id, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPANY_AUTHOR_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPANY_AUTHOR_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveWorker({ data, token, callback }) {
    try {
        const response = yield call(save, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_COMPANY_AUTHOR_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_COMPANY_AUTHOR_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* inviteWorker({ data, token, callback }) {
    try {
        const response = yield call(invite, { data, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.INVITE_COMPANY_AUTHOR_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.INVITE_COMPANY_AUTHOR_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteWorker({ id, token, callback }) {
    try {
        const response = yield call(deleteItem, { id, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_COMPANY_AUTHOR_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_COMPANY_AUTHOR_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function all({ token }) {
    return api.get(`/api/company/${token}/authors`);
}

function single({ id, token }) {
    return api.get(`/api/company/${token}/author-profile/${id}`);
}

function save({ data, token }) {
    if (data.author_id) {
        return api.put(`/api/company/${token}/authors`, { ...data });
    } else {
        return api.post(`/api/company/${token}/authors`, { ...data });
    }
}

function invite({ data, token }) {
    return api.post(`/api/company/${token}/invite-team-members`, { ...data });
}

function deleteItem({ id, token }) {
    return api.delete(`/api/company/${token}/authors/${id}`);
}

const stateName = "talent_match_state";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(stateName);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(stateName, serializedState);
    } catch (err) {
        // Ignore write errors
    }
};

export const clearState = () => {
    try {
        localStorage.removeItem(stateName);
    } catch (err) {
        // Ignore write errors
    }
};
